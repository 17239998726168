module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: 
     "https://mirax-api.shrewdbs.com/api/v1/" ,
    // "http://localhost:3030/api/v1",
    
    //  
  }
};

// "https://api-node.themesbrand.website"