// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";

export const ADD_NEW_VENDOR = "ADD_NEW_VENDOR";
export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS";
export const ADD_VENDOR_FAIL = "ADD_VENDOR_FAIL";

export const GET_VENDORS = "GET_VENDORS";
export const VENDOR_LIST = "VENDOR_LIST"
// * Edit VENDOR

export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
export const UPDATE_VENDOR_FAIL = "UPDATE_VENDOR_FAIL";

/**
* Delete VENDOR
*/
export const DELETE_VENDOR = "DELETE_VENDOR";
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS";
export const DELETE_VENDOR_FAIL = "DELETE_VENDOR_FAIL";

// status change
export const STATUS_CHANGE = "STATUS_CHANGE";
export const STATUS_CHANGE_SUCCESS = "STATUS_CHANGE_SUCCESS";
export const STATUS_CHANGE_FAIL = "STATUS_CHANGE_FAIL";
