import React from 'react'
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from './BreadCrumb';

export default function BlankLayout({metaTitle, pageTitle, breadcrumTitle, breadcrumPagetitle, children }) {
    // document.title = "Starter | Velzon - React Admin & Dashboard Template";
    document.title = metaTitle;

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={breadcrumTitle} pageTitle={breadcrumPagetitle} />
                    <Row>
                        <Col xs={12}>
                           {children}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
