import React, { useState, useEffect } from 'react';
import { Col, Input, Label, Row, Form, FormFeedback } from 'reactstrap';
import BlankLayout from '../../../Components/Common/BlankLayout';
import CardSection from '../../../Components/Common/CardSection';
import OFFCanvas from '../../../Components/Common/OffCanvas/OFFCanvas';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  getVendors as onGetVendors,  // Updated action
  updateVendor as onUpdateVendor,  // Updated action
} from "../../../store/admin/Vendor/action";  // Updated import path

import {
  getSubcategories as onGetSubcategories,  // Updated action
  editSubcategory as onEditSubcategory,  // Updated action
  updateSubcategory as onUpdateSubcategory
} from "../../../store/admin/SubCategory/action"; 
import { categoryList } from "../../../store/admin/Category/action";


const EditSubCategory = (props) => {  // Updated component name
    const [id, setId] = useState(null)
    const [subCategory, setSubcategory] = useState()
    const [category, setCategory] = useState([]);
    
    const { categories } = useSelector((state) => ({
      categories: state?.Category?.categories,
    }));
  
    useEffect(() => {
      dispatch(categoryList());
    }, []);
  
    useEffect(() => {
      setCategory(categories);
    }, [categories]);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {editData, subcategories, error, loading } = useSelector((state) => ({  // Updated selector name
    subcategories: state?.Subcategory?.subcategories,  
    editData: state?.Subcategory?.editData,  
    loading: state?.Subcategory?.loading,  // Updated state name
    error: state?.Subcategory?.error,  // Updated state name
  }));


  // useEffect(() => {
  //   if (props?.listData) {
  //     Object.keys(props?.listData).forEach((fieldName) => {
  //       setValue(fieldName, props?.listData[fieldName]);
  //     });
  //   }
  // }, [props?.listData, setValue]);


  console.log(props?.editData, 'edit....')

  const dispatch = useDispatch();

  useEffect(()=>{
      setId(props?.editData)
  }, [props?.editData])

  useEffect(()=>{
    const payload =   {
      "id": id
  }
    if(id){     
      dispatch(onEditSubcategory(payload, afterEditChange))
    }
  }, [id])


  useEffect(() => {
    setSubcategory(editData);  
  }, [editData]);

  let selectedOption;

  useEffect(()=>{
    if(subCategory !== null){
      setValue('name', subCategory?.name);
      setValue('key',subCategory?.key )
     selectedOption = category?.find((option) => option?.id === subCategory?.category_id);
      setValue('category_id',selectedOption)
    }
  },[subCategory, setValue])




  const afterEditChange = () =>{
  //   const pagination = {
  //     "page": 1,
  //     "page_size": 10
  // }
  //   dispatch(onGetSubcategories(pagination)); 
  //   props.Handler();
  

  }
  const afterUpdateChange = () =>{
    alert('hi')
      const pagination = {
        "page": 1,
        "page_size": 10
    }

      dispatch(onGetSubcategories(pagination)); 
      props.Handler();

  
    }

  

  const onSubmit = async (data) => {
    const filteredData = {
      "id":id,
      "category_id": !data?.category_id ? data?.category_id : data?.category_id?.id,
      "name": data.name,
      "key":  data.key
  }

     dispatch(onUpdateSubcategory(filteredData, afterUpdateChange));  // Updated action
    
  };

  return (
    <>
      <OFFCanvas canvasTitle={'Edit Value'} direction={'end'} flag={props.flag} handler={() => props.Handler()}>
        <Row>
       
          <Col xl={12}>
            {/* <CardSection panelTitle="Enter Details"> */}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col
                  className={`mb-3 ${
                    errors.category_id ? "d-block" : ""
                  }" md={12}`}
                >
                  <Controller
                    name="category_id"
                    control={control}
                    defaultValue="" // Set the default value if needed
                    rules={{ required: "Category is required" }}
                    render={({ field }) => (
                      <>
                        <Label htmlFor="dropdownValue" className="form-label">
                          Select Category
                        </Label>

                        <select
                          {...field}
                          className={`form-control ${
                            errors.category_id ? "is-invalid" : ""
                          }`}
                          id="dropdownValue"
                        >
                          {/* <option value="" disabled>
                            Select an option
                          </option> */}

                            <option value={selectedOption?.id} selected>
                              {selectedOption?.name}
                          </option>
                          {Array.isArray(category) &&                        
                             category?.map((option) => (   
                              option.id !== selectedOption?.id &&(
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option> )
                            ))}
                        </select>
                        {errors?.category_id && (
                          <FormFeedback className="d-block">
                            {errors?.category_id?.message}
                          </FormFeedback>
                        )}
                      </>
                    )}
                  />
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Name is required" }}
                      render={({ field }) => (
                        <>
                          <Label for="firstNameinput" className="form-label">
                            Subcategory Name
                          </Label>
                          <Input
                            {...field}
                            type="text"
                            invalid={errors.name ? true : false}
                            className="form-control"
                            placeholder="Enter Subcategory name"
                            id="firstNameinput"
                          />
                          <FormFeedback type="invalid">
                            {errors.name && errors.name.message}
                          </FormFeedback>
                        </>
                      )}
                    />
                  </div>
                </Col>
                {/* <Col md={12}>
                  <div className="mb-3">
                    <Controller
                      name="key"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Key is required" }}
                      render={({ field }) => (
                        <>
                          <Label for="lastNameinput" className="form-label">
                            Key
                          </Label>
                          <Input
                            {...field}
                            type="text"
                            invalid={errors.key ? true : false}
                            className="form-control"
                            placeholder="Enter your Last name"
                            id="lastNameinput"
                          />
                          <FormFeedback type="invalid">
                            {errors.key && errors.key.message}
                          </FormFeedback>
                        </>
                      )}
                    />
                  </div>
                </Col> */}

                <Col md={12}>
                  <div className="text-start">
                    {loading ? (
                      <button type="button" class="btn btn-primary " disabled>
                        Loading...
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    )}
                    {/* <button onClick={() => { setflag(!flag) }} className='btn btn-warning'> offcanvas</button> */}
                  </div>
                </Col>
              </Row>
            </Form>
            {/* </CardSection> */}
          </Col>
        
        </Row>
      </OFFCanvas>
    </>
  )
}

export default EditSubCategory;  // Updated component name
