import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { topartWork } from "../../common/data/index";
import { featuredNFTData } from "../../common/data/dashboardNFT";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper";
// Import Chart
import { TopArtworkChart } from '../DashboardNFT/DashboardNFTCharts'; 
//SimpleBar
import SimpleBar from "simplebar-react";
import { Link } from 'react-router-dom';
const TopProducts = ({data}) => {
    console.log(data, 'dash data')
    return (
        <React.Fragment>
            <Row>
                
                <Col xxl={4}>
                    <Card>
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Top Viewed Products</h4>
                            {/* <div className="flex-shrink-0">
                                <div>
                                    <button type="button" className="btn btn-soft-primary btn-sm">
                                        See All
                                    </button>
                                </div>
                            </div> */}
                        </div>
                        <div className="d-flex mb-0 justify-content-between align-items-center px-5 flex shrink-1 md:px-md-5 pt-3 border-bottom">
                            <p className="">Product List</p>
                            <p className="">Subcategory</p>
                            <p className="">Category</p>
                            {/* <div className="flex-shrink-0">
                                <div>
                                    <button type="button" className="btn btn-soft-primary btn-sm">
                                        See All
                                    </button>
                                </div>
                            </div> */}
                        </div>
                        <CardBody>
                            <div className="table-responsive table-card">
                                <SimpleBar style={{ maxHeight: "405px" }}>
                                    <table className="table table-borderless align-middle">
                                        <tbody>
                                            {data?.map((item, key) => (
                                                <tr key={key}>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <img src={item.image} alt="" className="avatar-sm rounded-circle" />
                                                            <div className="ms-3">
                                                                <Link to="#">
                                                                    <h6 className="fs-15 mb-1">{item.name}</h6>
                                                                </Link>
                                                                <p className="mb-0 text-muted">{item.count} {item.count === 1 ? "View" : "Views"} </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {/* <TopArtworkChart seriesData={item.series} chartsColor={item.chartsColor} /> */}
                                                    </td>
                                                    <td className="">
                                                        <Link to="#">
                                                            <h6 className="fs-14 mb-1 px-3">{item.subcategory_name}</h6>
                                                        </Link>
                                                        {/* <p className="mb-0 text-muted">Total USD</p> */}
                                                    </td>
                                                    <td className="text-end">
                                                        <Link to="#">
                                                            <h6 className="fs-14 mb-1 px-3">{item.category_name}</h6>
                                                        </Link>
                                                        {/* <p className="mb-0 text-muted">Total USD</p> */}
                                                    </td>
                                                </tr>))}
                                        </tbody>
                                    </table>
                                </SimpleBar>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default TopProducts;