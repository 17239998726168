import React, {useEffect, useState} from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";

//import Components
import UpgradeAccountNotise from './UpgradeAccountNotise';
import UsersByDevice from './UsersByDevice';
import UsersByBrowser from './UsersByBrowser';
import Widget from './Widget';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import AudiencesMetrics from './AudiencesMetrics';
import AudiencesSessions from './AudiencesSessions';
import LiveUsers from './LiveUsers';
import TopReferrals from './TopReferrals';
import TopPages from './TopPages';
import { getDashboardData } from '../../store/dashboardAnalytics/action';
// import FeaturedNFT from '../DashboardNFT/FeaturedNFT';
import TopProducts from './TopProducts';
import { categoryList } from '../../store/admin/Category/action';

const DashboardAnalytics = () => {
document.title="Dashboard-Mirax";

// const [subCategory, setSubCategory] = useState([]);
// const [vendor, setVendor] = useState([]);


const dispatch = useDispatch();


const [allData, setAllData] = useState([]);
const [deviceData, setDeviceData] = useState();

const { dashboardData } = useSelector(state => ({
    dashboardData: state?.DashboardAnalytics?.dashboardData
}));
const vendorId =  sessionStorage.getItem("vendor_id");


const afterAdd = () =>{

} 
useEffect(() => {
    const product = {
        vendor_id : vendorId !== "1" ? vendorId: ""
    }   
        dispatch(getDashboardData(product, afterAdd));
    },[]);

    useEffect(() => {
        setAllData(dashboardData);
        setDeviceData(dashboardData?.device_counts)
    }, [dashboardData]);

    console.log(dashboardData, 'from dashboard')
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Analytics" pageTitle="Dashboards" />
                    <Row>
                        <Col xxl={5}>
                            {/* <UpgradeAccountNotise /> */}
                            <Widget data={dashboardData} />
                        </Col>
                        {/* <LiveUsers /> */}
                    </Row>
                    <Row>
                        {/* <AudiencesMetrics /> */}
                        {/* <AudiencesSessions /> */}
                        <TopProducts data={dashboardData?.Products} />
                    </Row>
                    <Row>
                        <UsersByDevice data={dashboardData?.device_counts} />
                        <UsersByBrowser data={dashboardData?.browser_counts} />
                        {/* <TopReferrals /> */}
                        {/* <TopPages /> */}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashboardAnalytics;