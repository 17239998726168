import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import { Col, Label, Row, Card, CardHeader, CardBody } from "reactstrap";
import EditVendor from './EditVendor';  // Updated import path
import DeleteVendorModal from './DeleteVendorModal';  // Updated import path
import { useSelector, useDispatch } from "react-redux";

import {
  getVendors as onGetVendors,  // Updated action name
  addNewVendor as onAddNewVendor,  // Updated action name
  updateVendor as onUpdateVendor,  // Updated action name
  deleteVendor as onDeleteVendor,  // Updated action name
  statusChange as onStatusChange
} from "../../../store/admin/Vendor/action";  // Updated import path
import ViewDetailModal from './ViewDetailModal';

const VendorTable = () => {  // Updated component name
  
  const [vendor, setVendor] = useState([]);  // Updated state name
  const [deleteVendor, setDeleteVendor] = useState()  // Updated state name
  const [editData, setEditData] = useState()  // Updated state name
  const [viewData, setViewData]= useState()
  const [currentPage, setCurrentPage] = useState(vendor.length === 0  ? 1 : vendor?.current_page);
  const [itemsPerPage, setItemsPerPage] = useState(vendor.length === 0  ? 10 : vendor?.page_size);


  
  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage, page) => {
    setItemsPerPage(newPerPage);
    setCurrentPage(page);
  };
  const { vendors, error, loading } = useSelector((state) => ({  // Updated selector name
    vendors: state?.Vendor?.vendors,  // Updated state name
    loading: state?.Vendor?.loading,  // Updated state name
    error: state?.Vendor?.error,  // Updated state name
  }));

  const dispatch = useDispatch();
  
  useEffect(() => {
    const data = {
      "page": currentPage,
      "page_size": itemsPerPage
  }
    dispatch(onGetVendors(data));  // Updated action name
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    setVendor(vendors);  // Updated state name
  }, [vendors]);



  const [flag, setflag] = useState(false);
  const [view, setView] =useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (vendor) => {
    setDeleteVendor(vendor);  // Updated state name
    setDeleteModal(true);
  };

  const handleVendorClick = (data) => {
    setEditData(data);
  };

  const handleViewClick = (data) =>{
     setViewData(data)
  }

  const afterDeleteChange = () =>{
    const pagination = {
      "page": currentPage,
      "page_size": itemsPerPage
  }
    dispatch(onGetVendors(pagination));   
      setDeleteModal(false);


  }

  const handleDeleteVendor = async () => {
    if (vendor) {
      await dispatch(onDeleteVendor(deleteVendor, afterDeleteChange));  // Updated action name
      // dispatch(onGetVendors());  // Updated action name
    }
  };

  const afterChange = () =>{
    const pagination = {
      "page": currentPage,
      "page_size": itemsPerPage
  }
    dispatch(onGetVendors(pagination));   

  }

  const statusChange  = async (id) =>{
   
  const data = {
    vendor_id: id
   }
    await dispatch(onStatusChange(data, afterChange));  // Updated action
    // dispatch(onGetVendors(pagination));   
  }

  const columns = [
    {
      name: <span className='font-weight-bold fs-13'>SR No.</span>,
      cell: (cellProps, index) => <div> {currentPage === 2 && currentPage - 1}{index + 1}</div>,
      // selector: (row, index) => index + 1 + pagination.rowsPerPage * (pagination.page - 1),
      sortable: true
    },
    {
      name: <span className='font-weight-bold fs-13'>User Name</span>,
      selector: row => row.user.name,
    },
    {
      name: <span className='font-weight-bold fs-13'>First Name</span>,
      selector: row => row.first_name,
    },
    {
      name: <span className='font-weight-bold fs-13'>Last Name</span>,
      selector: row => row.last_name,
    },
    // {
    //   name: <span className='font-weight-bold fs-13'>User Name</span>,
    //   selector: row => row.user_name,
    // },
    // {
    //   name: <span className='font-weight-bold fs-13'>Company Name</span>,
    //   selector: row => row.company_name,
    // },
     {
      name: <span className='font-weight-bold fs-13'>Address</span>,
      selector: row => row.address,
    },
    {
      name: <span className='font-weight-bold fs-13'>Phone Number</span>,
      selector: row => row.phone_number,
    },
    {
      name: <span className='font-weight-bold fs-13'>Email</span>,
      selector: row => row.user.email,
    },
    {
      name: <span className='font-weight-bold fs-13'>Image</span>,
      selector: row => <img src={row.profile_image} className='avatar-xs rounded-circle' alt="" /> ,
      sortable: true
  },
    // {
    //   name: <span className='font-weight-bold fs-13'>Password</span>,
    //   selector: row => row.password,
    // },
    // {
    //   name: <span className='font-weight-bold fs-13'>Domain</span>,
    //   selector: row => row.site_url,
    // },
    {
      name: <span className='font-weight-bold fs-13'>Status</span>,
      cell: (cellProps) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item">
            <div className="form-check form-switch form-switch-danger mb-3">
              {cellProps.status === 1 ?
             <Input onClick={()=>statusChange(cellProps?.vendor_id)} className="form-check-input" type="checkbox" role="switch" id="SwitchCheck5" defaultChecked /> :
             <Input onClick={()=>statusChange(cellProps?.vendor_id)} className="form-check-input" type="checkbox" role="switch" id="SwitchCheck5"  />

            }
             {/* <Label className="form-check-label" htmlFor="SwitchCheck5">Switch Danger</Label>  */}
            </div>
              {/* <Link
                to="#"
                className="text-danger d-inline-block remove-item-btn"
                onClick={() => {
                  const vendorData = cellProps?.id;
                  // onClickDelete(vendorData);
                }}
              >
                <i className="ri-delete-bin-5-fill fs-16"></i>
              </Link> */}
            </li>
          </ul>
        );
      },
    },
    {
      name: <span className='font-weight-bold fs-13'>Action</span>,
      cell: (cellProps) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
             <li className="list-inline-item">
                    <Link
                      to="#"
                      className="text-primary d-inline-block"
                      onClick={() => {
                        const vendorData = cellProps;
                        handleViewClick(vendorData);
                        setView(!view);
                      }}
                    >
                      <i className="ri-eye-fill fs-16"></i>
                    </Link>
                  </li>
            <li className="list-inline-item edit">
              <Link
                to="#"
                className="text-primary d-inline-block edit-item-btn"
                onClick={() => {
                  const vendorData = cellProps;
                  handleVendorClick(vendorData);
                  setflag(!flag);
                }}
              >
                <i className="ri-pencil-fill fs-16"></i>
              </Link>
            </li>
            
            {/* <li className="list-inline-item">
              <Link
                to="#"
                className="text-danger d-inline-block remove-item-btn"
                onClick={() => {
                  const vendorData = cellProps?.id;
                  onClickDelete(vendorData);
                }}
              >
                <i className="ri-delete-bin-5-fill fs-16"></i>
              </Link>
            </li> */}
          </ul>
        );
      },
    },
  ];

  const data = [
    {
        srNo: "01",
        id: "VLZ-452",
        purchaseId: "VLZ1400087402",
        title: "Post launch reminder/ post list",
        name: "Joseph Parker",
        email: "john@gmail.com",
        password: "12345678",
        domain: "customer.com",
        image: avatar2,
        assigned: "Alexis Clarke",
        createdBy: "Joseph Parker",
        createDate: "03 Oct, 2021",
        status: "Re-open",
        priority: "High",
    },
    {
        srNo: "02",
        id: "VLZ-453",
        purchaseId: "VLZ1400087425",
        title: "Additional Calendar",
        name: "Diana Kohler",
        email: "john@gmail.com",
        password: "12345678",
        domain: "customer.com",
        assigned: "Admin",
        image: avatar2,
        createdBy: "Mary Rucker",
        createDate: "05 Oct, 2021",
        status: "On-Hold",
        priority: "Medium",
    },
    {
        srNo: "03",
        id: "VLZ-454",
        purchaseId: "VLZ1400087438",
        title: "Make a creating an account profile",
        name: "Tonya Noble",
        email: "john@gmail.com",
        password: "12345678",
        domain: "customer.com",
        image: avatar2,
        assigned: "Admin",
        createdBy: "Tonya Noble",
        createDate: "27 April, 2022",
        status: "Closed",
        priority: "Low",
    },
    {
        srNo: "04",
        id: "VLZ-455",
        purchaseId: "VLZ1400087748",
        title: "Apologize for shopping Error!",
        name: "Joseph Parker",
        email: "john@gmail.com",
        password: "12345678",
        domain: "customer.com",
        image: avatar2,
        assigned: "Alexis Clarke",
        createdBy: "Joseph Parker",
        createDate: "14 June, 2021",
        status: "Inprogress",
        priority: "Medium",
    },
    {
        srNo: "05",
        id: "VLZ-456",
        purchaseId: "VLZ1400087547",
        title: "Support for theme",
        name: "Donald Palmer",
        email: "john@gmail.com",
        password: "12345678",
        domain: "customer.com",
        image: avatar2,
        assigned: "Admin",

        createdBy: "Donald Palmer",
        createDate: "25 June, 2021",
        status: "Closed",
        priority: "Low",
    },
    {
        srNo: "06",
        id: "VLZ-457",
        purchaseId: "VLZ1400087245",
        title: "Benner design for FB & Twitter",
        name: "Mary Rucker",
        email: "john@gmail.com",
        password: "12345678",
        domain: "customer.com",
        image: avatar2,
        assigned: "Jennifer Carter",
        createdBy: "Mary Rucker",
        createDate: "14 Aug, 2021",
        status: "Inprogress",
        priority: "Medium",
    },
    {
        srNo: "07",
        id: "VLZ-458",
        purchaseId: "VLZ1400087785",
        title: "Change email option process",
        name: "James Morris",
        email: "john@gmail.com",
        password: "12345678",
        domain: "customer.com",
        image: avatar2,
        assigned: "Admin",
        createdBy: "James Morris",
        createDate: "12 March, 2022",
        status: "Open",
        priority: "High",
    },
    {
        srNo: "08",
        id: "VLZ-460",
        purchaseId: "VLZ1400087745",
        title: "Support for theme",
        name: "Nathan Cole",
        email: "john@gmail.com",
        password: "12345678",
        domain: "customer.com",
        image: avatar2,
        assigned: "Nancy Martino",
        createdBy: "Nathan Cole",
        createDate: "28 Feb, 2022",
        status: "On-Hold",
        priority: "Low",
    },
    {
        srNo: "09",
        id: "VLZ-461",
        purchaseId: "VLZ1400087179",
        title: "Form submit issue",
        name: "Grace Coles",
        email: "john@gmail.com",
        password: "12345678",
        domain: "customer.com",
        image: avatar2,
        assigned: "Admin",
        createdBy: "Grace Coles",
        createDate: "07 Jan, 2022",
        status: "New",
        priority: "High",
    },
    {
        srNo: "10",
        id: "VLZ-462",
        purchaseId: "VLZ140008856",
        title: "Edit customer testimonial",
        name: "Freda",
        email: "john@gmail.com",
        password: "12345678",
        domain: "customer.com",
        image: avatar2,
        assigned: "Alexis Clarke",
        createdBy: "Freda",
        createDate: "16 Aug, 2021",
        status: "Closed",
        priority: "Medium",
    },
    {
        srNo: "11",
        id: "VLZ-462",
        purchaseId: "VLZ140008856",
        title: "Edit customer testimonial",
        name: "Freda",
        email: "john@gmail.com",
        password: "12345678",
        domain: "customer.com",
        image: avatar2,
        assigned: "Alexis Clarke",
        createdBy: "Freda",
        createDate: "16 Aug, 2021",
        status: "Closed",
        priority: "Medium",
    },
];
  // const rowsPerPage = 10;
  // const totalRows = vendor?.vendors?.length;
  
  // const totalPages = Math.ceil(totalRows / rowsPerPage);
  // const initialPage = totalRows > 0 ? 1 : 0;

  // const pagination = {
  //   rowsPerPage: 1,
  //   page: initialPage,
  //   totalRows: totalRows,
  // };


  return (
    <>
      <DataTable
        columns={columns}
        data={vendor?.list}
        // pagination={pagination}
        progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={vendor?.total_page * itemsPerPage}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            // highlightOnHover={true}
        />

 <DeleteVendorModal
        show={deleteModal}
        
        onDeleteClick={handleDeleteVendor}
        onCloseClick={() => setDeleteModal(false)}
      />

<EditVendor flag={flag} editData={editData} Handler={()=>{setflag(!flag)}} />
<ViewDetailModal flag={view} viewData={viewData}  Handler={()=>{setView(!view)}} />

    </>
  )
}

export default VendorTable