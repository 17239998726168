import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  FormFeedback,
} from "reactstrap";
import CardSection from "../../../Components/Common/CardSection";
// import ProductTable from "./ProductsTable";
// import ProductsForm from "./ProductsForm";
import BlankLayout from "../../../Components/Common/BlankLayout";
import { useDispatch, useSelector } from "react-redux";
import { getAppKeyData, changeAppKeyData } from "../../../store/dashboardAnalytics/action";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import copy from 'clipboard-copy';


const docData = [
  {
    title: "MiraxAIBtn",
    description: "The buttons with the class MiraxAIBtn represent different products. Data attributes such as 'data-product-id' and 'data-type-id' store relevant information.",
  },
  {
    title: "handleButtonClick():",
    description: "This function is triggered when a MiraxAI button is clicked. It extracts data attributes and sends the data to MiraxAI using the sendDataToMiraxAIProduct function.",
  },
  // {
  //   title: "MiraxAI API URL:",
  //   description: "Define the MiraxAI API URL where the data will be sent.",
  // },
  {
    title: "sendDataToMiraxAIProduct():",
    description: "Sends data to the MiraxAI product. It includes the configured parameters and a callback function to handle success or error responses.",
  },
  // {
  //   title: "Error Handling:",
  //   description: "If there is an error during camera access or while sending data to MiraxAI, appropriate error messages are logged.",
  // },
  
]

const Documentation = () => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [settingsData, setSettingsData] = useState([]);
  const [isEditingDisabled, setIsEditingDisabled] = useState(true);
  const [passwordShow, setPasswordShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  

  const { appKeyData } = useSelector((state) => ({
    appKeyData: state?.DashboardAnalytics?.appKeyData,
  }));

  const vendorId = sessionStorage.getItem("vendor_id");
  // const afterAdd = () => {}; 
  const afterChange =async () => {
    setDeleteModal(false)
    
    const product = {
      vendor_id: vendorId !== "1" ? vendorId : "",
    };
    if(deleteModal !== true){
      await dispatch(getAppKeyData(product, afterAdd));
    }
  };


  useEffect(() => {
    const product = {
      vendor_id: vendorId !== "1" ? vendorId : "",
    };
    dispatch(getAppKeyData(product, afterAdd));
  }, []);

  useEffect(() => {
    setSettingsData(appKeyData[0]);
  }, [appKeyData]);

  console.log(settingsData, "setting");
  
  const handleDeleteOrder = async () =>{
    const product = {
      vendor_id: vendorId !== "1" ? vendorId : "",
    };
    dispatch(changeAppKeyData(product, afterChange));
  }

 const copyToClipboard = () => {
    const copyText = document.getElementById("copyText");   


    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");

    /* Alert the user that the text has been copied */
    // alert("Copied: " + copyText.value);
  };

  const copyCode = () => {
    const copyCode = document.getElementById("copyCode");


    copyCode.select();
    copyCode.setSelectionRange(0, 99999); 
    document.execCommand("copy");

    /* Alert the user that the text has been copied */
    // alert("Copied: " + copyText.value);
  };

  // const handleCopyClick = async () => {
  //   try {
  //     await copy(textToCopy);
  //     console.log('Text copied to clipboard:', textToCopy);
  //   } catch (err) {
  //     console.error('Error copying to clipboard:', err);
  //   }
  // };
  const afterAdd = () => {};


  useEffect(() => {
    const product = {
      vendor_id: vendorId !== "1" ? vendorId : "",
    };
    dispatch(getAppKeyData(product, afterAdd));
  }, []);

  useEffect(() => {
    setSettingsData(appKeyData[0]);
  }, [appKeyData]);

  return (
    <React.Fragment>
      <BlankLayout
        metaTitle={"Documentation"}
        pageTitle={"Documentation"}
        breadcrumTitle={"Documentation"}
        breadcrumPagetitle={"Documentation"}
      >
        <Row>
          <Col xl={12}>
            <div>
               <p className="font-weight-bold fs-4">Introduction</p>
               <p>This documentation provides guidance on integrating MiraxAI with product in your web application. By clicking the buttons, relevant data is sent to the MiraxAI platform for analysis.                
               </p>
               </div>
               <div>
                <p className="font-weight-bold fs-4">Prerequisites:</p>
                <div>
                  <p className="font-weight-bold fs-4">MiraxAI SDK Inclusion</p>
                  <p>Ensure that you have included the MiraxAI SDK in your project. You can use the following CDN link to include the MiraxAI SDK in your HTML file:</p>
                </div>
                <div>
                <p className="font-weight-bold fs-4">MiraxAI Account</p>
                 <p>To use MiraxAI services, you need a valid MiraxAI account. Obtain the necessary credentials, including appKey, appId, and any other relevant information, from the MiraxAI platform.</p>
                </div>

               </div>

               <div className="">
        <div className="input-group position-relative">
          <textarea className="form-control" id="copyText" readOnly defaultValue={`<script src="https://cdn.jsdelivr.net/gh/DevaPandiyan/MiraxAI@main/Mirax.ai.%4001.prod.min.js"></script>

`} />
          <div className="input-group-append position-absolute end-0 top-0">
            <button className="btn btn-outline-secondary "  type="button" onClick={copyToClipboard}>
              Copy code
            </button>
          </div>
        </div>
      </div>


      <div className="mt-3">
        <p className="font-weight-bold fs-4">Code Integration:</p>
        <p>Integrate MiraxAI with your application use the following code snippet:</p>
      </div>

      
      <div className="">
        <div className="input-group position-relative">
          <textarea rows="20"  // Set the number of rows (height)
            cols="50" className="form-control " id="copyCode"   readOnly defaultValue={`<!DOCTYPE html>
            <html lang="en">
            <head>
              <meta charset="UTF-8">
              <meta name="viewport" content="width=device-width, initial-scale=1.0">
              <title>MiraxAI Product</title>
              <script src="https://code.jquery.com/jquery-3.6.4.min.js"></script>
              <script src="https://cdn.jsdelivr.net/gh/DevaPandiyan/MiraxAI@main/Mirax.ai.%4001.prod.min.js"></script>
            </head>
            <body>
            
            <button class="MiraxAIBtn" data-product-id="1978febe-cff1-4312-9b6a-8cbd37e8aa25"  data-type-id="1" >Jewellery </button>
            <button class="MiraxAIBtn" data-product-id="171dc362-9321-4d59-a760-70b366ba7a97"  data-type-id="2">Cosmetics Lips Product</button>
            <button class="MiraxAIBtn" data-product-id="9441b2c5-b2ec-40eb-ac55-5f8614401c36"  data-type-id="3">Handz </button>
            
            <script>
            // Function to handle button click
            function handleButtonClick() {
              const clickedButton = this;
              event.preventDefault();
              
                var product_id = event.target.getAttribute('data-product-id');
                var product_component = event.target.getAttribute('data-component');
                var product_TypeID = event.target.getAttribute('data-type-id');
            
              const TypeId = product_TypeID, ProductId = product_id, appKey = 'fa80ff07f89da155', appId = '1';
              
              sendDataToMiraxAIProduct(${settingsData?.app_id}, ${settingsData?.app_key}, TypeId, ProductId, 0.0, 0.0, (error, message) => {
                if (error) {
                  console.log("Error occurred:", error);
                } else {
                  console.log("Success:", message);
                }
              });
            }
            
            document.addEventListener('DOMContentLoaded', function() {
              const buttons = document.querySelectorAll('.MiraxAIBtn');
              buttons.forEach(function(button) {
                button.addEventListener('click', handleButtonClick);
              });
            });
            </script>
            
            </body>
            </html>
            
`} />
          <div className="input-group-append position-absolute end-0 top-0">
            <button className="btn btn-outline-secondary "  type="button" onClick={copyCode}>
              Copy code
            </button>
          </div>
        </div>
      </div>
      <div className="mt-3">
      <p className="font-weight-bold fs-4 ">Explanation:</p>
      {docData.map((values, index)=>{
        return(
          <>
            <div>
        <p className="font-weight-semibold">{index + 1 + ".   "}{values.title}</p>
        <p>{values.description}</p>
      </div>
          </>
        )
      })}
           <p className="font-weight-bold fs-4 ">Conclusion</p>
           <p>By following this documentation, you can successfully integrate MiraxAI  in your web application. Ensure that you have the MiraxAI SDK included and the configuration parameters set according to your MiraxAI account.</p>

      </div>
          </Col>
          <Col xl={8}>
            {/* <CardSection panelTitle="Products Table">
              <ProductTable />
            </CardSection> */}
          </Col>
        </Row>
      </BlankLayout>
     
    </React.Fragment>
    
  );
};

export default Documentation;
