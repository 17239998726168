
import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,

  
  // ADD_NEW_CUSTOMER,
  // ADD_CUSTOMER_SUCCESS,
  // ADD_CUSTOMER_FAIL,

  DELETE_BUSINESS_INQUIRY,
  DELETE_BUSINESS_INQUIRY_SUCCESS,
  DELETE_BUSINESS_INQUIRY_FAIL,

  // UPDATE_CUSTOMER,
  // UPDATE_CUSTOMER_SUCCESS,
  // UPDATE_CUSTOMER_FAIL,

  GET_BUSINESS_INQUIRY,

} from "./actionType"
// import BusinessInquiry from "./reducer";


export const businessInquiryApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const businessInquiryApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// Add
// export const addNewCustomer = customer => ({
//     type: ADD_NEW_CUSTOMER,
//     payload: customer,
//   });
  
//   //1
//   export const addCustomerSuccess = customer => ({
//     type: ADD_CUSTOMER_SUCCESS,
//     payload: customer,
//   });
//   // 1
//   export const addCustomerFail = customer => ({
//     type: ADD_CUSTOMER_FAIL,
//     payload: customer,
//   });

//getAll
  export const getBusinessInquiry = () => ({
    type: GET_BUSINESS_INQUIRY,
  });

//update
  // export const updateCustomer = customer => ({
  //   type: UPDATE_CUSTOMER,
  //   payload: customer,
  // });
  
  // export const updateCustomerSuccess = customer => ({
  //   type: UPDATE_CUSTOMER_SUCCESS,
  //   payload: customer,
  // });
  
  // export const updateCustomerFail = error => ({
  //   type: UPDATE_CUSTOMER_FAIL,
  //   payload: error,
  // });

//delete
  export const deleteBusinessInquiry = businessInquiry => ({
    type: DELETE_BUSINESS_INQUIRY,
    payload: businessInquiry,
  });
  
  export const deleteBusinessInquirySuccess = businessInquiry => ({
    type: DELETE_BUSINESS_INQUIRY_SUCCESS,
    payload: businessInquiry,
  });
  
  export const deleteBusinessInquiryFail = error => ({
    type: DELETE_BUSINESS_INQUIRY_FAIL,
    payload: error,
  });
  