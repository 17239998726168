// sagas/formSaga.js
import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    FORM_SUBMIT_REQUEST,

} from "./actionType"
import {
  formSubmitSuccess,
  formSubmitFailure,
} from './action';
import {
  changeProfilePic
} from "../../../helpers/fakebackend_helper";
import NotificationService from '../../../Service/NotificationService';

toast.configure();


function* submitFormSaga(action) {
  try {
    // Assuming your server endpoint is '/api/submitForm'
    const response = yield call(changeProfilePic, action.payload);  // Updated action type
    if(response.status){
      NotificationService.success(response.message)
    }
    // const response = yield call(axios.post, '/api/submitForm', action.payload);

    yield put(formSubmitSuccess(response.data));
  } catch (error) {
    yield put(formSubmitFailure(error.message));
    NotificationService.error(error)

  }
}

function* formSaga() {
  yield takeLatest(FORM_SUBMIT_REQUEST, submitFormSaga);
}

export default formSaga;
