import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_COUNTRY_CHARTS_DATA, GET_AUDIENCESMETRICS_CHARTS_DATA, GET_USERDEVICE_CHARTS_DATA, GET_AUDIENCESSESSIONS_CHARTS_DATA, GET_DASHBOARD_DATA, GET_APPKEY_DATA, CHANGE_APPKEY_DATA } from "./actionType";
import { dashboardAnalyticsApiSuccess, dashboardAnalyticsApiError, getDashboardDataSuccess, getDashboardDataFailure, getAppKeyDataSuccess, getAppKeyDataFailure, changeAppKeyDataFailure, changeAppKeyDataSuccess } from "./action";
import NotificationService from "../../Service/NotificationService";
//Include Both Helper File with needed methods
import {
  getAllData,
  getHalfYearlyData,
  getMonthlyData,
  getAllAudiencesMetricsData,
  getMonthlyAudiencesMetricsData,
  getHalfYearlyAudiencesMetricsData,
  getYearlyAudiencesMetricsData,
  getTodayDeviceData,
  getLastWeekDeviceData,
  getLastMonthDeviceData,
  getCurrentYearDeviceData,
  getTodaySessionData,
  getLastWeekSessionData,
  getLastMonthSessionData,
  getCurrentYearSessionData,
  getDashboardData,
  getAppKeyData,
  changeAppKeyData
}
  from "../../helpers/fakebackend_helper";

function* getChartsData({ payload: data }) {
  try {
    var response;
    if (data === "all") {
      response = yield call(getAllData, data);
    }
    if (data === "halfyearly") {
      response = yield call(getHalfYearlyData, data);
    }
    if (data === "monthly") {
      response = yield call(getMonthlyData, data);
    }

    yield put(dashboardAnalyticsApiSuccess(GET_COUNTRY_CHARTS_DATA, response));
  } catch (error) {
    yield put(dashboardAnalyticsApiError(GET_COUNTRY_CHARTS_DATA, error));
  }
}

function* getAudiencesMetricsChartsData({ payload: data }) {
  try {
    var response;
    if (data === "all") {
      response = yield call(getAllAudiencesMetricsData, data);
    }
    if (data === "monthly") {
      response = yield call(getMonthlyAudiencesMetricsData, data);
    }
    if (data === "halfyearly") {
      response = yield call(getHalfYearlyAudiencesMetricsData, data);
    }
    if (data === "yearly") {
      response = yield call(getYearlyAudiencesMetricsData, data);
    }

    yield put(dashboardAnalyticsApiSuccess(GET_AUDIENCESMETRICS_CHARTS_DATA, response));
  } catch (error) {
    yield put(dashboardAnalyticsApiError(GET_AUDIENCESMETRICS_CHARTS_DATA, error));
  }
}

function* getUserDeviceChartsData({ payload: data }) {
  try {
    var response;
    if (data === "today") {
      response = yield call(getTodayDeviceData, data);
    }
    if (data === "lastWeek") {
      response = yield call(getLastWeekDeviceData, data);
    }
    if (data === "lastMonth") {
      response = yield call(getLastMonthDeviceData, data);
    }
    if (data === "currentYear") {
      response = yield call(getCurrentYearDeviceData, data);
    }

    yield put(dashboardAnalyticsApiSuccess(GET_USERDEVICE_CHARTS_DATA, response));
  } catch (error) {
    yield put(dashboardAnalyticsApiError(GET_USERDEVICE_CHARTS_DATA, error));
  }
}
function* getAudiencesSessionsChartsData({ payload: data }) {
  try {
    var response;
    if (data === "today") {
      response = yield call(getTodaySessionData, data);
    }
    if (data === "lastWeek") {
      response = yield call(getLastWeekSessionData, data);
    }
    if (data === "lastMonth") {
      response = yield call(getLastMonthSessionData, data);
    }
    if (data === "currentYear") {
      response = yield call(getCurrentYearSessionData, data);
    }

    yield put(dashboardAnalyticsApiSuccess(GET_AUDIENCESSESSIONS_CHARTS_DATA, response));
  } catch (error) {
    yield put(dashboardAnalyticsApiError(GET_AUDIENCESSESSIONS_CHARTS_DATA, error));
  }
}


function* getAllDashboardData({ payload:{product , afterAdd}  }) {
  try {
    const response = yield call(getDashboardData, product);
    if(response.status){
      // NotificationService.success(response.message);
      afterAdd()
    }
    console.log(response, 'from success response')
    yield put(getDashboardDataSuccess(response));

  } catch (error) {
    // console.error('Error adding product:', error);
    yield put(getDashboardDataFailure(error));
    // NotificationService.error(error);

  }
}

function* getAllAppKeyData({ payload: { product, afterAdd } }) {
  try {
    const response = yield call(getAppKeyData, product);
    console.log(response, 'res from saga')
    if (response.status) {
      // NotificationService.success(response.message);
      afterAdd();
    }
    yield put(getAppKeyDataSuccess(response));

   

  } catch (error) {
    // console.error('Error adding product:', error);
    yield put(getAppKeyDataFailure(error));
    // NotificationService.error(error);
  }
}

function* changeAllAppKeyData({ payload: { product, afterAdd } }) {
  try {
    const response = yield call(changeAppKeyData, product);
    console.log(response, 'res from saga');
    if(response.status) {
      NotificationService.success(response.message);
      afterAdd();
    }
    // yield put(changeAppKeyDataSuccess(response));
  } catch (error) {
    // yield put(changeAppKeyDataFailure(error));
    // NotificationService.error(error);
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_COUNTRY_CHARTS_DATA, getChartsData);
}

export function* watchGetAudiencesMetricsChartsData() {
  yield takeEvery(GET_AUDIENCESMETRICS_CHARTS_DATA, getAudiencesMetricsChartsData);
}

export function* watchGetUserDeviceChartsData() {
  yield takeEvery(GET_USERDEVICE_CHARTS_DATA, getUserDeviceChartsData);
}

export function* watchGetAudiencesSessionsChartsData() {
  yield takeEvery(GET_AUDIENCESSESSIONS_CHARTS_DATA, getAudiencesSessionsChartsData);
}

export function* getVendorDashboardData() {
  yield takeEvery(GET_DASHBOARD_DATA, getAllDashboardData);
}
export function* getAppkeyData() {
  yield takeEvery(GET_APPKEY_DATA, getAllAppKeyData);
}
export function* changeAppkeyData() {
  yield takeEvery(CHANGE_APPKEY_DATA, changeAllAppKeyData);
}

function* dashboardAnalyticsSaga() {
  
  yield all([fork(getVendorDashboardData)]);
  yield all([fork(getAppkeyData)]);
  yield all([fork(changeAppkeyData)]);

  yield all([fork(watchGetChartsData)]);
  yield all([fork(watchGetAudiencesMetricsChartsData)]);
  yield all([fork(watchGetUserDeviceChartsData)]);
  yield all([fork(watchGetAudiencesSessionsChartsData)]);
}

export default dashboardAnalyticsSaga;
