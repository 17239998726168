// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";

export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";
export const ADD_CUSTOMER_FAIL = "ADD_CUSTOMER_FAIL";

export const GET_CUSTOMERS = "GET_CUSTOMERS";

// * Edit CUSTOMER

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAIL = "UPDATE_CUSTOMER_FAIL";

/**
* Delete CUSTOMER
*/
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAIL = "DELETE_CUSTOMER_FAIL";