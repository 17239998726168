import {
    FORM_SUBMIT_REQUEST,
    FORM_SUBMIT_FAILURE,
    FORM_SUBMIT_SUCCESS
}  from  "./actionType"


export const formSubmitRequest = (formData) => ({
    type: FORM_SUBMIT_REQUEST,
    payload: formData,
  });
  
  export const formSubmitSuccess = (data) => ({
    type: FORM_SUBMIT_SUCCESS,
    payload: data,
  });
  
  export const formSubmitFailure = (error) => ({
    type: FORM_SUBMIT_FAILURE,
    payload: error,
  });