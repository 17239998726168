import React from 'react';
import { Row } from 'reactstrap';
import BlankLayout from '../../../Components/Common/BlankLayout';
import VendorTable from './VendorTable';  // Updated import path

const VendorList = () => {  // Updated component name

  return (
    <>
      <React.Fragment>

        <BlankLayout metaTitle={'Customer Section'} pageTitle={'Customer'} breadcrumTitle={'Customer list'} breadcrumPagetitle={'Customer'}>  {/* Updated metaTitle and breadcrumTitle */}
          <Row>
            <VendorTable />  {/* Updated table component */}
          </Row>

        </BlankLayout>
      </React.Fragment>
    </>
  )
}

export default VendorList;
