// Layout
export * from "./layouts/action";
// Authentication module
export * from "./auth/register/actions";
export * from "./auth/login/actions";
export * from "./auth/forgetpwd/actions";
export * from "./auth/resetpwd/actions";

export * from "./auth/profile/actions";
//calendar
// export * from "./calendar/actions";
//chat
export * from "./chat/action";
//project
export * from "./projects/action";
//ecommerce
// export * from "./ecommerce/action";
// Task
export * from "./tasks/action";
//Form advanced 
export * from './formAdvanced/action';
// Crypto
export * from "./crypto/action";
//TicketsList
export * from "./tickets/action";
//crm
export * from "./crm/action";
//invoice
export * from "./invoice/action";
//mailbox
export * from "./mailbox/action";
// Dashboard Analytics
export * from "./dashboardAnalytics/action";
// Dashboard Analytics
export * from "./dashboardCRM/action";
// Dashboard Ecommerce
// export * from "./dashboardEcommerce/action";
// Dashboard Crypto
export * from "./dashboardCrypto/action";
// Dashboard Project
export * from "./dashboardProject/action";
// Dashboard NFT
export * from "./dashboardNFT/action";
// Pages > Team
export * from "./team/action";
// File Manager
export * from "./fileManager/action"
// To do
export * from "./todos/action"
//job
export * from "./job/action";

//API Key
export * from "./apikey/action";

export * from  "./admin/businessinquiry/action"
export * from  "./admin/customer/action"
export * from  "./admin/GetInTouch/action"
export * from  "./admin/Category/action"
export * from "./admin/product/action"
export * from "./admin/Vendor/action"

export * from "./admin/ProfilePic/action"
export * from "./admin/SubCategory/action"



// new 
// export * from "./admin/customer/action"
