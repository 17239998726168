
import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,



  DELETE_GETIN_TOUCH,
  DELETE_GETIN_TOUCH_SUCCESS,
  DELETE_GETIN_TOUCH_FAIL,


  GET_GETIN_TOUCH,

} from "./actionType"


export const getInTouchApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const getInTouchApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// // Add
// export const addNewCustomer = customer => ({
//     type: ADD_NEW_CUSTOMER,
//     payload: customer,
//   });
  
//   //1
//   export const addCustomerSuccess = customer => ({
//     type: ADD_CUSTOMER_SUCCESS,
//     payload: customer,
//   });
//   // 1
//   export const addCustomerFail = customer => ({
//     type: ADD_CUSTOMER_FAIL,
//     payload: customer,
//   });

//getAll
  export const getGetInTouch = () => ({
    type: GET_GETIN_TOUCH,
  });

//update
  // export const updateCustomer = customer => ({
  //   type: UPDATE_CUSTOMER,
  //   payload: customer,
  // });
  
  // export const updateCustomerSuccess = customer => ({
  //   type: UPDATE_CUSTOMER_SUCCESS,
  //   payload: customer,
  // });
  
  // export const updateCustomerFail = error => ({
  //   type: UPDATE_CUSTOMER_FAIL,
  //   payload: error,
  // });

//delete
  export const deleteGetInTouch = data => ({
    type: DELETE_GETIN_TOUCH,
    payload: data,
  });
  
  export const deleteGetInTouchSuccess = data => ({
    type: DELETE_GETIN_TOUCH_SUCCESS,
    payload: data,
  });
  
  export const deleteGetInTouchFail = error => ({
    type: DELETE_GETIN_TOUCH_FAIL,
    payload: error,
  });
  