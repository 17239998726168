import { act } from "react-dom/test-utils";
import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_SUBCATEGORY,  // Updated action type
  ADD_SUBCATEGORY_SUCCESS,  // Updated action type
  ADD_SUBCATEGORY_FAIL,  // Updated action type
  GET_SUBCATEGORIES,  // Updated action type
  UPDATE_SUBCATEGORY_SUCCESS,  // Updated action type
  UPDATE_SUBCATEGORY_FAIL,  // Updated action type
  DELETE_SUBCATEGORY_SUCCESS,  // Updated action type
  DELETE_SUBCATEGORY_FAIL,  // Updated action type
  EDIT_SUBCATEGORY,
  EDIT_SUBCATEGORY_SUCCESS,
  EDIT_SUBCATEGORY_FAIL, 
  // FETCH_SUBCATEGORIES_SUCCESS, 
  // FETCH_SUBCATEGORIES_FAILURE,
  FETCH_SUBCATEGORIES
 
} from "./actionType";

const INIT_STATE = {
  subcategories: [],  // Updated state property
  subCategoryList: [] ,
  editData: null,
  loading: false,
  errorMsg: "",
  error: null,
};

const Subcategory = (state = INIT_STATE, action) => {


  // console.log(action, state, '????')
  switch (action.type) {

    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_SUBCATEGORIES:  // Updated action type
          return {
            ...state,
            subcategories: action.payload.data,  // Updated state property
            loading: false,
          };
          case FETCH_SUBCATEGORIES:
            return{
              ...state,
              subCategoryList: action.payload.data,
            }

        default:
          return {
            ...state,
            loading: false,
          };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_SUBCATEGORIES:  // Updated action type
          return {
            ...state,
            error: action.payload.error,
          };
          case FETCH_SUBCATEGORIES:
            return {
              ...state,
              error: action.payload.error,
              
            };
        
        default:
          return {
            ...state,
            loading: true,
          };
      }
    case ADD_NEW_SUBCATEGORY:
      return {
      ...state,
      loading: true,
    };
    case GET_SUBCATEGORIES:  // Updated action type
      return {
        ...state,
        subcategories: Array.isArray(state.subcategories) ? [...state.subcategories, action?.payload?.data] : [action?.payload?.data],
        loading: false,
      };

    case ADD_SUBCATEGORY_SUCCESS:  // Updated action type
      return {
        ...state,
        subcategories: [...state.subcategories, action.payload.data],  // Updated state property
        loading: false,
      };

    case ADD_SUBCATEGORY_FAIL:  // Updated action type
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

      // case FETCH_SUBCATEGORIES_SUCCESS:
      // return {
      //   ...state,
      //   subCategoryList: action.payload.subcategories,
      //   loading: false,
      //   error: null,
      // };
     
      // case FETCH_SUBCATEGORIES_FAILURE:
      // return { ...state, loading: false, error: action.payload.error };

    case UPDATE_SUBCATEGORY_SUCCESS:  // Updated action type
      return {
        ...state,
        subcategories: Array.isArray(state?.subcategories)
          ? state.subcategories.map((subcategory) =>
              subcategory?.subcategory_id.toString() === action.payload.data.subcategory_id.toString()
                ? { ...subcategory, ...action.payload.data }
                : subcategory
            )
          : [],
      };

    case UPDATE_SUBCATEGORY_FAIL:  // Updated action type
      return {
        ...state,
        error: action.payload,
      };

      case EDIT_SUBCATEGORY_SUCCESS:
  return {
    ...state,
    editData: action.payload.data
  };

case EDIT_SUBCATEGORY_FAIL:
  return {
    ...state,
    error: action.payload,
  };
   
  
    case DELETE_SUBCATEGORY_SUCCESS:  // Updated action type
      return {
        ...state,
        subcategories: Array.isArray(state?.subcategories)
          ? state.subcategories.filter(
              (subcategory) => subcategory?.subcategory_id.toString() !== action.payload.subcategory.toString()
            )
          : [],
      };

    case DELETE_SUBCATEGORY_FAIL:  // Updated action type
      return {
        ...state,
        error: action.payload,
      };

   

    default:
      return { ...state };
  }
};

export default Subcategory;  // Updated component name
