
import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

  
    // ADD_NEW_BUSINESS_INQUIRY,
    // ADD_BUSINESS_INQUIRY_SUCCESS,
    // ADD_BUSINESS_INQUIRY_FAIL,

    GET_BUSINESS_INQUIRY,

    // UPDATE_CUSTOMER_SUCCESS,
    // UPDATE_CUSTOMER_FAIL,


    DELETE_BUSINESS_INQUIRY_SUCCESS,
    DELETE_BUSINESS_INQUIRY_FAIL,

  } from "./actionType"

  const INIT_STATE = {
    businessInquiry: [],
  };

  const BusinessInquiry = (state = INIT_STATE, action) => {
    switch (action.type) {
      case API_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {
           
          case GET_BUSINESS_INQUIRY:
            return {
              ...state,
              businessInquiry: action.payload.data,
              isBusinessInquiryCreated: false,
              isBusinessInquirySuccess: true
            };
  
          default:
            return { ...state };
        }
      case API_RESPONSE_ERROR:
        switch (action.payload.actionType) {
      
          case GET_BUSINESS_INQUIRY:
            return {
              ...state,
              error: action.payload.error,
              isBusinessInquiryCreated: false,
              isBusinessInquirySuccess: false
            };
          default:
            return { ...state };
        }
      case GET_BUSINESS_INQUIRY:
        return {
          ...state,
          isBusinessInquiryCreated: false
        };
  
      ///////////////////
      // case ADD_BUSINESS_INQUIRY_SUCCESS:
      //   return {
      //     ...state,
      //     isBusinessInquiryCreated: true,
      //     businessInquiry: [...state.businessInquiry, action.payload.data],
      //   };
     
      // case ADD_BUSINESS_INQUIRY_FAIL:
      //   return {
      //     ...state,
      //     error: action.payload,
      //   };

        // case UPDATE_BUSINESS_INQUIRY_SUCCESS:
        //   return {
        //     ...state,
        //     businessInquiry: state.businessInquiry.map(businessInquiry =>
        //       businessInquiry._id.toString() === action.payload.data._id.toString()
        //         ? { ...businessInquiry, ...action.payload.data }
        //         : businessInquiry
        //     ),
        //   };
    
        // case UPDATE_BUSINESS_INQUIRY_FAIL:
        //   return {
        //     ...state,
        //     error: action.payload,
        //   };
    
    
        case DELETE_BUSINESS_INQUIRY_SUCCESS:
          return {
            ...state,
            businessInquiry: state.businessInquiry.filter(
              businessInquiry => businessInquiry._id.toString() !== action.payload.businessInquiry.toString()
            ),
          };
    
        case DELETE_BUSINESS_INQUIRY_FAIL:
          return {
            ...state,
            error: action.payload,
          };
  
      default:
        return { ...state };
    }
  };
  
  export default BusinessInquiry;