import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_COUNTRY_CHARTS_DATA,
  GET_AUDIENCESMETRICS_CHARTS_DATA,
  GET_USERDEVICE_CHARTS_DATA,
  GET_AUDIENCESSESSIONS_CHARTS_DATA,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAILURE,
  GET_APPKEY_DATA_SUCCESS,
  GET_APPKEY_DATA_FAILURE,
  CHANGE_APPKEY_DATA_SUCCESS,
  CHANGE_APPKEY_DATA_FAILURE
} from "./actionType";

const INIT_STATE = {
  dashboardData: [],
  appKeyData: [],
  changeAppKeyData: [],
  chartData: [],
  audiencesMetricsData: [],
  userDeviceData: [],
  audiencesSessionsData: [],
  error: {},
};

const DashboardAnalytics = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_COUNTRY_CHARTS_DATA:
          return {
            ...state,
            chartData: action.payload.data,
          };
        // case GET_DASHBOARD_DATA:
        // return {
        //   ...state,
        //   dashboardData: action.payload.data,
        // };
        case GET_AUDIENCESMETRICS_CHARTS_DATA:
          return {
            ...state,
            audiencesMetricsData: action.payload.data,
          };
        case GET_USERDEVICE_CHARTS_DATA:
          return {
            ...state,
            userDeviceData: action.payload.data,
          };
        case GET_AUDIENCESSESSIONS_CHARTS_DATA:
          return {
            ...state,
            audiencesSessionsData: action.payload.data,
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_COUNTRY_CHARTS_DATA:
          return {
            ...state,
            error: action.payload.error,
          };
        // case GET_DASHBOARD_DATA:
        // return {
        //   ...state,
        //   error: action.payload.error,
        // };
        case GET_AUDIENCESMETRICS_CHARTS_DATA:
          return {
            ...state,
            error: action.payload.error,
          };
        case GET_USERDEVICE_CHARTS_DATA:
          return {
            ...state,
            error: action.payload.error,
          };
        case GET_AUDIENCESSESSIONS_CHARTS_DATA:
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return state;
      }
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload.data, // Updated state property
        loading: false,
      };
    case GET_DASHBOARD_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case GET_APPKEY_DATA_SUCCESS:
      return {
        ...state,
        appKeyData: [...state.appKeyData, action.payload.data], // Updated state property
        loading: false,
      };
    case GET_APPKEY_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
      case CHANGE_APPKEY_DATA_SUCCESS:
        return {
          ...state,
          changeAppKeyData: [...state.changeAppKeyData, action.payload.data],
          loading: false,
        };
      case CHANGE_APPKEY_DATA_FAILURE:
        return { ...state, loading: false, error: action.payload.error };
    default:
      return state;
  }
};
export default DashboardAnalytics;
