import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { useNavigate } from "react-router-dom";
// Login Redux States
import { RESET_PASSWORD } from "./actionTypes";  // Updated action type
import { userResetPasswordSuccess, userResetPasswordError } from "./actions";  // Updated action creators
import NotificationService from "../../../Service/NotificationService";
// Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeResetPwd,  // Updated function name
  postJwtResetPwd,  // Updated function name
} from "../../../helpers/fakebackend_helper";
import { toast } from "react-toastify";

const fireBaseBackend = getFirebaseBackend();

// If user is sent a successful mail link, dispatch redux actions directly from here.
function* resetUser({ payload: { user, history, navigateData } }) {  // Updated generator function name
  
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.resetPassword, user.email);  // Updated function name
      if (response) {
        yield put(
          userResetPasswordSuccess(  // Updated action creator
            "Reset link has been sent to your mailbox, please check there first"
          )
        );
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {

      const response = yield call(postJwtResetPwd, {  // Updated function name
        token: user.token,
        password: user.password
      });
      if (response) {
        NotificationService.success(response.message)
        navigateData(response?.data?.token)
        yield put(
          userResetPasswordSuccess(  // Updated action creator
            // "Reset link has been sent to your mailbox, please check there first"
          )
        );
      }
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeResetPwd, user);  // Updated function name
      if (response) {
        yield put(
          userResetPasswordSuccess(  // Updated action creator
            "Reset link has been sent to your mailbox, please check there first"
          )
        );
      }
    }
  } catch (error) {
    yield put(userResetPasswordError(error));  // Updated action creator
  }
}

export function* watchUserPasswordReset() {  // Updated watcher function name
  yield takeEvery(RESET_PASSWORD, resetUser);  // Updated action type
}

function* resetPasswordSaga() {  // Updated saga function name
  yield all([fork(watchUserPasswordReset)]);  // Updated watcher function name
}

export default resetPasswordSaga;  // Updated saga function name
