import { act } from "react-dom/test-utils";
import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_VENDOR,  // Updated action type
  ADD_VENDOR_SUCCESS,  // Updated action type
  ADD_VENDOR_FAIL,  // Updated action type
  GET_VENDORS,  // Updated action type
  UPDATE_VENDOR_SUCCESS,  // Updated action type
  UPDATE_VENDOR_FAIL,  // Updated action type
  DELETE_VENDOR_SUCCESS,  // Updated action type
  DELETE_VENDOR_FAIL,  // Updated action type
  STATUS_CHANGE_SUCCESS,
  STATUS_CHANGE_FAIL, 
  VENDOR_LIST
} from "./actionType";

const INIT_STATE = {
  vendors: [],  // Updated state property
  vendorData: [],
  loading: false,
  errorMsg: "",
  error: null,
};

const Vendor = (state = INIT_STATE, action) => {
  switch (action.type) {

    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_VENDORS:  // Updated action type
          return {
            ...state,
            vendors: action.payload.data,  // Updated state property
            loading: false,
          };
          case VENDOR_LIST:  // Updated action type
          return {
            ...state,
            vendorData: action.payload.data,  // Updated state property
          };

        default:
          return {
            ...state,
            loading: false,
          };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_VENDORS:  // Updated action type
          return {
            ...state,
            error: action.payload.error,
          };
          case VENDOR_LIST:  // Updated action type
          return {
            ...state,
            error: action.payload.error,
          };
        default:
          return {
            ...state,
            loading: true,
          };
      }
    case ADD_NEW_VENDOR :
      return {
      ...state,
      loading: true,
    };
    case GET_VENDORS:  // Updated action type
      return {
        ...state,
        vendors: Array.isArray(state.vendors) ? [...state.vendors, action?.payload?.data] : [action?.payload?.data],
        loading: false,
      };

    case ADD_VENDOR_SUCCESS:  // Updated action type
      return {
        ...state,
        vendors: [...state.vendors, action.payload.data],  // Updated state property
        loading: false,
      };

    case ADD_VENDOR_FAIL:  // Updated action type
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case UPDATE_VENDOR_SUCCESS:  // Updated action type
      return {
        ...state,
        vendors: Array.isArray(state?.vendors)
          ? state.vendors.map((vendor) =>
              vendor?.vendor_id.toString() === action.payload.data.vendor_id.toString()
                ? { ...vendor, ...action.payload.data }
                : vendor
            )
          : [],
      };

    case UPDATE_VENDOR_FAIL:  // Updated action type
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_VENDOR_SUCCESS:  // Updated action type
      return {
        ...state,
        vendors: Array.isArray(state?.vendors)
          ? state.vendors.filter(
              (vendor) => vendor?.vendor_id.toString() !== action.payload.vendor.toString()
            )
          : [],
      };

    case DELETE_VENDOR_FAIL:  // Updated action type
      return {
        ...state,
        error: action.payload,
      };

      case STATUS_CHANGE_SUCCESS:  // Updated action type
      return {
        ...state,
        vendors: Array.isArray(state?.vendors)
          ? state.vendors.filter(
              (vendor) => vendor?.vendor_id.toString() !== action.payload.vendor.toString()
            )
          : [],
      };

    case STATUS_CHANGE_FAIL:  // Updated action type
      return {
        ...state,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Vendor;  // Updated component name
