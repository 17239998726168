import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

class NotificationService {
  static showToast(message, options = {}) {
    toast(message, { ...defaultOptions, ...options });
  }

  static success(message, options = {}) {
    toast.success(message, { ...defaultOptions, ...options });
  }

  static warning(message, options = {}) {
    toast.warning(message, { ...defaultOptions, ...options });
  }

  static error(message, options = {}) {
    toast.error(message, { ...defaultOptions, ...options });
  }
}

export default NotificationService;
