import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_SUBCATEGORY,  // Updated action type
  ADD_SUBCATEGORY_SUCCESS,  // Updated action type
  ADD_SUBCATEGORY_FAIL,  // Updated action type
  DELETE_SUBCATEGORY,  // Updated action type
  DELETE_SUBCATEGORY_SUCCESS,  // Updated action type
  DELETE_SUBCATEGORY_FAIL,  // Updated action type
  UPDATE_SUBCATEGORY,  // Updated action type
  UPDATE_SUBCATEGORY_SUCCESS,  // Updated action type
  UPDATE_SUBCATEGORY_FAIL,  // Updated action type
  GET_SUBCATEGORIES,  // Updated action type

  EDIT_SUBCATEGORY,
  EDIT_SUBCATEGORY_SUCCESS,
  EDIT_SUBCATEGORY_FAIL,
  
  FETCH_SUBCATEGORIES,
  // FETCH_SUBCATEGORIES_SUCCESS,
  // FETCH_SUBCATEGORIES_FAILURE,
 
} from "./actionType";

export const subcategoryApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const subcategoryApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const addNewSubcategory = (subcategory, afterAdd) => ({
  type: ADD_NEW_SUBCATEGORY,  // Updated action type
  payload: { subcategory, afterAdd },
});

export const addSubcategorySuccess = subcategory => ({
  type: ADD_SUBCATEGORY_SUCCESS,  // Updated action type
  payload: subcategory,
});

export const addSubcategoryFail = subcategory => ({
  type: ADD_SUBCATEGORY_FAIL,  // Updated action type
  payload: subcategory,
});

export const getSubcategories = subcategory => ({
  type: GET_SUBCATEGORIES,  // Updated action type
  payload: subcategory ,
});

export const updateSubcategory =(subcategory, afterChange)  => ({
  type: UPDATE_SUBCATEGORY,  // Updated action type
  payload:{subcategory, afterChange} ,
});

export const updateSubcategorySuccess = subcategory => ({
  type: UPDATE_SUBCATEGORY_SUCCESS,  // Updated action type
  payload: subcategory,
});

export const updateSubcategoryFail = error => ({
  type: UPDATE_SUBCATEGORY_FAIL,  // Updated action type
  payload: error,
});

export const editSubcategory = (subcategory, afterChange) => ({
  type: EDIT_SUBCATEGORY,
  payload: { subcategory, afterChange },
});

export const editSubcategorySuccess = (subcategory) => ({
  type: EDIT_SUBCATEGORY_SUCCESS,
  payload: subcategory,
});

export const editSubcategoryFail = (error) => ({
  type: EDIT_SUBCATEGORY_FAIL,
  payload: error,
});

export const deleteSubcategory = (subcategory, afterChange) => ({
  type: DELETE_SUBCATEGORY,  // Updated action type
  payload: {subcategory, afterChange},
});

export const deleteSubcategorySuccess = subcategory => ({
  type: DELETE_SUBCATEGORY_SUCCESS,  // Updated action type
  payload: subcategory,
});

export const deleteSubcategoryFail = error => ({
  type: DELETE_SUBCATEGORY_FAIL,  // Updated action type
  payload: error,
});


export const fetchSubcategories = subcategory => ({
  type: FETCH_SUBCATEGORIES,
  payload: subcategory,
});

// export const fetchSubcategoriesSuccess = (subcategories) => ({
//   type: FETCH_SUBCATEGORIES_SUCCESS,
//   payload: { subcategories },
// });

// export const fetchSubcategoriesFailure = (error) => ({
//   type: FETCH_SUBCATEGORIES_FAILURE,
//   payload: { error },
// });


// export const statusChange = (subcategory, afterChange)  => ({
//   type: STATUS_CHANGE,  // Updated action type
//   payload: {subcategory, afterChange},
// });

// export const statusChangeSuccess = subcategory => ({
//   type: STATUS_CHANGE_SUCCESS,  // Updated action type
//   payload: subcategory,
// });

// export const statusChangeFail = error => ({
//   type: STATUS_CHANGE_FAIL,  // Updated action type
//   payload: error,
// });
