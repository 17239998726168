
import { act } from "react-dom/test-utils";
import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

  
    ADD_NEW_CUSTOMER,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_FAIL,

    GET_CUSTOMERS,

    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAIL,


    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAIL,

  } from "./actionType"

  const INIT_STATE = {
    customers: [],
    loading: false,
    errorMsg: "",
    error: null,
  };

  const Customer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case API_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {
           
          case GET_CUSTOMERS:
            return {
              ...state,
              customers: action.payload.data,
              loading: false
             
            };
  
          default:
            return { ...state,
              loading: false
             };
        }
      case API_RESPONSE_ERROR:
        switch (action.payload.actionType) {
      
          case GET_CUSTOMERS:
            return {
              ...state,
              error: action.payload.error,
            
            };
          default:
            return { ...state,
              loading: true
             };
        }
      case GET_CUSTOMERS:
        return {
          ...state,
          loading: false
        };
  
      case ADD_CUSTOMER_SUCCESS:
        return {
          ...state,
          customers: [...state.customers, action.payload.data],
          loading: false
        }; 
     
      case ADD_CUSTOMER_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false
        };

        case UPDATE_CUSTOMER_SUCCESS:
          return {
            ...state,
            customers: Array.isArray(state?.customers)
            ? state.customers.map(customer =>
                customer?.id.toString() === action.payload.data.id.toString()
                  ? { ...customer, ...action.payload.data }
                  : customer
              )
            : []
          };
    
        case UPDATE_CUSTOMER_FAIL:
          return {
            ...state,
            error: action.payload,
          };
     
        case DELETE_CUSTOMER_SUCCESS:        
          return {
            ...state,
            customers: Array.isArray(state?.customers)
            && state.customers.filter(
                customer => customer?.id.toString() !== action.payload.customer.toString()
              )
            
          };
    
        case DELETE_CUSTOMER_FAIL:
          return {
            ...state,
            error: action.payload,
          };
  
      default:
        return { ...state };
    }
  };
  
  export default Customer;