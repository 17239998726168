import React from 'react'
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';

export default function OFFCanvas({ flag, handler, direction, canvasTitle, children }) {
    return (
        <Offcanvas direction={direction} isOpen={flag} toggle={handler} id={`offcanvas-${direction}`}>
            <OffcanvasHeader toggle={handler} id={`offcanvas-${direction}-label`}>
                {canvasTitle}
            </OffcanvasHeader>
            <OffcanvasBody>
                {children}
            </OffcanvasBody>
            
        </Offcanvas>
    )
}
