import React, { useState } from "react";
import { Col, Input, Label, Row, Card, CardHeader, CardBody } from "reactstrap";
import BlankLayout from "../../../Components/Common/BlankLayout";
import CardSection from "../../../Components/Common/CardSection";
import SubCategoryTable from "./SubCategoryTable";
import AddSubCategory from "./AddSubCategory";

const Category = () => {
 
  return (
    <React.Fragment>
      <BlankLayout
        metaTitle={"SubCategory Section"}
        pageTitle={"subcategory"}
        breadcrumTitle={"subcategory"}
        breadcrumPagetitle={"subcategory"}
      >
        <Row>
          <Col xl={4}>
            <CardSection panelTitle="Add SubCategory">
              <AddSubCategory />
            </CardSection>
          </Col>
          <Col xl={8}>
            <CardSection panelTitle="SubCategory Table">
              <SubCategoryTable />
            </CardSection>
          </Col>
        </Row>
       
      </BlankLayout>
    </React.Fragment>
  );
};

export default Category;
