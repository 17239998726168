// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";

export const ADD_NEW_SUBCATEGORY = "ADD_NEW_SUBCATEGORY";
export const ADD_SUBCATEGORY_SUCCESS = "ADD_SUBCATEGORY_SUCCESS";
export const ADD_SUBCATEGORY_FAIL = "ADD_SUBCATEGORY_FAIL";

export const GET_SUBCATEGORIES = "GET_SUBCATEGORIES";

// * Edit SUBCATEGORY

export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const UPDATE_SUBCATEGORY_SUCCESS = "UPDATE_SUBCATEGORY_SUCCESS";
export const UPDATE_SUBCATEGORY_FAIL = "UPDATE_SUBCATEGORY_FAIL";


export const EDIT_SUBCATEGORY = "EDIT_SUBCATEGORY";
export const EDIT_SUBCATEGORY_SUCCESS = "EDIT_SUBCATEGORY_SUCCESS";
export const EDIT_SUBCATEGORY_FAIL = "EDIT_SUBCATEGORY_FAIL";

/**
* Delete SUBCATEGORY
*/
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_FAIL = "DELETE_SUBCATEGORY_FAIL";

// actionTypes.js
export const FETCH_SUBCATEGORIES = 'FETCH_SUBCATEGORIES';
// export const FETCH_SUBCATEGORIES_SUCCESS = 'FETCH_SUBCATEGORIES_SUCCESS';
// export const FETCH_SUBCATEGORIES_FAILURE = 'FETCH_SUBCATEGORIES_FAILURE';

// status change
// export const STATUS_CHANGE = "STATUS_CHANGE";
// export const STATUS_CHANGE_SUCCESS = "STATUS_CHANGE_SUCCESS";
// export const STATUS_CHANGE_FAIL = "STATUS_CHANGE_FAIL";
