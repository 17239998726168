import React from 'react';
import { Row } from 'reactstrap';
import BlankLayout from '../../../Components/Common/BlankLayout';
import SubCategoryTable from './SubCategoryTable';  // Updated import path

const SubCategoryList= () => {  // Updated component name

  return (
    <>
      <React.Fragment>

        <BlankLayout metaTitle={'SubCategory Section'} pageTitle={'SubCategory'} breadcrumTitle={'SubCategory list'} breadcrumPagetitle={'SubCategory'}>  {/* Updated metaTitle and breadcrumTitle */}
          <Row>
            <SubCategoryTable />  {/* Updated table component */}
          </Row>

        </BlankLayout>
      </React.Fragment>
    </>
  )
}

export default SubCategoryList;
