// sagas.js
import { takeLatest, put, call, all , fork, takeEvery} from 'redux-saga/effects';
import {
  fetchProductsSuccess,
  fetchProductsFailure,
  addProductSuccess,
  addProductFailure,
  updateProductSuccess,
  updateProductFailure,
  deleteProductSuccess,
  deleteProductFailure,
  productApiResponseSuccess,
  productApiResponseError,
  editProductSuccess,
  editProductFail,
     
  productStatusChangeSuccess,
  productStatusChangeFail
} from './action';
import NotificationService from "../../../Service/NotificationService"

import {
  FETCH_PRODUCTS,
  DELETE_PRODUCT,
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  EDIT_PRODUCT,
  PRODUCT_LIST,
  PRODUCT_STATUS_CHANGE,
  VENDOR_FETCH_PRODUCTS
}  from "./actionType"

import {
  fetchProducts, 
  addProduct,
  updateProduct,
  deleteProduct, 
  editProduct,
  productListData,
  productStatusChange,
  vendorFetchProducts
}  from "../../../helpers/fakebackend_helper"

// const api = {
//   fetchProducts: () => fetch('/api/products').then((response) => response.json()),
//   addProduct: (product) => fetch('/api/products', { method: 'POST', body: JSON.stringify(product) }),
//   updateProduct: (product) => fetch(`/api/products/${product.id}`, { method: 'PUT', body: JSON.stringify(product) }),
//   deleteProduct: (productId) => fetch(`/api/products/${productId}`, { method: 'DELETE' }),
// };

function* productList({ payload: product }) {
  try {
    const response = yield call(productListData, product);
    yield put(productApiResponseSuccess(PRODUCT_LIST, response.data));
  } catch (error) {
    yield put(productApiResponseError(PRODUCT_LIST, error));
  }
}

function* getProducts({ payload: product }) {
  try {
    const response = yield call(fetchProducts, product);
    yield put(productApiResponseSuccess(FETCH_PRODUCTS, response.data));
  } catch (error) {
    yield put(productApiResponseError(FETCH_PRODUCTS, error));
  }
}

function* vendorGetProducts({ payload: product }) {
  try {
    const response = yield call(vendorFetchProducts, product);
    console.log(response, 'res')
    yield put(productApiResponseSuccess(VENDOR_FETCH_PRODUCTS, response.data));
  } catch (error) {
    yield put(productApiResponseError(VENDOR_FETCH_PRODUCTS, error));
  }
}


function* addNewProduct({ payload:{product , afterAdd}  }) {
  try {
    const response = yield call(addProduct, product);
    if(response.status){
      NotificationService.success(response.message);
      afterAdd()
    }
    yield put(addProductSuccess(response));

  } catch (error) {
    // console.error('Error adding product:', error);
    yield put(addProductFailure(error));
    NotificationService.error(error);

  }
}

function* updateProducts({ payload:{ product , afterUpdate}}) {
  try {
    const response = yield call(updateProduct, product);
    if(response?.status){
      NotificationService.success(response.message);
      afterUpdate()
    }
    yield put(updateProductSuccess({ product, ...response }));
  } catch (error) {
    yield put(updateProductFailure(error));
    NotificationService.error(error);

  }
}

function* deleteProducts({ payload: product }) {
  try {
    const response = yield call(deleteProduct, product);
    if(response.status){
      NotificationService.success(response.message);
    }
    yield put(deleteProductSuccess({ product, ...response }));
  } catch (error) {
    yield put(deleteProductFailure(error));
    NotificationService.error(error);

  }
}

function* onEditProduct({ payload: { product, afterChange } }) {
  try {
    const response = yield call(editProduct, product);
    if (response.status) {
      afterChange();
    }
    yield put(editProductSuccess(response));

  } catch (error) {
    yield put(editProductFail(error));
    NotificationService.error(error);
  }
}

function* onStatusChange({ payload: {product, afterChange } }) {  // Updated action type
  try {
    const response = yield call(productStatusChange, product);  // Updated action type
    console.log(response, 'res')
    if(response.status){
      NotificationService.success(response.message)
      afterChange()
    }
    yield put(productStatusChangeSuccess({ product, ...response }));  // Updated action type


  } catch (error) {
    yield put(productStatusChangeFail(error));  // Updated action type
    if(error){
      NotificationService.error(error)
    }
  }
}




export function* watchAddProduct() {
  yield takeEvery(ADD_PRODUCT, addNewProduct);
}

export function* watchGetProducts() {
  yield takeEvery(FETCH_PRODUCTS, getProducts);
}

export function* watchVendorGetProducts() {
  yield takeEvery(VENDOR_FETCH_PRODUCTS, vendorGetProducts);
}

export function* watchProductList() {
  yield takeEvery(PRODUCT_LIST, productList);
}
export function* watchUpdateProduct() {
  yield takeEvery(UPDATE_PRODUCT, updateProducts);
}

export function* watchDeleteProduct() {
  yield takeEvery(DELETE_PRODUCT, deleteProducts);
}

export function* watchEditProduct() {
  yield takeEvery(EDIT_PRODUCT, onEditProduct);
}

export function* watchStatusChange() {  // Updated action type
  yield takeEvery(PRODUCT_STATUS_CHANGE, onStatusChange);  // Updated action type
}

function* productSaga() {
  yield all([
    fork(watchAddProduct),
    fork(watchDeleteProduct),
    fork(watchGetProducts),
    fork(watchVendorGetProducts),
    fork(watchEditProduct),
    fork(watchProductList),
    fork(watchUpdateProduct),
    fork(watchStatusChange)

  ]);
}

export default productSaga;
