import React, { useState } from "react";
import { Col, Input, Label, Row, Card, CardHeader, CardBody } from "reactstrap";
import BlankLayout from "../../../Components/Common/BlankLayout";
import CardSection from "../../../Components/Common/CardSection";
import ProductTable from "./ProductsTable";
import ProductsForm from "./ProductsForm";
import FilterModal from "./FilterModal";

const VendorProducts = () => {
  const [filter, setFilter] = useState(false)
  const [filterIds, setFilterIds]  = useState([])

  console.log(filterIds, 'filterids')

  return (

    <React.Fragment>
      <BlankLayout
        metaTitle={"Products Section"}
        pageTitle={"products"}
        breadcrumTitle={"products"}
        breadcrumPagetitle={"products"}
      >
        <Row>
          <Col xl={4}>
            <CardSection panelTitle="Products">
              <ProductsForm />
            </CardSection>
          </Col>
          <Col xl={8}>
            <CardSection panelTitle="Products Table" button="Filter" clickHandler={()=>{setFilter(!filter)}}>
              <ProductTable filterIds={filterIds} />
            </CardSection>
          </Col>
        </Row>
       
      </BlankLayout>
      <FilterModal setId={setFilterIds} filter={filter} Handler={()=>{setFilter(!filter)}}  />

    </React.Fragment>

  );
};

export default VendorProducts;
