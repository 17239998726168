import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Ecoomerce Redux States
import {
  
  // ADD_NEW_BUSINESS_INQUIRY,

  GET_BUSINESS_INQUIRY,

  // UPDATE_BUSINESS_INQUIRY,
  DELETE_BUSINESS_INQUIRY
} from "./actionType";

import {
  businessInquiryApiResponseSuccess,
  businessInquiryApiResponseError,
  
  //   addBusinessInquiryFail,
  //   addBusinessInquirySuccess,

  //   updateBusinessInquirySuccess,
  // updateBusinessInquiryFail,

  deleteBusinessInquirySuccess,
  deleteBusinessInquiryFail,
   
  } from "./action";

  import {
  
    // addNewBusinessInquiry,
    getBusinessInquiry as getBusinessInquiryApi,
    // updateBusinessInquiry,
    deleteBusinessInquiry,
   
  } from "../../../helpers/fakebackend_helper";

  toast.configure();


  // function* onAddNewBusinessInquiry({ payload: businessInquiry }) {
  //   try {
  //     const response = yield call(addNewBusinessInquiry, businessInquiry);
  //     yield put(addBusinessInquirySuccess(response));
  //     toast.success(response.message, { autoClose: 3000 });
  //   } catch (error) {
  //     yield put(addBusinessInquiryFail(error));
  //     console.log(error?.response?.data?.message, 'error')
  //     toast.error(error?.response?.data?.message, { autoClose: 3000 });
  //   }
  // }


// //update
//   function* onUpdateBusinessInquiry({ payload: businessInquiry }) {
//     try {
//       const response = yield call(updateBusinessInquiry, businessInquiry);
//       yield put(updateBusinessInquirySuccess(response));
//       toast.success(response.message, { autoClose: 3000 });
//     } catch (error) {
//       yield put(updateBusinessInquiryFail(error));
//       toast.error(error.response?.data?.message, { autoClose: 3000 });
//     }
//   }
  

  //delete
function* onDeleteBusinessInquiry({ payload: businessInquiry }) {
  try {
    const response = yield call(deleteBusinessInquiry, businessInquiry);
    if(response.status){
      toast.success(response.message, { autoClose: 3000 });
    }
    yield put(deleteBusinessInquirySuccess({ businessInquiry, ...response }));
  } catch (error) {
    console.log(error, 'delete error')

    yield put(deleteBusinessInquiryFail(error));
    toast.error(error.response?.data?.message, { autoClose: 3000 });
  }
}
//getAll
function* getBusinessInquiry() {
  try {
    const response = yield call(getBusinessInquiryApi);
    yield put(businessInquiryApiResponseSuccess(GET_BUSINESS_INQUIRY, response.data));
  } catch (error) {
    yield put(businessInquiryApiResponseError(GET_BUSINESS_INQUIRY, error));
  }
}




  // export function* watchAddNewBusinessInquiry() {
  //   yield takeEvery(ADD_NEW_BUSINESS_INQUIRY, onAddNewBusinessInquiry);
  // }

  export function* watchGetBusinessInquiry() {
    yield takeEvery(GET_BUSINESS_INQUIRY, getBusinessInquiry);
  }
  // export function* watchUpdateBusinessInquiry() {
  //   yield takeEvery(UPDATE_BUSINESS_INQUIRY, onUpdateBusinessInquiry);
  // }
  
  export function* watchDeleteBusinessInquiry() {
    yield takeEvery(DELETE_BUSINESS_INQUIRY, onDeleteBusinessInquiry);
  }
  
  


  function* businessInquirySaga() {
    yield all([
      fork(watchGetBusinessInquiry),
      // fork(watchAddNewBusinessInquiry),
      fork(watchDeleteBusinessInquiry),
      // fork(watchUpdateBusinessInquiry),
    ]);
  }
  
  export default businessInquirySaga;