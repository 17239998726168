import React, { useState, useEffect, useRef } from "react";
import { Col, Input, Label, Row, Form, FormFeedback } from "reactstrap";
import BlankLayout from "../../../Components/Common/BlankLayout";
import CardSection from "../../../Components/Common/CardSection";
import OFFCanvas from "../../../Components/Common/OffCanvas/OFFCanvas";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { categoryList } from "../../../store/admin/Category/action";
import { fetchSubcategories } from "../../../store/admin/SubCategory/action";
import { vendorList } from "../../../store/admin/Vendor/action";
import default_image from "../../../assets/images/default-image.jpg";
import {
  // getSubcategories as onGetSubcategories,  // Updated action
  editProduct as onEditProduct,
  updateProduct, // Updated action
  fetchProducts,
  productList,
  vendorFetchProducts,
  // updateSubcategory as onUpdateSubcategory
} from "../../../store/admin/product/action";
import Select from "react-select";

const EditProduct = (props) => {
  const [productData, setProductData] = useState([]);

  const [id, setId] = useState(null);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    productData.length === 0 ? 1 : productData?.current_page
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    productData.length === 0 ? 10 : productData?.page_size
  );
  const [productListData, setProductListData] = useState([]);
  const [childData, setChildData] = useState(null);

  const handleChildData = (data) => {
    setChildData(data);
  };

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const setImageData = watch("product_list");
  // const setStatus = watch("sets_status");
  //  const categoryId  = watch('category_id');
  //  const subcategoryId = watch('subcategory_id')

  // category list
  const { categories } = useSelector((state) => ({
    categories: state?.Category?.categories,
  }));

  useEffect(() => {
    dispatch(categoryList());
  }, []);

  useEffect(() => {
    setCategory(categories);
  }, [categories]);

  // vendor list
  const { vendorData } = useSelector((state) => ({
    vendorData: state?.Vendor?.vendorData,
  }));

  useEffect(() => {
    dispatch(vendorList());
  }, []);

  useEffect(() => {
    setVendor(vendorData);
  }, [vendorData]);

  const SingleOptions = [
    { value: "round", label: "Round" },
    { value: "square", label: "Square" },
  ];
  const setsOptions = [
    { value: "true", label: "true" },
    { value: "false", label: "false" },
  ];
  const flipOptions = [
    { value: "right", label: "Right" },
    { value: "left", label: "Left" },
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    setEditedData(props?.editData);
    // setCategoryId(props?.editData?.category_id)
  }, [props?.editData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log(file, "file");
    if (file) {
      setImage(file);
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  const afterEditChange = () => {
    //   const pagination = {
    //     "page": 1,
    //     "page_size": 10
    // }
    //   dispatch(onGetSubcategories(pagination));
    //   props.Handler();
  };

  let categorySelectedOption = category?.find(
    (option) => option?.id === editedData?.category_id
  );
  let subcategorySelectedOption ;
  let vendorSelectedOption;
  let productTypeSelectedOption;
  let setSelectedOption;
  let flipSelectedOption;

  console.log(editedData, 'edite')

  useEffect(() => {
    setValue("name", editedData?.name);
    console.log(editedData, 'edite')

    //  categorySelectedOption = category?.find((option) => option?.id === editedData?.category_id);
    subcategorySelectedOption = subCategory?.find(
      (option) => option?.id === editedData?.subcategory_id
    );
    vendorSelectedOption = vendor?.find(
      (option) => option?.id === editedData?.vendor_id
    );
    productTypeSelectedOption = SingleOptions?.find(
      (option) => option?.value === editedData?.product_type
    );
    setSelectedOption = setsOptions?.find(
      (option) => option?.value === editedData?.sets_status
    );
    flipSelectedOption =  flipOptions?.find(
      (option) => option?.value === editedData?.flip_position
    );
    setValue('category_id', categoryId ? categoryId : categorySelectedOption?.id);
    setValue("subcategory_id", subcategorySelectedOption?.id);
    setValue("vendor_id", vendorSelectedOption?.id);
    setValue("product_type", productTypeSelectedOption?.value);
    setValue("flip_position", flipSelectedOption?.value);
    setValue("sets_status", setSelectedOption?.value);
    setValue("color_code", editedData?.color_code);
  }, [setValue, category, subCategory, vendor, SingleOptions, flipOptions]);

  //subcategory list
  const { subCategoryList } = useSelector((state) => ({
    subCategoryList: state?.Subcategory?.subCategoryList,
  }));

  useEffect(() => {
    const payload = {
      category_id: categoryId ? categoryId : editedData?.category_id,
    };
    console.log(categorySelectedOption?.id, 'cat done')
    if(categoryId !== categorySelectedOption?.id){
    dispatch(fetchSubcategories(payload));
    }
  }, [editedData?.category_id, categoryId]);

  useEffect(() => {
    setSubCategory(subCategoryList);
  }, [subCategoryList]);

  // product list
  const { productAllList } = useSelector((state) => ({
    productAllList: state?.Product?.productAllList,
  }));

  useEffect(() => {
    const payload = {
      category_id: editedData?.category_id,
      vendor_id : vendorId
    };
    if (editedData?.category_id) {
      dispatch(productList(payload));
    }
  }, [editedData?.category_id]);

  useEffect(() => {
    setProductListData(productAllList);
  }, [productAllList]);

  const vendorId =  sessionStorage.getItem("vendor_id");


  const onSubmit = (data) => {
    // Handle the form submission, including uploaded files in 'selectedFiles'
    console.log(data, "from submit");
    const formData = new FormData();

    formData.append("uuid", editedData?.uuid);
    formData.append("category_id", categoryId !== null ? categoryId : editedData?.category_id);
    // categoryId !== "1"  | (categoryId === null && props?.editData?.category_id !== "1"
    formData.append("subcategory_id", data?.subcategory_id);
    formData.append("vendor_id", vendorId);

    formData.append("name", data.name);

    if (categoryId !== null ? categoryId : editedData?.category_id) {
      formData.append(
        "sets_status",
        editedData?.sets_status === "true" ? editedData?.sets_status : "false"
      );
      if (data?.subcategory_id === 4) {
        formData.append("flip_position", data?.flip_position);
      }
      if (editedData?.sets_status === "true") {
        formData.append(
          "product_set",
          JSON.stringify(data?.product_list?.map((item) => item.value))
        );
        formData.append("set_image_url", childData);
      }
    } 
     if (categoryId !== null ? categoryId === "2" : editedData?.category_id === 2) {
      formData.append("color_code", data.color_code);
    }

    if (editedData?.sets_status !== "true") {
      formData.append("image", image ? image : editedData?.product_image.split('/').pop());
      formData.append("product_type", data?.product_type);
    }
    console.log(formData, "from submit form");

    // dispatch(addProduct(formData, afterAdd));

    // var payload;
    // if(data?.category_id  === 2){
    //   payload = {
    //     uuid : editedData?.uuid,
    //     category_id: data?.category_id,
    //     subcategory_id: data?.subcategory_id,
    //     vendor_id: data?.vendor_id,
    //     product_type: data?.product_type,
    //     name: data?.name,
    //     color_code: data?.color_code,
    //     image: image
    // }
    // }
    // else if(data?.category_id === 3){
    //   payload = {
    //     uuid : editedData?.uuid,
    //     category_id: data?.category_id,
    //     subcategory_id: data?.subcategory_id,
    //     vendor_id: data?.vendor_id,
    //     product_type: data?.product_type,
    //     name: data?.name,
    //     // color_code: data?.color_code,
    //     image: image
    // }
    // }
    // else{
    //   if(editedData?.sets_status === "false"){
    //     payload = {
    //       uuid :editedData?.uuid,
    //       category_id: data?.category_id,
    //       subcategory_id: data?.subcategory_id,
    //       vendor_id: data?.vendor_id,
    //       product_type: data?.product_type,
    //       name: data?.name,
    //       image: image ,
    //       sets_status: editedData?.sets_status,
    //       // if(editedData?.sets_status){
    //       //   set_image_url : childData ? childData : editedData?.set_image_url
    //       // }
    //   }
    //   }
    //   else{
    //     payload = {
    //       uuid :editedData?.uuid,
    //       category_id: data?.category_id,
    //       subcategory_id: data?.subcategory_id,
    //       vendor_id: data?.vendor_id,
    //       product_type: data?.product_type,
    //       name: data?.name,
    //       // image: image ,
    //       sets_status: editedData?.sets_status,
    //       set_image_url : childData ? childData : editedData?.set_image_url
    //   }
    //   }

    // }
    //    console.log(payload,'payloa')
    dispatch(updateProduct(formData, afterUpdate));
  };



  const afterUpdate = () => {
    setCategoryId(null)
    const data = {
      page: 1,
      page_size: 10,
      vendor_id: vendorId,
      subcategory : []

    };
    dispatch(vendorFetchProducts(data));
    props.Handler();
    reset({
      product_list: "",
      category_id : ""
    });
   
  };

  useEffect(()=>{
    // reset({     
    //   category_id : ""
    // });
    // setValue('category_id', editedData?.category_id);
    setCategoryId(null)
    // alert('hi')
    
  }, [props.flag])

  return (
    <>
      <OFFCanvas
        canvasTitle={"Edit Value"}
        direction={"end"}
        flag={props.flag}
        handler={() =>{ props.Handler();
          setImagePreview(editedData?.set_image_url)
         } }
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Col
            md={12}
            className={`mb-3 ${errors.category_id ? "d-block" : ""}" md={12}`}
          >
            <Controller
              name="category_id"
              control={control}
              defaultValue={categorySelectedOption?.id} // Set the default value if needed
              // rules={{ required: "Category is required" }}
              render={({ field }) => (
                <>
                  <Label htmlFor="dropdownValue" className="form-label">
                    Select Category
                  </Label>

                  <select
                    {...field}
                    className={`form-control`}
                    id="dropdownValue"
                    onChange={(e) => {
                      const selectedCategoryId = e.target.value;
                      // const selectedCategory = category.find((option) => option.id === selectedCategoryId);
                      setCategoryId(selectedCategoryId);
                    }}
                  >
                    <option value={categorySelectedOption} selected>
                      {categorySelectedOption?.name}
                    </option>
                    {Array.isArray(category) &&
                      category?.length !== 0 &&
                      category?.map(
                        (option) =>
                          option !== categorySelectedOption && (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          )
                      )}
                  </select>
                  {/* {errors?.category_id && (
                        <FormFeedback className="d-block">
                          {errors?.category_id?.message}
                        </FormFeedback>
                      )} */}
                </>
              )}
            />
          </Col>
          <Col
            md={12}
            className={`mb-3 ${
              errors.subcategory_id ? "d-block" : ""
            }" md={12}`}
          >
            <Controller
              name="subcategory_id"
              control={control}
              defaultValue="" // Set the default value if needed
              rules={{ required: "SubCategory is required" }}
              render={({ field }) => (
                <>
                  <Label htmlFor="dropdownValue" className="form-label">
                    Select SubCategory
                  </Label>

                  <select
                    {...field}
                    className={`form-control`}
                    id="dropdownValue"
                    
                  >
                    {/* {console.log(subcategorySelectedOption?.id, 'sub id')} */}
                    <option value={subcategorySelectedOption?.id} selected>
                      {subcategorySelectedOption?.name}
                    </option>
                    {Array.isArray(subCategory) &&
                      subCategory?.length !== 0 &&
                      subCategory?.map(
                        (option) =>
                          option !== subcategorySelectedOption && (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          )
                      )}
                  </select>
                  {/* {errors?.subcategory_id && (
                        <FormFeedback className="d-block">
                          {errors?.subcategory_id?.message}
                        </FormFeedback>
                      )} */}
                </>
              )}
            />
          </Col>
          {console.log(props?.editData?.category_id, 'cat final id')}

           {console.log(editedData?.subcategory_id, 'final id')}
           {/* selected only pending */}
          {categoryId === "1"  || (categoryId === null && props?.editData?.category_id === 1) &&
            editedData?.subcategory_id === 4 && (
            <Col
              md={12}
              className={`mb-3 ${
                errors.flip_position ? "d-block" : ""
              }" md={12}`}
            >
              <Controller
                name="flip_position"
                control={control}
                defaultValue="" // Set the default value if needed
                // rules={{ required: "Product Type is required" }}
                render={({ field }) => (
                  <>
                    <Label htmlFor="dropdownValue" className="form-label">
                      Select Position
                    </Label>
                    <select
                      {...field}
                      className={`form-control ${
                        errors.flip_position ? "is-invalid" : ""
                      }`}
                      id="dropdownValue"
                    >
                       <option value={flipSelectedOption?.value} selected>
                      {flipSelectedOption?.label}
                    </option>
                      {Array.isArray(flipOptions) &&
                        flipOptions?.length !== 0 &&
                        flipOptions?.map((option, index) => (
                          <option key={option?.index} value={option?.value}>
                            {option?.label}
                          </option>
                        ))}
                    </select>
                    {errors?.flip_position && (
                      <FormFeedback className="d-block">
                        {errors?.flip_position?.message}
                      </FormFeedback>
                    )}
                  </>
                )}
              />
            </Col>
          ) }

          
          <Col md={12}>
            <div className="mb-3">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: "Product Name is required" }}
                render={({ field }) => (
                  <>
                    <Label for="firstNameinput" className="form-label">
                      Name
                    </Label>
                    <Input
                      {...field}
                      type="text"
                      invalid={errors.name ? true : false}
                      className="form-control"
                      placeholder="Enter your name"
                      id="firstNameinput"
                    />
                    {/* <FormFeedback type="invalid">
                          {errors.name && errors.name.message}
                        </FormFeedback> */}
                  </>
                )}
              />
            </div>
          </Col>


         {/* // final crt */}
          {categoryId === "2"  | (categoryId === null && props?.editData?.category_id === "2") ? (
                <Col
                  md={12}
                  className={` mb-3 ${
                    errors.color_code ? "d-block" : ""
                  }" md={12}`}
                >
                  <Controller
                    name="color_code"
                    control={control}
                    rules={{ required: "Color code is required" }}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <Label htmlFor="color_code" className="form-label">
                          Choose Color
                        </Label>
                        <Input
                          type="color"
                          {...field}
                          className={`form-control ${
                            errors.color_code ? "is-invalid" : ""
                          }`}
                          // invalid={errors.color_code ? true : false}
                          // className="form-control"
                          placeholder="Choose color"
                        />
                        {/* {errors?.color_code && (
                        <FormFeedback className="d-block">
                          {errors?.color_code?.message}
                        </FormFeedback>
                      )} */}
                      </>
                    )}
                  />
                </Col>
              ) : ""}

          {(props?.editData?.sets_status !== "true") ?
          // (categoryId !== 1  | (categoryId === null && props?.editData?.category_id !== 1)) ?
           (
            <Col
              md={12}
              className={`mb-3 ${
                errors.product_type ? "d-block" : ""
              }" md={12}`}
            >
              <Controller
                name="product_type"
                control={control}
                defaultValue="" // Set the default value if needed
                rules={{ required: "Product Type is required" }}
                render={({ field }) => (
                  <>
                    <Label htmlFor="dropdownValue" className="form-label">
                      Select Product Type
                    </Label>
                    <select
                      {...field}
                      className={"form-control"}
                      id="dropdownValue"
                    >
                     
                      <option value={productTypeSelectedOption?.value} selected>
                        {productTypeSelectedOption?.label}
                      </option>
                      {Array.isArray(SingleOptions) &&
                        SingleOptions?.length !== 0 &&
                        SingleOptions?.map(
                          (option, index) =>
                            option !== productTypeSelectedOption && (
                              <option key={option?.index} value={option?.value}>
                                {option?.label}
                              </option>
                            )
                        )}
                    </select>
                    {/* {errors?.product_type && (
                    <FormFeedback className="d-block">
                      {errors?.product_type?.message}
                    </FormFeedback>
                  )} */}
                  </>
                )}
              />
            </Col>
          ) : (
            ""
          )}

          {/* {props?.editData?.category_id === 1 && props?.editData?.sets_status === "true"  && (
            <Col
            md={12}
            className={`mb-3 ${errors.sets_status ? "d-block" : ""}" md={12}`}
          >
            <Controller
              name="sets_status"
              control={control}
              defaultValue="" 
              render={({ field }) => (
                <>
                  <Label htmlFor="dropdownValue" className="form-label">
                    Add Jwellery Set
                  </Label>
                  <select
                    {...field}
                    className={`form-control ${
                      errors.sets_status ? "is-invalid" : ""
                    }`}
                    id="dropdownValue"
                  >
                    
                    <option
                          value={setSelectedOption?.value}
                          selected
                        >
                          {setSelectedOption?.label}
                        </option>
                    {Array.isArray(setsOptions) &&
                      setsOptions?.length !== 0 &&
                      setsOptions?.map((option, index) =>
                      option !== setSelectedOption && (
                        <option key={option?.index} value={option?.value}>
                          {option?.label}
                        </option>
                      ))}
                    
                  </select>
                  
                </>
              )}
            />
          </Col>
            )} */}

          {props?.editData?.sets_status === "true" &&
          //  (categoryId === "1"  | (categoryId === null && props?.editData?.category_id === "1"))
            props?.editData?.category_id === 1 && (
              <Col
                md={12}
                className={` mb-3 ${
                  errors.product_list ? "d-block" : ""
                }" md={12}`}
              >
                <Controller
                  name="product_list"
                  control={control}
                  defaultValue={[]}
                  // rules={{ required: "Product Set is required" }}
                  render={({ field }) => (
                    <>
                      <Label for="product_list" className="form-label">
                        Change Product Set
                      </Label>
                      <Select
                        {...field}
                        isMulti
                        invalid={errors.product_list ? true : false}
                        options={
                          Array.isArray(productListData)
                            ? productListData
                                .filter((option) => option.subcategory_id !== 6) // Exclude subcategory_id 6
                                .map((option) => ({
                                  value: option.uuid,
                                  label: option.name,
                                  image: option.image,
                                  item_id: option.item_id,
                                  subcategory_id: option.subcategory_id,
                                }))
                            : []
                        }
                        onChange={(selectedOptions) => {
                          const uniqueSelectedOptions = selectedOptions.reduce(
                            (uniqueOptions, option) => {
                              // Remove previously selected products with the same subcategory_id
                              const existingIndex = uniqueOptions.findIndex(
                                (o) =>
                                  o.subcategory_id === option.subcategory_id
                              );

                              if (existingIndex !== -1) {
                                // If exists, replace it with the newly selected product
                                uniqueOptions[existingIndex] = option;
                              } else {
                                // If not exists, add the newly selected product
                                uniqueOptions.push(option);
                              }

                              return uniqueOptions;
                            },
                            []
                          );

                          // Update the form field with the new selected products
                          field.onChange(uniqueSelectedOptions);
                        }}
                        className={`${errors.product_list ? "is-invalid" : ""}`}
                      />
                    </>
                  )}
                />
                {/* {errors?.product_list && (
                <span className="text-danger">
                 {errors?.product_list?.message}
                </span>
              )} */}
              </Col>
            )}

          {(props?.editData?.sets_status === "false") |
          (props?.editData?.category_id !== 1) ? (
            <Col md={12} className="mb-3">
              <Controller
                name="image"
                // rules={{ required: "Image is required" }}
                control={control}
                render={({ field }) => (
                  <>
                    <Label for="image" className="form-label">
                      Select Image
                    </Label>
                    <Input
                      {...field}
                      type="file"
                      accept="image/*"
                      // value={field.value}
                      // invalid={errors.image ? true : false}
                      className="form-control"
                      onChange={(e) => {
                        // field.onChange(e);
                        handleImageChange(e);
                      }}
                    />
                    {/* <FormFeedback className="" type="invalid">
                    {errors.image && errors.image.message}
                  </FormFeedback> */}
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="mt-3"
                        style={{ maxWidth: "100%", maxHeight: "60px" }}
                      />
                    ) : (
                      <img
                        src={editedData?.product_image}
                        alt="Preview"
                        className="mt-3"
                        style={{ maxWidth: "100%", maxHeight: "60px" }}
                      />
                    )}
                  </>
                )}
              />
            </Col>
          ) : (
            ""
          )}

          {setImageData?.length === 0 && (
            <img src={editedData?.set_image_url} alt="" />
          )}
          {setImageData?.length !== 0 &&
            props?.editData?.sets_status === "true" &&
            props?.editData?.category_id === 1 && (
              <CanvasComponent data={setImageData} onData={handleChildData} />
            )}

          <Col md={12}>
            <div className="text-start">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </Col>
        </Form>
      </OFFCanvas>
    </>
  );
};

export default EditProduct;

export const CanvasComponent = ({ data, onData }) => {
  const canvasRef = useRef(null);
  const [imageUrl, setImageUrl] = useState([]);

  const sendDataToParent = () => {
    // Invoke the callback in the parent with the data from the child
    onData(imageUrl);
  };

  useEffect(() => {
    if (imageUrl) {
      sendDataToParent();
    }
  }, [imageUrl]);

  useEffect(() => {
    if (data?.length !== 0) {
      drawImages();
    }
  }, [data]);

  const drawImages = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const loadImages = (index) => {
      if (index < data?.length) {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
          const sub_category = data[index].item_id.replace(/\d/g, "");
          let x, y, width, height;

          if (sub_category === "Earrings") {
            // Draw the first earring on the left
            x = 20;
            y = canvas.height - 50;
            width = 30;
            height = 30;
            ctx.drawImage(img, x, y, width, height);

            // Draw the second earring on the right
            x = canvas.width - 50;
            ctx.drawImage(img, x, y, width, height);
          } else if (sub_category === "Necklace") {
            x = canvas.width / 2 - 60;
            y = canvas.height / 2 - 40;
            width = 120;
            height = 90;
            ctx.drawImage(img, x, y, width, height);
          } else if (sub_category === "NoseRings") {
            x = 20;
            y = 20;
            width = 10;
            height = 10;
            ctx.drawImage(img, x, y, width, height);
          }

          loadImages(index + 1);
        };

        img.src = data[index].image;
      } else {
        // All images are loaded
        const dataURL = canvas.toDataURL("image/png");
        // const base64Data = dataURL.split(",")[1];
        // const binaryData = atob(base64Data);
        setImageUrl(dataURL);
      }
    };

    loadImages(0);
  };

  return (
    <div>
      <canvas
        ref={canvasRef}
        id="myCanvas"
        width="300"
        height="180"
        className="position-relative"
        style={{ border: "1px solid black", background: "#D3D3D3" }}
      ></canvas>
      {/* <img src={imageUrl} alt="" /> */}
    </div>
  );
};

// useEffect(() => {
//   setValue("name", editedData?.name);
//   //   setValue('key',subCategory?.key )
//   categorySelectedOption =
//     category &&
//     category?.find((option) => option?.id === editedData?.category_id);

//   subcategorySelectedOption = subCategory?.find(
//     (option) => option?.id === editedData?.subcategory_id
//   );
//   vendorSelectedOption = vendor?.find(
//     (option) => option?.id === editedData?.vendor_id
//   );
//   productTypeSelectedOption = SingleOptions?.find(
//     (option) => option?.value === editedData?.product_type
//   );

//   setValue("category_id", categorySelectedOption);
//   setValue("subcategory_id", subcategorySelectedOption);
//   setValue("vendor_id", vendorSelectedOption);
//   setValue("product_type", vendorSelectedOption);
//   setValue("color_code", editedData?.color_code);
//   // setImagePreview(editedData?.product_image)
//   // setValue('image',editedData?.product_image);

// }, [setValue,editedData,category, subCategory, vendor, SingleOptions, imagePreview]);

// subcategory list

// useEffect(() => {
//   const payload = {
//     uuid: id,
//   };
//   if (id) {
//     dispatch(onEditProduct(payload, afterEditChange));
//   }
// }, [id]);

// const { products, editData, loading, error } = useSelector((state) => ({
//   editData: state.Product.editData,
//   error: state.Category.error,
// }));

// useEffect(() => {
//   setEditedData(editData);
// }, [editData]);
