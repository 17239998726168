import React, { useState, useEffect,useRef } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormFeedback,
} from "reactstrap";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import default_image from "../../../assets/images/default-image.jpg";

import {
  addProduct,
  deleteProduct,
  updateProduct,
  fetchProducts,
  productList,
  vendorFetchProducts,
} from "../../../store/admin/product/action";
import { categoryList } from "../../../store/admin/Category/action";
import { fetchSubcategories } from "../../../store/admin/SubCategory/action";
import { vendorList } from "../../../store/admin/Vendor/action";

const ProductsForm = () => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [childData, setChildData] = useState(null);

  const handleChildData = (data) => {
    // Handle the data received from the child component
    console.log('Data received from child:', data);
    setChildData(data);
  };
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [selectedMulti2, setselectedMulti2] = useState(null);
  const [productListData, setProductListData] = useState([]);

  const dispatch = useDispatch();
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [vendor, setVendor] = useState([]);

  const { categories } = useSelector((state) => ({
    categories: state?.Category?.categories,
  }));

  useEffect(() => {
    dispatch(categoryList());
  }, []);

  useEffect(() => {
    setCategory(categories);
  }, [categories]);

  const categoryId = watch("category_id");
  const setStatus = watch("sets_status");
  const setImageData = watch("product_list");
  const subCategoryId = watch("subcategory_id");

  const vendorId =  sessionStorage.getItem("vendor_id");


console.log(categoryId, 'cate')
console.log(subCategoryId, 'subcate')

  
  console.log(setImageData, 'setimage')

  const { subCategoryList } = useSelector((state) => ({
    subCategoryList: state?.Subcategory?.subCategoryList,
  }));


  useEffect(() => {
    const payload = {
      category_id: categoryId,
    };
    if(categoryId){
      dispatch(fetchSubcategories(payload));
    }
  },[categoryId]);

  useEffect(() => {
    setSubCategory(subCategoryList);
  }, [subCategoryList]);

  const { vendorData } = useSelector((state) => ({
    vendorData: state?.Vendor?.vendorData,
  }));

  useEffect(() => {
    dispatch(vendorList());
  }, []);

  useEffect(() => {
    setVendor(vendorData);
  }, [vendorData]);

  const { productAllList } = useSelector((state) => ({
    productAllList: state?.Product?.productAllList,
  }));

  useEffect(() => {
    const payload = {
      category_id:  categoryId,
      vendor_id : vendorId
    };
    if (categoryId) {
      dispatch(productList(payload));
    }
  }, [categoryId]);

  useEffect(() => {
    setProductListData(productAllList);
  }, [productAllList]);


  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Set the image preview
      setImage(file);
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  const options = [
    {
      value: "1",
      image:
        "https://devadmintoneita.shrewdbs.com//assets//application//category//1696491279651e670f0fa8b.jpg",
    },
    {
      value: "2",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpMzd_ufAXzS3xue90CH1CxNsHAxuptTnxx9DXB8ntkNT9ZhS6t5p0QBnMB1Lj-W9e1Ms&usqp=CAU",
    },
    // Add more options as needed
  ];

  // console.log(products, '...')

  // useEffect(() => {
  //   dispatch(fetchProducts());
  // }, []);

  // console.log(products, 'product')

  // const handleAddProduct = (product) => {
  //   dispatch(addProduct(product));
  // };

  // const handleUpdateProduct = (product) => {
  //   dispatch(updateProduct(product));
  // };

  // const handleDeleteProduct = (productId) => {
  //   dispatch(deleteProduct(productId));
  // };

  const afterAdd = () => {
    setImagePreview(null);
    reset({
      image: "",
    });
    const data = {
      page: 1,
      page_size: 10,
      vendor_id : vendorId,
      subcategory : []

    };
    dispatch(vendorFetchProducts(data));
  };
 console.log(childData, 'chiold')
  const onSubmit = async (data) => {
    console.log(typeof(data?.sets_status, 'status'))
    console.log(typeof(data?.category_id, 'id'))

    console.log(data, "product form data");

        const formData = new FormData();

    if(data?.category_id === "1"){
      formData.append("sets_status", data?.sets_status == "true" ? data?.sets_status : "false" ) 
      if(data?.subcategory_id === "4"){
        formData.append("flip_position", data?.flip_position);
      }
      if(data?.sets_status === "true"){
        formData.append("product_set",JSON.stringify(data?.product_list?.map(item => item.value)));  
        formData.append("set_image_url", childData) 

      }
    
    }
    else if(data?.category_id === "2"){
      formData.append("color_code", data.color_code);
    }
    
    formData.append("category_id", data.category_id);
    formData.append("subcategory_id", data.subcategory_id);
    formData.append("vendor_id", vendorId);

    formData.append("name", data.name);
    if(data?.sets_status !== "true" ){
      formData.append("image", image);
     if(data?.category_id === "1"){
      // alert('done')
      formData.append("product_type", data?.product_type);
     } 

    }
    // if(data?.sets_status === "false"){

    // }

    // &&  data?.category_id !== "1"
      // console.log(formData, 'final submit')
    dispatch(addProduct(formData, afterAdd));
  };

  function handleMulti2(selectedMulti) {
    setselectedMulti2(selectedMulti);
  }

  const SingleOptions = [
    { value: "round", label: "Round" },
    { value: "square", label: "Square" },
  ];
  const setsOptions = [
    { value: "true", label: "true" },
    { value: "false", label: "false" },
  ];

  const flipOptions = [
    { value: "right", label: "Right" },
    { value: "left", label: "Left" },
  ];
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col
            md={12}
            className={`mb-3 ${errors.category_id ? "d-block" : ""}" md={12}`}
          >
            <Controller
              name="category_id"
              control={control}
              defaultValue="" // Set the default value if needed
              rules={{ required: "Category is required" }}
              render={({ field }) => (
                <>
                  <Label htmlFor="dropdownValue" className="form-label">
                    Select Category
                  </Label>

                  <select
                    {...field}
                    className={`form-control ${
                      errors.category_id ? "is-invalid" : ""
                    }`}
                    id="dropdownValue"
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {Array.isArray(category) &&
                      category?.length !== 0 &&
                      category?.map((option) => (
                        <option key={option?.id} value={option?.id}>
                          {option?.name}
                        </option>
                      ))}
                  </select>
                  {errors?.category_id && (
                    <FormFeedback className="d-block">
                      {errors?.category_id?.message}
                    </FormFeedback>
                  )}
                </>
              )}
            />
          </Col>
          <Col
            md={12}
            className={`mb-3 ${
              errors.subcategory_id ? "d-block" : ""
            }" md={12}`}
          >
            <Controller
              name="subcategory_id"
              control={control}
              defaultValue="" // Set the default value if needed
              rules={{ required: "SubCategory is required" }}
              render={({ field }) => (
                <>
                  <Label htmlFor="dropdownValue" className="form-label">
                    Select SubCategory
                  </Label>

                  <select
                    {...field}
                    className={`form-control ${
                      errors.category_id ? "is-invalid" : ""
                    }`}
                    id="dropdownValue"
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {Array.isArray(subCategory) &&
                      subCategory?.length !== 0 &&
                      subCategory?.map((option) => (
                        <option key={option?.id} value={option?.id}>
                          {option?.name}
                        </option>
                      ))}
                  </select>
                  {errors?.subcategory_id && (
                    <FormFeedback className="d-block">
                      {errors?.subcategory_id?.message}
                    </FormFeedback>
                  )}
                </>
              )}
            />
          </Col>

          { categoryId === "1" && subCategoryId ===  "4"  ? 
          <Col
            md={12}
            className={`mb-3 ${errors.flip_position ? "d-block" : ""}" md={12}`}
          >
            <Controller
              name="flip_position"
              control={control}
              defaultValue="" // Set the default value if needed
              // rules={{ required: "Product Type is required" }}
              render={({ field }) => (
                <>
                  <Label htmlFor="dropdownValue" className="form-label">
                    Select Position
                  </Label>
                  <select
                    {...field}
                    className={`form-control ${
                      errors.flip_position ? "is-invalid" : ""
                    }`}
                    id="dropdownValue"
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {Array.isArray(flipOptions) &&
                      flipOptions?.length !== 0 &&
                      flipOptions?.map((option, index) => (
                        <option key={option?.index} value={option?.value}>
                          {option?.label}
                        </option>
                      ))}
                  </select>
                  {errors?.flip_position && (
                    <FormFeedback className="d-block">
                      {errors?.flip_position?.message}
                    </FormFeedback>
                  )}
                </>
              )}
            />
          </Col> : ""  }

          {setStatus !== "true" && categoryId === "1" ? 
          <Col
            md={12}
            className={`mb-3 ${errors.product_type ? "d-block" : ""}" md={12}`}
          >
            <Controller
              name="product_type"
              control={control}
              defaultValue="" // Set the default value if needed
              // rules={{ required: "Product Type is required" }}
              render={({ field }) => (
                <>
                  <Label htmlFor="dropdownValue" className="form-label">
                    Select Product Type
                  </Label>
                  <select
                    {...field}
                    className={`form-control ${
                      errors.product_type ? "is-invalid" : ""
                    }`}
                    id="dropdownValue"
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {Array.isArray(SingleOptions) &&
                      SingleOptions?.length !== 0 &&
                      SingleOptions?.map((option, index) => (
                        <option key={option?.index} value={option?.value}>
                          {option?.label}
                        </option>
                      ))}
                  </select>
                  {errors?.product_type && (
                    <FormFeedback className="d-block">
                      {errors?.product_type?.message}
                    </FormFeedback>
                  )}
                </>
              )}
            />
          </Col> : ""  }
{/*  
          <Col
            md={12}
            className={`mb-3 ${errors.category_id ? "d-block" : ""}" md={12}`}
          >
            <Controller
              name="vendor_id"
              control={control}
              defaultValue=""
              rules={{ required: "Vendor is required" }}
              render={({ field }) => (
                <>
                  <Label htmlFor="dropdownValue" className="form-label">
                    Select Vendor
                  </Label>

                  <select
                    {...field}
                    className={`form-control ${
                      errors.vendor_id ? "is-invalid" : ""
                    }`}
                    id="dropdownValue"
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {Array.isArray(vendor) &&
                      vendor?.length !== 0 &&
                      vendor?.map((option) => (
                        <option key={option?.id} value={option?.id}>
                          {option?.name}
                        </option>
                      ))}
                  </select>
                  {errors?.vendor_id && (
                    <FormFeedback className="d-block">
                      {errors?.vendor_id?.message}
                    </FormFeedback>
                  )}
                </>
              )}
            /> */}

            {categoryId === "2" && (
              <Col
                md={12}
                className={`mb-2 ${
                  errors.color_code ? "d-block" : ""
                }" md={12}`}
              >
                <Controller
                  name="color_code"
                  control={control}
                  rules={{ required: "Color code is required" }}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <Label htmlFor="color_code" className="form-label">
                        Choose Color
                      </Label>
                      <Input
                        type="color"
                        {...field}
                        className={`form-control ${
                          errors.color_code ? "is-invalid" : ""
                        }`}
                        // invalid={errors.color_code ? true : false}
                        // className="form-control"
                        placeholder="Choose color"
                      />
                      {errors?.color_code && (
                        <FormFeedback className="d-block">
                          {errors?.color_code?.message}
                        </FormFeedback>
                      )}
                    </>
                  )}
                />
              </Col>
            )}
            {/* <div className="invalid-feedback" id="color_code_error"></div> */}
          {/* </Col> */}
         
          <Col md={12}>
            <div className="mb-3">
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: "Product Name is required" }}
                render={({ field }) => (
                  <>
                    <Label for="firstNameinput" className="form-label">
                      Name
                    </Label>
                    <Input
                      {...field}
                      type="text"
                      invalid={errors.name ? true : false}
                      className="form-control"
                      placeholder="Enter your name"
                      id="firstNameinput"
                    />
                    <FormFeedback type="invalid">
                      {errors.name && errors.name.message}
                    </FormFeedback>
                  </>
                )}
              />
            </div>
          </Col>

          {/* <div className="">
            <Controller
        name="selectedOptions"
        control={control}
        defaultValue=""
        rules={{ required: "This field is required" }}
        render={({ field }) => (
          <>
          <Label for="product" className="form-label">
                      Select Products
           </Label>
          <Select
            {...field}
            isMulti
            isClearable
            className="mb-3"
            id="product"
            invalid={errors.selectedOptions ? true : false}
            onChange={(selectedOptions) => field.onChange(selectedOptions)}
            options={SingleOptions}
          />
          </>
        )}
      />
         <FormFeedback type="invalid">
                      {errors.selectedOptions && errors.selectedOptions.message}
                    </FormFeedback>
          </div>  */}
            {categoryId === "1"  && subCategoryId === "6" && (
            <Col
            md={12}
            className={`mb-3 ${errors.sets_status ? "d-block" : ""}" md={12}`}
          >
            <Controller
              name="sets_status"
              control={control}
              defaultValue="" // Set the default value if needed
              rules={{ required: "Sets status is required" }}
              render={({ field }) => (
                <>
                  <Label htmlFor="dropdownValue" className="form-label">
                    Add Jwellery Set
                  </Label>
                  <select
                    {...field}
                    className={`form-control ${
                      errors.sets_status ? "is-invalid" : ""
                    }`}
                    id="dropdownValue"
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    {Array.isArray(setsOptions) &&
                      setsOptions?.length !== 0 &&
                      setsOptions?.map((option, index) => (
                        <option key={option?.index} value={option?.value}>
                          {option?.label}
                        </option>
                      ))}
                  </select>
                  {errors?.sets_status && (
                    <FormFeedback className="d-block">
                      {errors?.sets_status?.message}
                    </FormFeedback>
                  )}
                </>
              )}
            />
          </Col>
            )}
          { setStatus === "true" && categoryId === "1"  && (
            <Col
              md={12}
              className={` mb-3 ${
                errors.product_list ? "d-block" : ""
              }" md={12}`}
            >
              <Controller
                name="product_list"
                control={control}
                defaultValue={[]}
                rules={{ required: "Product Set is required" }}
                render={({ field }) => (
                  <>
                    <Label for="product_list" className="form-label">
                      Select Product Set
                    </Label>
                    <Select
                      {...field}
                      isMulti
                      invalid={errors.product_list ? true : false}
                      options={
                        Array.isArray(productListData)
                          ? productListData
                              .filter((option) => option.subcategory_id !== 6) // Exclude subcategory_id 6
                              .map((option) => ({
                                value: option.uuid,
                                label: option.name,
                                image: option.image,
                                item_id: option.item_id,
                                subcategory_id: option.subcategory_id,
                              }))
                          : []
                      }
                      onChange={(selectedOptions) => {
                        const uniqueSelectedOptions = selectedOptions.reduce(
                          (uniqueOptions, option) => {
                            // Remove previously selected products with the same subcategory_id
                            const existingIndex = uniqueOptions.findIndex(
                              (o) => o.subcategory_id === option.subcategory_id
                            );
        
                            if (existingIndex !== -1) {
                              // If exists, replace it with the newly selected product
                              uniqueOptions[existingIndex] = option;
                            } else {
                              // If not exists, add the newly selected product
                              uniqueOptions.push(option);
                            }
        
                            return uniqueOptions;
                          },
                          []
                        );
        
                        // Update the form field with the new selected products
                        field.onChange(uniqueSelectedOptions);
                      }}
                      className={`${errors.product_list ? "is-invalid" : ""}`}
                    />
                  </>
                )}
              />
              {errors?.product_list && (
                <span className="text-danger">
                  {errors?.product_list?.message}
                </span>
              )}
            </Col>
          )}
        
        {setStatus !==  "true" | categoryId !== "1"&& subCategoryId !== "6" ? 
          <Col md={12} className="mb-3">
            <Controller
              name="image"
              rules={{ required: "Image is required" }}
              control={control}
              render={({ field }) => (
                <>
                  <Label for="image" className="form-label">
                    Select Image
                  </Label>
                  <Input  
                    {...field}
                    type="file"
                    accept="image/*"
                    value={field.value}
                    invalid={errors.image ? true : false}
                    className="form-control"
                    onChange={(e) => {
                      field.onChange(e);
                      handleImageChange(e);
                    }}
                  />
                  <p className="pt-2">Image size - Max 2 mb</p>
                  <FormFeedback className="" type="invalid">
                    {errors.image && errors.image.message}
                  </FormFeedback>
                  {imagePreview ? (
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="mt-3"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  ) : (
                    <img
                      src={default_image}
                      alt="Preview"
                      className="mt-3"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </>
              )}
            />
          </Col> :""
         }
        
         {setImageData?.length !== 0 && setStatus === "true" && categoryId === "1" &&
         <CanvasComponent data={setImageData} onData={handleChildData} />}
             
          <Col md={12}>
            <div className="text-start">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ProductsForm;

{
  /* <Col lg={12} md={12}>
<div className="mb-3">
  <Select
    value={selectedMulti2}
    isMulti={true}
    isClearable={true}
    onChange={() => {
      handleMulti2();
    }}
    options={SingleOptions}
  />
</div>
</Col> */
}

export const CanvasComponent = ({ data, onData }) => {
  const canvasRef = useRef(null);
  const [imageUrl, setImageUrl] = useState([])

  const sendDataToParent = () => {
    // Invoke the callback in the parent with the data from the child
    onData(imageUrl);
  };


  useEffect(()=>{
    if(imageUrl){
      sendDataToParent()
    }
  }, [imageUrl])

  useEffect(()=>{
    if(data?.length !== 0){
     drawImages()
    }
  },[data])

 


  // const drawImages = () => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext("2d");
  //   ctx.clearRect(0, 0, canvas.width, canvas.height);

  //   data?.forEach((item, index) => {
  //     const img = new Image();
  //     img.crossOrigin = 'anonymous';
  //     img.src = item.image;
  //     img.onload = () => {
  //       let x; // Adjust the initial x position as needed
  //       let y; // Adjust the y position as needed
  //       let width; // Adjust the width as needed
  //       let height;// Adjust the height as needed
  //       let className = ''; // Initialize className
  //       console.log(item, 'from canvas')
  //       function removeNumbersFromString(inputString) {
  //         return inputString.replace(/\d/g, ''); // \d is a regular expression pattern matching digits
  //       }
  //       const sub_category = removeNumbersFromString(item?.item_id)
  //       if(sub_category === "Earrings" ){
  //         // if (index === 0) {
  //         //   // First image (bottom left corner)
  //         //   x = 20;
  //         //   y = canvas.height - 50;
  //         //   className = 'position-absolute bottom-10 left-10';
  //         // } else if (index === 1) {
  //         //   // Second image (bottom right corner)
  //         //   x = canvas.width - 50;
  //         //   y = canvas.height - 50;
  //         //   className = 'position-absolute bottom-10 right-10';
  //         // }
  //         x= 20
  //         y = canvas.height - 50;
  //         width = 30;
  //         height = 30;
  //         ctx.drawImage(img, canvas.width - 50, canvas.height - 50, 30, 30);
  //         ctx.drawImage(img, x, y, width, height);

  //       }
  //       else if(sub_category === "Necklace"){
  //         x = canvas.width / 2 - 60;
  //         y = canvas.height / 2 - 40;
  //         width = 120;
  //         height = 90;
  //         className = '';
  //         ctx.drawImage(img, x, y, width, height);
  //       }       
  //       else if(sub_category === "NoseRings"){
  //         width = 10;
  //          height = 10;
  //          x=20;
  //          y= 20;
  //          className = 'earrings';
  //       }
      
        
  //     };
  //   });
  //   const dataURL = canvas.toDataURL('image/png');
  //   setImageUrl(dataURL);
  //   console.log(dataURL, 'binary');
  // };
  const drawImages = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  
    const loadImages = (index) => {
      if (index < data?.length) {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = () => {
          const sub_category = data[index].item_id.replace(/\d/g, '');
          let x, y, width, height;
  
          if (sub_category === 'Earrings') {
            // Draw the first earring on the left
            x = 20;
            y = canvas.height - 50;
            width = 30;
            height = 30;
            ctx.drawImage(img, x, y, width, height);
  
            // Draw the second earring on the right
            x = canvas.width - 50;
            ctx.drawImage(img, x, y, width, height);
          } else if (sub_category === 'Necklace') {
            x = canvas.width / 2 - 60;
            y = canvas.height / 2 - 40;
            width = 120;
            height = 90;
            ctx.drawImage(img, x, y, width, height);
          } else if (sub_category === 'NoseRings') {
            x = canvas.width / 2 - 10;
            y = canvas.height / 2 - 40;
            width = 20;
            height = 20;
            ctx.drawImage(img, x, y, width, height);
          }
  
          loadImages(index + 1);
        };
  
        img.src = data[index].image;
      } else {
        // All images are loaded
        const dataURL = canvas.toDataURL('image/png');
        // const base64Data = dataURL.split(",")[1];
        // const binaryData = atob(base64Data);
        setImageUrl(dataURL);
      }
    };
  
    loadImages(0);
  };
  

  return (
    <div>
      <canvas
        ref={canvasRef}
        id="myCanvas"
        width="300"
        height="180"
        className="position-relative"
        style={{ border: "1px solid black", background: "#D3D3D3" }}
      ></canvas>
       {/* <img src={imageUrl} alt="" /> */}
      {/* <p>
        <button onClick={drawImages}>Generate it</button>
      </p> */}
    </div>
  );
};

// export default CanvasComponent;

