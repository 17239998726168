// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";

// export const ADD_NEW_BUSINESS_INQUIRY = "ADD_NEW_BUSINESS_INQUIRY";
// export const ADD_BUSINESS_INQUIRY_SUCCESS = "ADD_BUSINESS_INQUIRY_SUCCESS";
// export const ADD_BUSINESS_INQUIRY_FAIL = "ADD_BUSINESS_INQUIRY_FAIL";

export const GET_BUSINESS_INQUIRY = "GET_BUSINESS_INQUIRY";

// * Edit BUSINESS_INQUIRY

// export const UPDATE_BUSINESS_INQUIRY = "UPDATE_BUSINESS_INQUIRY";
// export const UPDATE_BUSINESS_INQUIRY_SUCCESS = "UPDATE_BUSINESS_INQUIRY_SUCCESS";
// export const UPDATE_BUSINESS_INQUIRY_FAIL = "UPDATE_BUSINESS_INQUIRY_FAIL";

/**
* Delete BUSINESS_INQUIRY
*/
export const DELETE_BUSINESS_INQUIRY = "DELETE_BUSINESS_INQUIRY";
export const DELETE_BUSINESS_INQUIRY_SUCCESS = "DELETE_BUSINESS_INQUIRY_SUCCESS";
export const DELETE_BUSINESS_INQUIRY_FAIL = "DELETE_BUSINESS_INQUIRY_FAIL";