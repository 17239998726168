import React, { useState, useEffect } from 'react';
import { Col, Input, Label, Row, Form, FormFeedback } from 'reactstrap';
import BlankLayout from '../../../Components/Common/BlankLayout';
import CardSection from '../../../Components/Common/CardSection';
import OFFCanvas from '../../../Components/Common/OffCanvas/OFFCanvas';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  getVendors as onGetVendors,  // Updated action
  updateVendor as onUpdateVendor,  // Updated action
} from "../../../store/admin/Vendor/action";  // Updated import path

const EditVendor = (props) => {  // Updated component name


  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (props?.editData) {
      Object.keys(props?.editData).forEach((fieldName) => {
        setValue(fieldName, props?.editData[fieldName]);
      });
    }
  }, [props?.editData, setValue]);

  const dispatch = useDispatch();

  const afterChange = () =>{
    const pagination = {
      "page": 1,
      "page_size": 10
  }
    dispatch(onGetVendors(pagination)); 
    props.Handler();
  

  }

  const onSubmit = async (data) => {
    const filteredData = {
      vendor_id: data.vendor_id,
      first_name: data.first_name,
      last_name: data.last_name,
      // company_name: data.company_name,
      email: data.email,
      phone_number: data.phone_number,
      address: data.address,
      // password: data.password,
      site_url: "",
      // data.site_url
      // Add other fields as needed
    };

    const pagination = {
      "page":1,
      "page_size":10
  }
    await dispatch(onUpdateVendor(filteredData, afterChange));  // Updated action
    // dispatch(onGetVendors(pagination));   
    // props.Handler();
  };

  return (
    <>
      <OFFCanvas canvasTitle={'Edit Value'} direction={'end'} flag={props.flag} handler={() => props.Handler()}>
        <Row>
        <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Controller
                          name="first_name"
                          control={control}
                          defaultValue=""
                          rules={{ required: "First name is required" }}
                          render={({ field }) => (
                            <>
                              <Label
                                for="firstNameinput"
                                className="form-label"
                              >
                                First Name
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.first_name ? true : false}
                                className="form-control"
                                placeholder="Enter your firstname"
                                id="firstNameinput"
                              />
                              <FormFeedback type="invalid">
                                {errors.first_name && errors.first_name.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Controller
                          name="last_name"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Last name is required" }}
                          render={({ field }) => (
                            <>
                              <Label for="lastNameinput" className="form-label">
                                Last Name
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.last_name ? true : false}
                                className="form-control"
                                placeholder="Enter your Last name"
                                id="lastNameinput"
                              />
                              <FormFeedback type="invalid">
                                {errors.last_name && errors.last_name.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    {/* <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="company_name"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Company name is required" }}
                          render={({ field }) => (
                            <>
                              <Label
                                for="companyNameinput"
                                className="form-label"
                              >
                                Company Name
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.company_name ? true : false}
                                className="form-control"
                                placeholder="Enter your Company name"
                                id="companyNameinput"
                              />
                              <FormFeedback type="invalid">
                                {errors.company_name &&
                                  errors.company_name.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col> */}
{/* 
                    <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{ required: "email is required" }}
                          render={({ field }) => (
                            <>
                              <Label for="email" className="form-label">
                                email
                              </Label>
                              <Input
                                {...field}
                                type="email"
                                invalid={errors.email ? true : false}
                                className="form-control"
                                placeholder="Enter your email"
                                id="email"
                              />
                              <FormFeedback type="invalid">
                                {errors.email && errors.email.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col> */}
                    <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="phone_number"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Phone Number is required" }}
                          render={({ field }) => (
                            <>
                              <Label for="phone_number" className="form-label">
                              Phone Number
                              </Label>
                              <Input
                                {...field}
                                type="number"
                                invalid={errors.email ? true : false}
                                className="form-control"
                                placeholder="Enter your Phone Number"
                                id="phone_number"
                              />
                              <FormFeedback type="invalid">
                                {errors.phone_number && errors.phone_number.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <div className="mb-3">
                        <Controller
                          name="address"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Address is required" }}
                          render={({ field }) => (
                            <>
                              <Label
                                for="address"
                                className="form-label"
                              >
                                Address
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.address ? true : false}
                                className="form-control"
                                placeholder="Enter your Address"
                                id="address"
                              />
                              <FormFeedback type="invalid">
                                {errors.address &&
                                  errors.address.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    {/* <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="password"
                          control={control}
                          defaultValue=""
                          rules={{ required: "password is required" }}
                          render={({ field }) => (
                            <>
                              <Label for="password" className="form-label">
                                password
                              </Label>
                              <Input
                                {...field}
                                type="password"
                                invalid={errors.password ? true : false}
                                className="form-control"
                                placeholder="Enter your password"
                                id="password"
                              />
                              <FormFeedback type="invalid">
                                {errors.password && errors.password.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col> */}

                    {/* <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="site_url"
                          control={control}
                          defaultValue=""
                          rules={{ required: "domain is required" }}
                          render={({ field }) => (
                            <>
                              <Label for="domain" className="form-label">
                                Domain
                              </Label>
                              <Input
                                {...field}
                                type="domain"
                                invalid={errors.site_url ? true : false}
                                className="form-control"
                                placeholder="Enter your domain"
                                id="domain"
                              />
                              <FormFeedback type="invalid">
                                {errors.site_url && errors.site_url.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col> */}

                    <Col md={12}>
                      <div className="text-start">
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                        {/* <button onClick={() => { setflag(!flag) }} className='btn btn-warning'> offcanvas</button> */}
                      </div>
                    </Col>
                  </Row>
                </Form>
        </Row>
      </OFFCanvas>
    </>
  )
}

export default EditVendor;  // Updated component name
