
import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,

  
  ADD_NEW_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,

  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,

  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,

  GET_CUSTOMERS,

} from "./actionType"


export const customerApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const customerApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// Add
export const addNewCustomer = (customer, reset)  => ({
    type: ADD_NEW_CUSTOMER,
    payload: {customer, reset} ,
  });
  
  //1
  export const addCustomerSuccess = customer => ({
    type: ADD_CUSTOMER_SUCCESS,
    payload: customer,
  });
  // 1
  export const addCustomerFail = customer => ({
    type: ADD_CUSTOMER_FAIL,
    payload: customer,
  });

//getAll
  export const getCustomers = () => ({
    type: GET_CUSTOMERS,
  });

//update
  export const updateCustomer = customer => ({
    type: UPDATE_CUSTOMER,
    payload: customer,
  });
  
  export const updateCustomerSuccess = customer => ({
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: customer,
  });
  
  export const updateCustomerFail = error => ({
    type: UPDATE_CUSTOMER_FAIL,
    payload: error,
  });

//delete
  export const deleteCustomer = customer => ({
    type: DELETE_CUSTOMER,
    payload: customer,
  });
  
  export const deleteCustomerSuccess = customer =>
  ({
    type: DELETE_CUSTOMER_SUCCESS,
    payload: customer,
  });
  
  export const deleteCustomerFail = error => ({
    type: DELETE_CUSTOMER_FAIL,
    payload: error,
  });
  