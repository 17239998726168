import React, { useState } from "react";
import { Col, Input, Label, Row, Card, CardHeader, CardBody } from "reactstrap";
import BlankLayout from "../../../Components/Common/BlankLayout";
import CardSection from "../../../Components/Common/CardSection";
import ProductsForm from "../Product/ProductsForm";
// import ProductTable from "./ProductsTable";
// import ProductsForm from "./ProductsForm";

// import FilterModal from "./FilterModal";

const CosmeticAi = () => {
  const [filter, setFilter] = useState(false)
  const [filterIds, setFilterIds]  = useState([])


  return (

    <React.Fragment>
      <BlankLayout
        metaTitle={"Cosmetics AI Section"}
        pageTitle={"Cosmetics AI"}
        breadcrumTitle={"Cosmetics AI"}
        breadcrumPagetitle={"Cosmetics AI"}
      >
        <Row>
          <Col xl={12}>
            <CardSection panelTitle="Cosmetics AI">
              {/* <ProductsForm /> */}
              <ProductsForm />
            </CardSection>
          </Col>
          {/* <Col xl={8}>
            <CardSection panelTitle="Products Table" button="Filter" clickHandler={()=>{setFilter(!filter)}}>
              <ProductTable filterIds={filterIds} />
            </CardSection>
          </Col> */}
        </Row>
       
      </BlankLayout>
      {/* <FilterModal setId={setFilterIds} filter={filter} Handler={()=>{setFilter(!filter)}}  /> */}

    </React.Fragment>

  );
};

export default CosmeticAi;
