import React, { useState } from "react";
import { Col, Input, Label, Row, Form, FormFeedback } from "reactstrap";
import BlankLayout from "../../../Components/Common/BlankLayout";
import CardSection from "../../../Components/Common/CardSection";
import OFFCanvas from "../../../Components/Common/OffCanvas/OFFCanvas";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useForm, Controller } from "react-hook-form";

import { useSelector, useDispatch } from "react-redux";

import {
  getOrders as onGetOrders,
  addNewVendor as onAddNewVendor,  // Updated action
  addVendorSuccess,  // Updated action
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "../../../store/admin/Vendor/action";  // Updated import path

const AddVendor = () => {  // Updated component name
  const [loader, setLoader] = useState(false);
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [flag, setflag] = useState(false);
  const dispatch = useDispatch();

  

  const { vendors, error, loading } = useSelector((state) => ({  // Updated selector name
    vendors: state?.Vendor?.vendors,  // Updated state name
    loading: state?.Vendor?.loading,  // Updated state name
    error: state?.Vendor?.error,  // Updated state name
  }));

  const onSubmit = async (data) => {
    try {
      // Dispatch the action and wait for it to complete
       const response =  await dispatch(onAddNewVendor(data, reset));
         
      // Reset the form if the dispatch is successful
      // reset();
    } catch (error) {
      // Handle errors if necessary
      console.error('Error:', error);
    }
  };

  return (
    <>
      <React.Fragment>
        <BlankLayout
          metaTitle={"Customer Section"}
          pageTitle={"Customer"}  // Updated title
          breadcrumTitle={"Customer"}  // Updated breadcrumb title
          breadcrumPagetitle={"Customer"}  // Updated breadcrumb page title
        >
          <Row>
            <Col xl={12}>
              <CardSection panelTitle="Enter Details">
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Controller
                          name="first_name"
                          control={control}
                          defaultValue=""
                          rules={{ required: "First name is required" }}
                          render={({ field }) => (
                            <>
                              <Label
                                for="firstNameinput"
                                className="form-label"
                              >
                                First Name
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.first_name ? true : false}
                                className="form-control"
                                placeholder="Enter your firstname"
                                id="firstNameinput"
                              />
                              <FormFeedback type="invalid">
                                {errors.first_name && errors.first_name.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Controller
                          name="last_name"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Last name is required" }}
                          render={({ field }) => (
                            <>
                              <Label for="lastNameinput" className="form-label">
                                Last Name
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.last_name ? true : false}
                                className="form-control"
                                placeholder="Enter your Last name"
                                id="lastNameinput"
                              />
                              <FormFeedback type="invalid">
                                {errors.last_name && errors.last_name.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="username"
                          control={control}
                          defaultValue=""
                          rules={{ required: "User name is required" }}
                          render={({ field }) => (
                            <>
                              <Label
                                for="userNameinput"
                                className="form-label"
                              >
                                User Name
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.username ? true : false}
                                className="form-control"
                                placeholder="Enter User name"
                                id="userNameinput"
                              />
                              <FormFeedback type="invalid">
                                {errors.username &&
                                  errors.username.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    {/* <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="company_name"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Company name is required" }}
                          render={({ field }) => (
                            <>
                              <Label
                                for="companyNameinput"
                                className="form-label"
                              >
                                Company Name
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.company_name ? true : false}
                                className="form-control"
                                placeholder="Enter your Company name"
                                id="companyNameinput"
                              />
                              <FormFeedback type="invalid">
                                {errors.company_name &&
                                  errors.company_name.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col> */}
                       <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="address"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Address is required" }}
                          render={({ field }) => (
                            <>
                              <Label
                                for="address"
                                className="form-label"
                              >
                                Address
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.address ? true : false}
                                className="form-control"
                                placeholder="Enter your Address"
                                id="address"
                              />
                              <FormFeedback type="invalid">
                                {errors.address &&
                                  errors.address.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>

                    <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{ required: "email is required" }}
                          render={({ field }) => (
                            <>
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Input
                                {...field}
                                type="email"
                                invalid={errors.email ? true : false}
                                className="form-control"
                                placeholder="Enter your email"
                                id="email"
                              />
                              <FormFeedback type="invalid">
                                {errors.email && errors.email.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="password"
                          control={control}
                          defaultValue=""
                          rules={{ required: "password is required" }}
                          render={({ field }) => (
                            <>
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <Input
                                {...field}
                                type="password"
                                invalid={errors.password ? true : false}
                                className="form-control"
                                placeholder="Enter your password"
                                id="password"
                              />
                              <FormFeedback type="invalid">
                                {errors.password && errors.password.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="phone_number"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Phone Number is required" }}
                          render={({ field }) => (
                            <>
                              <Label for="phone_number" className="form-label">
                              Phone Number
                              </Label>
                              <Input
                                {...field}
                                type="number"
                                invalid={errors.email ? true : false}
                                className="form-control"
                                placeholder="Enter your Phone Number"
                                id="phone_number"
                              />
                              <FormFeedback type="invalid">
                                {errors.phone_number && errors.phone_number.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    {/* <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="domain"
                          control={control}
                          defaultValue=""
                          rules={{ required: "domain is required" }}
                          render={({ field }) => (
                            <>
                              <Label for="domain" className="form-label">
                                Domain
                              </Label>
                              <Input
                                {...field}
                                type="domain"
                                invalid={errors.domain ? true : false}
                                className="form-control"
                                placeholder="Enter your domain"
                                id="domain"
                              />
                              <FormFeedback type="invalid">
                                {errors.domain && errors.domain.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col> */}

                    <Col md={12}>
                      <div className="text-start">
                        {loading ?
                        
                   
                      <button type="button" class="btn btn-primary " disabled>Loading...</button>
                       :
                        <button type="submit" className="btn btn-primary">
                         Submit
                        </button>
                           }
                        {/* <button onClick={() => { setflag(!flag) }} className='btn btn-warning'> offcanvas</button> */}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardSection>
            </Col>
          </Row>
        </BlankLayout>
      </React.Fragment>
    </>
  );
};

export default AddVendor;  // Updated component name
