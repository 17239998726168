import React, { useState } from "react";
import { Col, Input, Label, Row, Card, CardHeader, CardBody } from "reactstrap";
import BlankLayout from "../../../Components/Common/BlankLayout";
import CardSection from "../../../Components/Common/CardSection";
import CategoryTable from "./CategoryTable";
import CategoryForm from "./CategoryForm";

const Category = () => {
 
  return (
    <React.Fragment>
      <BlankLayout
        metaTitle={"Category Section"}
        pageTitle={"category"}
        breadcrumTitle={"category"}
        breadcrumPagetitle={"category"}
      >
        <Row>
          <Col xl={4}>
            <CardSection panelTitle="Category">
              <CategoryForm />
            </CardSection>
          </Col>
          <Col xl={8}>
            <CardSection panelTitle="Category Table">
              <CategoryTable />
            </CardSection>
          </Col>
        </Row>
       
      </BlankLayout>
    </React.Fragment>
  );
};

export default Category;
