// actions.js
import {
  FETCH_PRODUCTS,
  // FETCH_PRODUCTS_SUCCESS,
  // FETCH_PRODUCTS_FAILURE,
  API_RESPONSE_SUCCESS, 
  API_RESPONSE_ERROR,
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAIL,
  PRODUCT_LIST, 
  PRODUCT_STATUS_CHANGE, 
  PRODUCT_STATUS_CHANGE_SUCCESS, 
  PRODUCT_STATUS_CHANGE_FAIL,
  VENDOR_FETCH_PRODUCTS
} from "./actionType";

// export const fetchProducts = () => ({ type: FETCH_PRODUCTS });

export const productApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const productApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const fetchProducts = product => ({
  type: FETCH_PRODUCTS,
  payload: product,

});

// vendor
export const vendorFetchProducts = product => ({
  type: VENDOR_FETCH_PRODUCTS,
  payload: product,

});

export const productList = product => ({
  type: PRODUCT_LIST,
  payload: product,

});


// export const fetchProductsSuccess = (products) => ({
//   type: FETCH_PRODUCTS_SUCCESS,
//   payload: { products }, // consistent with the reducers
// });

// export const fetchProductsFailure = (error) => ({
//   type: FETCH_PRODUCTS_FAILURE,
//   payload: { error },
// });

export const addProduct = (product, afterAdd) => ({
  type: ADD_PRODUCT,
  payload: { product, afterAdd },
});

export const addProductSuccess = () => ({ type: ADD_PRODUCT_SUCCESS });

export const addProductFailure = (error) => ({
  type: ADD_PRODUCT_FAILURE,
  payload: { error },
});

export const updateProduct = (product, afterUpdate) => ({
  type: UPDATE_PRODUCT,
  payload: { product, afterUpdate },
});

export const updateProductSuccess = () => ({ type: UPDATE_PRODUCT_SUCCESS });

export const updateProductFailure = (error) => ({
  type: UPDATE_PRODUCT_FAILURE,
  payload: { error },
});

// productsActions.js
export const editProduct = (product, afterChange) => ({
  type: EDIT_PRODUCT,
  payload: { product, afterChange },
});

export const editProductSuccess = (product) => ({
  type: EDIT_PRODUCT_SUCCESS,
  payload: product,
});

export const editProductFail = (error) => ({
  type: EDIT_PRODUCT_FAIL,
  payload: error,
});


export const deleteProduct = (productId) => ({
  type: DELETE_PRODUCT,
  payload: { productId },
});

export const deleteProductSuccess = () => ({ type: DELETE_PRODUCT_SUCCESS });

export const deleteProductFailure = (error) => ({
  type: DELETE_PRODUCT_FAILURE,
  payload: { error },
});

export const productStatusChange = (product, afterChange)  => ({
  type: PRODUCT_STATUS_CHANGE,  // Updated action type
  payload: {product, afterChange},
});

export const productStatusChangeSuccess = product => ({
  type: PRODUCT_STATUS_CHANGE_SUCCESS,  // Updated action type
  payload: product,
});

export const productStatusChangeFail = error => ({
  type: PRODUCT_STATUS_CHANGE_FAIL,  // Updated action type
  payload: error,
});
