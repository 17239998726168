
import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

  
   
    GET_GETIN_TOUCH,

  


    DELETE_GETIN_TOUCH_SUCCESS,
    DELETE_GETIN_TOUCH_FAIL,

  } from "./actionType"

  const INIT_STATE = {
    getInTouch: [],
    
  };

  const GetInTouch = (state = INIT_STATE, action) => {
    switch (action.type) {
      case API_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {
           
          case GET_GETIN_TOUCH:
            return {
              ...state,
              getInTouch: action.payload.data,
              isGetInTouchCreated: false,
              isGetInTouchSuccess: true
            };
  
          default:
            return { ...state };
        }
      case API_RESPONSE_ERROR:
        switch (action.payload.actionType) {
      
          case GET_GETIN_TOUCH:
            return {
              ...state,
              error: action.payload.error,
              isGetInTouchCreated: false,
              isGetInTouchSuccess: false
            };
          default:
            return { ...state };
        }
      case GET_GETIN_TOUCH:
        return {
          ...state,
          isGetInTouchCreated: false
        };
  
      ///////////////////
      // case ADD_CUSTOMER_SUCCESS:
      //   return {
      //     ...state,
      //     isCustomerCreated: true,
      //     customers: [...state.customers, action.payload.data],
      //   };
     
      // case ADD_CUSTOMER_FAIL:
      //   return {
      //     ...state,
      //     error: action.payload,
      //   };

        // case UPDATE_CUSTOMER_SUCCESS:
        //   return {
        //     ...state,
        //     customers: state.customers.map(customer =>
        //       customer._id.toString() === action.payload.data._id.toString()
        //         ? { ...customer, ...action.payload.data }
        //         : customer
        //     ),
        //   };
    
        // case UPDATE_CUSTOMER_FAIL:
        //   return {
        //     ...state,
        //     error: action.payload,
        //   };
    
    
        case DELETE_GETIN_TOUCH_SUCCESS:
          return {
            ...state,
            getInTouch: state.getInTouch.filter(
              getInTouch => getInTouch._id.toString() !== action.payload.getInTouch.toString()
            ),
          };
    
        case DELETE_GETIN_TOUCH_FAIL:
          return {
            ...state,
            error: action.payload,
          };
  
      default:
        return { ...state };
    }
  };
  
  export default GetInTouch;