// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";

// export const ADD_NEW_GETIN_TOUCH = "ADD_NEW_GETIN_TOUCH";
// export const ADD_GETIN_TOUCH_SUCCESS = "ADD_GETIN_TOUCH_SUCCESS";
// export const ADD_GETIN_TOUCH_FAIL = "ADD_GETIN_TOUCH_FAIL";

export const GET_GETIN_TOUCH = "GET_GETIN_TOUCH";

// * Edit GETIN_TOUCH

// export const UPDATE_GETIN_TOUCH = "UPDATE_GETIN_TOUCH";
// export const UPDATE_GETIN_TOUCH_SUCCESS = "UPDATE_GETIN_TOUCH_SUCCESS";
// export const UPDATE_GETIN_TOUCH_FAIL = "UPDATE_GETIN_TOUCH_FAIL";

/**
* Delete GETIN_TOUCH
*/
export const DELETE_GETIN_TOUCH = "DELETE_GETIN_TOUCH";
export const DELETE_GETIN_TOUCH_SUCCESS = "DELETE_GETIN_TOUCH_SUCCESS";
export const DELETE_GETIN_TOUCH_FAIL = "DELETE_GETIN_TOUCH_FAIL";