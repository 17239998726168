import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'

export default function CardSection({panelTitle, panelFootersection,  children, button, clickHandler}) {
    return (
        <Card>
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">{panelTitle}</h4>
              {button && <button onClick={clickHandler} type="button" class="btn btn-light">{button}</button>} 
                {panelFootersection}
            </CardHeader>
            <CardBody>
                {children}
            </CardBody>
        </Card>
    )
}
