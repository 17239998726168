import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import DeleteModal from '../../../Components/Common/DeleteModal';
import { Col, Label, Row, Card, CardHeader, CardBody } from "reactstrap";
import OFFCanvas from '../../../Components/Common/OffCanvas/OFFCanvas';
import EditCategory from './EditProduct';
import DeleteCategoryModal from './DeleteProductModal';
import { useSelector, useDispatch } from "react-redux";
import {
  // getSubcategories as onGetSubcategories,  // Updated action
  editProduct as onEditProduct, productStatusChange, vendorFetchProducts, // Updated action
  // updateSubcategory as onUpdateSubcategory
} from "../../../store/admin/product/action";
import {
  fetchProducts ,
  getCategories as onGetCategories,
  addNewCategory as onAddNewCategory,
  updateCategory as onUpdateCategory,
  deleteCategory as onDeleteCategory,
} from "../../../store/admin/product/action";
import EditProduct from './EditProduct';
import { fetchSubcategories } from "../../../store/admin/SubCategory/action";
import FilterModal from './FilterModal';


const ProductTable = (props) => {
  const [productData, setProductData] = useState([]);
  const [deleteCategory, setDeleteCategory] = useState()
  const [sendEditData, setSendEditData] = useState()
  const [currentPage, setCurrentPage] = useState(productData.length === 0  ? 1 : productData?.current_page);
  const [itemsPerPage, setItemsPerPage] = useState(productData.length === 0  ? 10 : productData?.page_size);
  const [subcategory,setSubCategory] = useState([])
  const handleProductClick = (data) => {
    // setEditData(data);
    const payload = {
      uuid: data,
    };
    dispatch(onEditProduct(payload, afterEditChange));
  };


  // useEffect(() => {
  //   const payload = {
  //     uuid: id,
  //   };
  //   if (id) {
  //     dispatch(onEditProduct(payload, afterEditChange));
  //   }
  // }, [id]);

  const {editData } = useSelector((state) => ({
    editData: state?.Product?.editData,
    error: state.Category.error,
  }));

  useEffect(() => {
    setSendEditData(editData);
  }, [editData]);
  

  console.log(sendEditData, 'dat...')

// subcategory list
  const { subCategoryList } = useSelector((state) => ({
    subCategoryList: state?.Subcategory?.subCategoryList,
  }));

const afterEditChange = () => {
  //   const pagination = {
  //     "page": 1,
  //     "page_size": 10
  // }
  //   dispatch(onGetSubcategories(pagination));
  //   props.Handler();
  // const categoryId =  sendEditData?.category_id

  // const payload = {
  //   category_id: categoryId,
  // };
  // dispatch(fetchSubcategories(payload));

};
  // console.log(categoryId, 'cattt')
  // useEffect(() => {
  //   const payload = {
  //     category_id: categoryId,
  //   };
  //   if(categoryId){
  //     dispatch(fetchSubcategories(payload));
  //   }
  // },[categoryId]);


  useEffect(() => {
    setSubCategory(subCategoryList);
  }, [subCategoryList]);

  console.log(subcategory, 'subbbb')

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage, page) => {
    setItemsPerPage(newPerPage);
    setCurrentPage(page);
  };

  const { products, loading, error } = useSelector((state) => ({
    products: state?.Product?.products,
    error: state?.Category?.error,
  }));


  const dispatch = useDispatch();
  const vendorId =  sessionStorage.getItem("vendor_id");

  
   useEffect(() => {
    const data = {
      "page": currentPage,
      "page_size": itemsPerPage,
      "vendor_id": vendorId,
      "subcategory": props.filterIds
  }
       dispatch(vendorFetchProducts(data));
  }, [currentPage, itemsPerPage,  props.filterIds]);

  useEffect(() => {
    setProductData(products);
  }, [products]);

    // const [isEdit, setIsEdit] = useState(false);
    const [flag, setflag] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);
    // const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  
    const onClickDelete = (order) => {
    //   setOrder(order);
      setDeleteModal(true);
    };

    const statusChange  = async (prop) =>{
       
      const data = {
        vendor_id: prop?.vendor_id,
        uuid: prop?.uuid
       }

         dispatch(productStatusChange(data, afterChange));  
        // dispatch(onGetVendors(pagination));   
      }

      const afterChange = () =>{
        const data = {
          "page": currentPage,
          "page_size": itemsPerPage,
          "vendor_id": vendorId, 
          "subcategory": []
      }
           dispatch(vendorFetchProducts(data));
      }

    const columns = [
        // {
        //     name: <Input className="form-check-input fs-15" type="checkbox" name="checkAll" value="option1" />,
        //     cell: () => (
        //         <input className="form-check-input fs-15" type="checkbox" name="checkAll" value="option1" />
        //     ),
        // },
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            cell: (cellProps, index) => <div>{(currentPage - 1) * itemsPerPage + index + 1}</div>,
            sortable: true
        },
        // {
        //     name: <span className='font-weight-bold fs-13'>ID</span>,
        //     selector: row => row.id,
        //     sortable: true
        // },
        {
          name: <span className='font-weight-bold fs-13'>id</span>,
          selector: row => row.uuid,
          sortable: true
      },
       
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => row.name,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>Image</span>,
            selector: row => <img src={row.category_image} className='avatar-xs rounded-circle' alt="" /> ,
            sortable: true
        },
        // {
        //     name: <span className='font-weight-bold fs-13'>Assigned To</span>,
        //     selector: row => row.assigned,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Create By</span>,
        //     selector: row => row.createdBy,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Create Date</span>,
        //     selector: row => row.createDate,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Status</span>,
        //     sortable: true,
        //     selector: (cell) => {
        //         switch (cell.status) {
        //             case "Re-open":
        //                 return <span className="badge badge-soft-info"> {cell.status} </span>;
        //             case "On-Hold":
        //                 return <span className="badge badge-soft-secondary"> {cell.status} </span>;
        //             case "Closed":
        //                 return <span className="badge badge-soft-danger"> {cell.status} </span>;
        //             case "Inprogress":
        //                 return <span className="badge badge-soft-warning"> {cell.status} </span>;
        //             case "Open":
        //                 return <span className="badge badge-soft-primary"> {cell.status} </span>;
        //             case "New":
        //                 return <span className="badge badge-soft-success"> {cell.status} </span>;
        //             default:
        //                 return <span className="badge badge-soft-success"> {cell.status} </span>;
        //         }
        //     },
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Priority</span>,
        //     sortable: true,
        //     selector: (cell) => {
        //         switch (cell.priority) {
        //             case "High":
        //                 return <span className="badge bg-danger"> {cell.priority} </span>;
        //             case "Medium":
        //                 return <span className="badge bg-info"> {cell.priority} </span>;
        //             case "Low":
        //                 return <span className="badge bg-success"> {cell.priority} </span>;
        //             default:
        //                 return <span className="badge bg-danger"> {cell.priority} </span>;
        //         }
        //     },
        // },
        {
          name: <span className='font-weight-bold fs-13'>Status</span>,
          cell: (cellProps) => {
            return (
              <ul className="list-inline hstack gap-2 mb-0">
                <li className="list-inline-item">
                <div className="form-check form-switch form-switch-danger mb-3">
                  {cellProps.status === 1 ?
                 <Input onClick={()=>statusChange(cellProps)} className="form-check-input" type="checkbox" role="switch" id="SwitchCheck5" defaultChecked /> :
                 <Input onClick={()=>statusChange(cellProps)} className="form-check-input" type="checkbox" role="switch" id="SwitchCheck5"  />
    
                }
                 {/* <Label className="form-check-label" htmlFor="SwitchCheck5">Switch Danger</Label>  */}
                </div>
                  {/* <Link
                    to="#"
                    className="text-danger d-inline-block remove-item-btn"
                    onClick={() => {
                      const vendorData = cellProps?.id;
                      // onClickDelete(vendorData);
                    }}
                  >
                    <i className="ri-delete-bin-5-fill fs-16"></i>
                  </Link> */}
                </li>
              </ul>
            );
          },
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            // sortable: true,
            cell: (cellProps) => {
              return (
                <ul className="list-inline hstack gap-2 mb-0">
                  {/* <li className="list-inline-item">
                    <Link
                      to="/apps-ecommerce-order-details"
                      className="text-primary d-inline-block"
                    >
                      <i className="ri-eye-fill fs-16"></i>
                    </Link>
                  </li> */}
                  <li className="list-inline-item edit">
                    <Link
                      to="#"
                      className="text-primary d-inline-block edit-item-btn"
                      onClick={() => {
                        const vendorData = cellProps.uuid;
                  handleProductClick(vendorData);
                  setflag(!flag);
                      }}
                    //   onClick={() => {
                    //     const orderData = cellProps.row.original;
                    //     handleOrderClick(orderData);
                    //   }}
                    >
                      <i className="ri-pencil-fill fs-16"></i>
                    </Link>
                  </li>
                  {/* <li className="list-inline-item">
                    <Link
                      to="#"
                      className="text-danger d-inline-block remove-item-btn"
                      onClick={() => {
                        // const orderData = cellProps.row.original;
                        // onClickDelete(orderData);
                        onClickDelete();

                      }}
                    >
                      <i className="ri-delete-bin-5-fill fs-16"></i>
                    </Link>
                  </li> */}
                </ul>
              );
            },
          },
        // {
        //     name: <span className='font-weight-bold fs-13'>Action</span>,
        //     sortable: true,

        //     cell: () => {
        //         return (
        //             <UncontrolledDropdown className="dropdown d-inline-block">
        //                 <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
        //                     <i className="ri-more-fill align-middle"></i>
        //                 </DropdownToggle>
        //                 <DropdownMenu className="dropdown-menu-end">
        //                     <DropdownItem href="#"><i className="ri-eye-fill align-bottom me-2 text-muted"></i>View</DropdownItem>
        //                     <DropdownItem className='edit-item-btn'><i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit</DropdownItem>
        //                     <DropdownItem className='remove-item-btn'> <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
        //                 </DropdownMenu>
        //             </UncontrolledDropdown>
        //         );
        //     },
        // },
    ];

    const data = [
        {
            srNo: "01",
            id: "VLZ-452",
            purchaseId: "VLZ1400087402",
            title: "Post launch reminder/ post list",
            name: "Joseph Parker",
            image: avatar2,
            assigned: "Alexis Clarke",
            createdBy: "Joseph Parker",
            createDate: "03 Oct, 2021",
            status: "Re-open",
            priority: "High",
        },
        {
            srNo: "02",
            id: "VLZ-453",
            purchaseId: "VLZ1400087425",
            title: "Additional Calendar",
            name: "Diana Kohler",
            assigned: "Admin",
            image: avatar2,
            createdBy: "Mary Rucker",
            createDate: "05 Oct, 2021",
            status: "On-Hold",
            priority: "Medium",
        },
        {
            srNo: "03",
            id: "VLZ-454",
            purchaseId: "VLZ1400087438",
            title: "Make a creating an account profile",
            name: "Tonya Noble",
            image: avatar2,
            assigned: "Admin",
            createdBy: "Tonya Noble",
            createDate: "27 April, 2022",
            status: "Closed",
            priority: "Low",
        },
        {
            srNo: "04",
            id: "VLZ-455",
            purchaseId: "VLZ1400087748",
            title: "Apologize for shopping Error!",
            name: "Joseph Parker",
            image: avatar2,
            assigned: "Alexis Clarke",
            createdBy: "Joseph Parker",
            createDate: "14 June, 2021",
            status: "Inprogress",
            priority: "Medium",
        },
        {
            srNo: "05",
            id: "VLZ-456",
            purchaseId: "VLZ1400087547",
            title: "Support for theme",
            name: "Donald Palmer",
            image: avatar2,
            assigned: "Admin",

            createdBy: "Donald Palmer",
            createDate: "25 June, 2021",
            status: "Closed",
            priority: "Low",
        },
        {
            srNo: "06",
            id: "VLZ-457",
            purchaseId: "VLZ1400087245",
            title: "Benner design for FB & Twitter",
            name: "Mary Rucker",
            image: avatar2,
            assigned: "Jennifer Carter",
            createdBy: "Mary Rucker",
            createDate: "14 Aug, 2021",
            status: "Inprogress",
            priority: "Medium",
        },
        {
            srNo: "07",
            id: "VLZ-458",
            purchaseId: "VLZ1400087785",
            title: "Change email option process",
            name: "James Morris",
            image: avatar2,
            assigned: "Admin",
            createdBy: "James Morris",
            createDate: "12 March, 2022",
            status: "Open",
            priority: "High",
        },
        {
            srNo: "08",
            id: "VLZ-460",
            purchaseId: "VLZ1400087745",
            title: "Support for theme",
            name: "Nathan Cole",
            image: avatar2,
            assigned: "Nancy Martino",
            createdBy: "Nathan Cole",
            createDate: "28 Feb, 2022",
            status: "On-Hold",
            priority: "Low",
        },
        {
            srNo: "09",
            id: "VLZ-461",
            purchaseId: "VLZ1400087179",
            title: "Form submit issue",
            name: "Grace Coles",
            image: avatar2,
            assigned: "Admin",
            createdBy: "Grace Coles",
            createDate: "07 Jan, 2022",
            status: "New",
            priority: "High",
        },
        {
            srNo: "10",
            id: "VLZ-462",
            purchaseId: "VLZ140008856",
            title: "Edit customer testimonial",
            name: "Freda",
            image: avatar2,
            assigned: "Alexis Clarke",
            createdBy: "Freda",
            createDate: "16 Aug, 2021",
            status: "Closed",
            priority: "Medium",
        },
        {
            srNo: "11",
            id: "VLZ-462",
            purchaseId: "VLZ140008856",
            title: "Edit customer testimonial",
            name: "Freda",
            image: avatar2,
            assigned: "Alexis Clarke",
            createdBy: "Freda",
            createDate: "16 Aug, 2021",
            status: "Closed",
            priority: "Medium",
        },
    ];
  return (
    <>
      <DataTable
        columns={columns}
        data={productData?.list}
        // pagination={pagination}
        progressPending={loading}
      pagination
      paginationServer
      paginationTotalRows={productData?.total_page * itemsPerPage}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerPageChange}
      paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
            // highlightOnHover={true}
        />

 <DeleteCategoryModal
        show={deleteModal}
        // onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      
<EditProduct flag={flag} editData={sendEditData}   Handler={()=>{setflag(!flag)}} />

    </>
  )
}

export default ProductTable