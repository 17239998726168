import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import NotificationService from "../../../Service/NotificationService";

// Ecommerce Redux States
import {
  ADD_NEW_SUBCATEGORY,  // Updated action type
  GET_SUBCATEGORIES,  // Updated action type
  UPDATE_SUBCATEGORY,  // Updated action type
  DELETE_SUBCATEGORY,
  EDIT_SUBCATEGORY,
  FETCH_SUBCATEGORIES,
  
} from "./actionType";

import {
  subcategoryApiResponseSuccess,  // Updated action type
  subcategoryApiResponseError,  // Updated action type
  addSubcategoryFail,  // Updated action type
  addSubcategorySuccess,  // Updated action type
  updateSubcategorySuccess,  // Updated action type
  updateSubcategoryFail,  // Updated action type
  deleteSubcategorySuccess,  // Updated action type
  deleteSubcategoryFail,  // Updated action type
  getSubcategories as onGetSubcategories,  // Updated action type
  editSubcategoryFail,
  editSubcategorySuccess,
  // fetchSubcategoriesSuccess,
  // fetchSubcategoriesFailure
 
} from "./action";

import {
  addNewSubcategory,  // Updated action type
  getSubcategories as getSubcategoriesApi,  // Updated action type
  updateSubcategory,  // Updated action type
  deleteSubcategory,  // Updated action type
  editSubcategory,
  subcategoryListApi
} from "../../../helpers/fakebackend_helper";

toast.configure();

function* onAddNewSubcategory({ payload: { subcategory, afterAdd } }) {  // Updated action type
  try {
    const response = yield call(addNewSubcategory, subcategory);  // Updated action type
    if(response.status){
      afterAdd()
      NotificationService.success(response.message)
    }
    yield put(addSubcategorySuccess(response));  // Updated action type
  } catch (error) {
    yield put(addSubcategoryFail(error));  // Updated action type
    NotificationService.error(error)
  }
}

// Update
function* onUpdateSubcategory({ payload: { subcategory, afterChange } }) {  // Updated action type
  try {
    const response = yield call(updateSubcategory, subcategory);  // Updated action type

    console.log(response.status, 'from update saga')
    if(response.status === "true"){
      afterChange()
      NotificationService.success(response.message)
    }
    yield put(updateSubcategorySuccess(response));  // Updated action type

  } catch (error) {
    yield put(updateSubcategoryFail(error));  // Updated action type
    NotificationService.error(error)
  }
}

function* onEditSubcategory({ payload: { subcategory, afterChange } }) {
  try {
    const response = yield call(editSubcategory, subcategory);  // Updated action type
    if(response.status) {
      afterChange();
    }
    yield put(editSubcategorySuccess(response));  // Updated action type

  } catch (error) {
    yield put(editSubcategoryFail(error));  // Updated action type
    NotificationService.error(error);
  }
}


// Delete
function* onDeleteSubcategory({ payload: { subcategory, afterChange } }) {  // Updated action type
  try {
    const response = yield call(deleteSubcategory, subcategory);  // Updated action type
    if(response.status){
      afterChange()
      NotificationService.success(response.message)
      yield put(deleteSubcategorySuccess({ subcategory, ...response }));  // Updated action type
    }
  } catch (error) {
    yield put(deleteSubcategoryFail(error));  // Updated action type
    if(error){
      NotificationService.error(error)
    }
  }
}

// Get all
function* getSubcategories({ payload: subcategory }) {  // Updated action type
  try {
    const response = yield call(getSubcategoriesApi, subcategory);  // Updated action type
    yield put(subcategoryApiResponseSuccess(GET_SUBCATEGORIES, response.data));  // Updated action type
  } catch (error) {
    yield put(subcategoryApiResponseError(GET_SUBCATEGORIES, error));  // Updated action type
  }
}

function* fetchSubCategoryList({ payload: subcategory }) {
  try {
    const response = yield call(subcategoryListApi, subcategory);

    // console.log(response, 'res for sub')
    yield put(subcategoryApiResponseSuccess(FETCH_SUBCATEGORIES, response.data));
  } catch (error) {
    yield put(subcategoryApiResponseError(FETCH_SUBCATEGORIES, error));
  }
}



export function* watchAddNewSubcategory() {  // Updated action type
  yield takeEvery(ADD_NEW_SUBCATEGORY, onAddNewSubcategory);  // Updated action type
}

export function* watchGetSubcategories() {  // Updated action type
  yield takeEvery(GET_SUBCATEGORIES, getSubcategories);  // Updated action type
}

export function* watchFetchSubcategoriesList() {  // Updated action type
  yield takeEvery(FETCH_SUBCATEGORIES, fetchSubCategoryList);  // Updated action type
}



export function* watchUpdateSubcategory() {  // Updated action type
  yield takeEvery(UPDATE_SUBCATEGORY, onUpdateSubcategory);  // Updated action type
}

export function* watchEditSubcategory() {  // Updated action type
  yield takeEvery(EDIT_SUBCATEGORY, onEditSubcategory);  // Updated action type
}

export function* watchDeleteSubcategory() {  // Updated action type
  yield takeEvery(DELETE_SUBCATEGORY, onDeleteSubcategory);  // Updated action type
}

// export function* watchStatusChange() {  // Updated action type
//   yield takeEvery(STATUS_CHANGE, onStatusChange);  // Updated action type
// }

function* subcategorySaga() {  // Updated component name
  yield all([
    fork(watchGetSubcategories),  // Updated action type
    fork(watchAddNewSubcategory),  // Updated action type
    fork(watchDeleteSubcategory),  // Updated action type
    fork(watchUpdateSubcategory),
    fork(watchEditSubcategory),
    fork(watchFetchSubcategoriesList)
    // fork(watchStatusChange)
    // Updated action type
  ]);
}

export default subcategorySaga;  // Updated component name
