import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_VENDOR,  // Updated action type
  ADD_VENDOR_SUCCESS,  // Updated action type
  ADD_VENDOR_FAIL,  // Updated action type
  DELETE_VENDOR,  // Updated action type
  DELETE_VENDOR_SUCCESS,  // Updated action type
  DELETE_VENDOR_FAIL,  // Updated action type
  UPDATE_VENDOR,  // Updated action type
  UPDATE_VENDOR_SUCCESS,  // Updated action type
  UPDATE_VENDOR_FAIL,  // Updated action type
  GET_VENDORS,  // Updated action type
  VENDOR_LIST,
  STATUS_CHANGE,
  STATUS_CHANGE_FAIL, 
  STATUS_CHANGE_SUCCESS
} from "./actionType";

export const vendorApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const vendorApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const addNewVendor = (vendor, reset) => ({
  type: ADD_NEW_VENDOR,  // Updated action type
  payload: { vendor, reset },
});

export const addVendorSuccess = vendor => ({
  type: ADD_VENDOR_SUCCESS,  // Updated action type
  payload: vendor,
});

export const addVendorFail = vendor => ({
  type: ADD_VENDOR_FAIL,  // Updated action type
  payload: vendor,
});

export const getVendors = vendor => ({
  type: GET_VENDORS,  // Updated action type
  payload: vendor ,
});
export const vendorList = vendor => ({
  type: VENDOR_LIST,  // Updated action type
});
export const updateVendor =(vendor, afterChange)  => ({
  type: UPDATE_VENDOR,  // Updated action type
  payload:{vendor,afterChange} ,
});

export const updateVendorSuccess = vendor => ({
  type: UPDATE_VENDOR_SUCCESS,  // Updated action type
  payload: vendor,
});

export const updateVendorFail = error => ({
  type: UPDATE_VENDOR_FAIL,  // Updated action type
  payload: error,
});

export const deleteVendor = (vendor, afterChange) => ({
  type: DELETE_VENDOR,  // Updated action type
  payload: {vendor, afterChange},
});

export const deleteVendorSuccess = vendor => ({
  type: DELETE_VENDOR_SUCCESS,  // Updated action type
  payload: vendor,
});

export const deleteVendorFail = error => ({
  type: DELETE_VENDOR_FAIL,  // Updated action type
  payload: error,
});


export const statusChange = (vendor, afterChange)  => ({
  type: STATUS_CHANGE,  // Updated action type
  payload: {vendor, afterChange},
});

export const statusChangeSuccess = vendor => ({
  type: STATUS_CHANGE_SUCCESS,  // Updated action type
  payload: vendor,
});

export const statusChangeFail = error => ({
  type: STATUS_CHANGE_FAIL,  // Updated action type
  payload: error,
});
