import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";

import NotificationService from "../../../Service/NotificationService";

// Ecoomerce Redux States
import {
  
  ADD_NEW_CUSTOMER,

  GET_CUSTOMERS,

  UPDATE_CUSTOMER,
  DELETE_CUSTOMER
} from "./actionType";

import {
  customerApiResponseSuccess,
  customerApiResponseError,
  
    addCustomerFail,
    addCustomerSuccess,

    updateCustomerSuccess,
  updateCustomerFail,

  deleteCustomerSuccess,
  deleteCustomerFail,
  getCustomers as onGetCustomers,
  } from "./action";

  import {
  
    addNewCustomer,
    getCustomers as getCustomersApi,
    updateCustomer,
    deleteCustomer,
   
  } from "../../../helpers/fakebackend_helper";

  toast.configure();


  function* onAddNewCustomer({ payload:{customer, reset}  }) {
    try {
      const response = yield call(addNewCustomer, customer);
      yield put(addCustomerSuccess(response));
      
      toast.success(response.message, { autoClose: 3000 });
      reset()
    } catch (error) {
      yield put(addCustomerFail(error));
      NotificationService.error(error)
    }
  }


//update
  function* onUpdateCustomer({ payload: customer }) {
    try {
      const response = yield call(updateCustomer, customer);
      if(response.status){
        NotificationService.success(response.message)
        yield put(updateCustomerSuccess(response));

      }
      
    } catch (error) {
      yield put(updateCustomerFail(error));
      NotificationService.error(error)
    }
  }
  

  //delete
function* onDeleteCustomer({ payload: customer }) {
  try {
    const response = yield call(deleteCustomer, customer);
    if(response.status){
      toast.success("Customer deleted Successfully", { autoClose: 3000 });
      yield put(deleteCustomerSuccess({ customer, ...response }));
      // yield call(getCustomersApi);
      // yield put(customerApiResponseSuccess(GET_CUSTOMERS, response.data));

    }
  } catch (error) {
    yield put(deleteCustomerFail(error));
    if(error){
      NotificationService.error(error)
    }
  }
}
//getAll
function* getCustomers() {
  try {
    const response = yield call(getCustomersApi);
    yield put(customerApiResponseSuccess(GET_CUSTOMERS, response.data));
  } catch (error) {
    yield put(customerApiResponseError(GET_CUSTOMERS, error));
  }
}




  export function* watchAddNewCustomer() {
    yield takeEvery(ADD_NEW_CUSTOMER, onAddNewCustomer);
  }

  export function* watchGetCustomers() {
    yield takeEvery(GET_CUSTOMERS, getCustomers);
  }
  export function* watchUpdateCustomer() {
    yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer);
  }
  
  export function* watchDeleteCustomer() {
    yield takeEvery(DELETE_CUSTOMER, onDeleteCustomer);
  }
  
  


  function* customerSaga() {
    yield all([
      fork(watchGetCustomers),
      fork(watchAddNewCustomer),
      fork(watchDeleteCustomer),
      fork(watchUpdateCustomer),
    ]);
  }
  
  export default customerSaga;