import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import NotificationService from "../../../Service/NotificationService";

// Ecommerce Redux States
import {
  ADD_NEW_VENDOR,  // Updated action type
  GET_VENDORS,  // Updated action type
  UPDATE_VENDOR,  // Updated action type
  DELETE_VENDOR,
  STATUS_CHANGE,
  VENDOR_LIST,  // Updated action type
} from "./actionType";

import {
  vendorApiResponseSuccess,  // Updated action type
  vendorApiResponseError,  // Updated action type
  addVendorFail,  // Updated action type
  addVendorSuccess,  // Updated action type
  updateVendorSuccess,  // Updated action type
  updateVendorFail,  // Updated action type
  deleteVendorSuccess,  // Updated action type
  deleteVendorFail,  // Updated action type
  getVendors as onGetVendors,  // Updated action type
  statusChangeSuccess,
  statusChangeFail
} from "./action";

import {
  addNewVendor,  // Updated action type
  getVendors as getVendorsApi,  // Updated action type
  updateVendor,  // Updated action type
  deleteVendor,  // Updated action type
  statusChange, 
  vendorListApi
} from "../../../helpers/fakebackend_helper";

toast.configure();

function* onAddNewVendor({ payload: { vendor, reset } }) {  // Updated action type
  try {
    const response = yield call(addNewVendor, vendor);  // Updated action type
    if(response.status){
      NotificationService.success(response.message)
      reset();
    }
    yield put(addVendorSuccess(response));  // Updated action type
    
  } catch (error) {
    yield put(addVendorFail(error));  // Updated action type
    NotificationService.error(error)

    // toast.error(error, { autoClose: 3000 });
  }
}

// Update
function* onUpdateVendor({ payload: {vendor, afterChange} }) {  // Updated action type
  try {
    const response = yield call(updateVendor, vendor);  // Updated action type
    if(response.status){
      afterChange()
      NotificationService.success(response.message)
      yield put(updateVendorSuccess(response));  // Updated action type
    }
  } catch (error) {
    yield put(updateVendorFail(error));  // Updated action type
    NotificationService.error(error)
  }
}

// Delete
function* onDeleteVendor({ payload: {vendor, afterChange} }) {  // Updated action type
  try {
    const response = yield call(deleteVendor, vendor);  // Updated action type
    if(response.status){
      afterChange()
      NotificationService.success(response.message)
      yield put(deleteVendorSuccess({ vendor, ...response }));  // Updated action type
    }
  } catch (error) {
    yield put(deleteVendorFail(error));  // Updated action type
    if(error){
      NotificationService.error(error)
    }
  }
}

// Get all
function* getVendors({ payload: vendor }) {  // Updated action type
  try {
    const response = yield call(getVendorsApi, vendor);  // Updated action type
    yield put(vendorApiResponseSuccess(GET_VENDORS, response.data));  // Updated action type
  } catch (error) {
    yield put(vendorApiResponseError(GET_VENDORS, error));  // Updated action type
  }
}

function* vendorList() {
  try {
    const response = yield call(vendorListApi);

    // console.log(response, 'res for vendor')
    yield put(vendorApiResponseSuccess(VENDOR_LIST, response.data));
  } catch (error) {
    yield put(vendorApiResponseError(VENDOR_LIST, error));
  }
}

function* onStatusChange({ payload: {vendor, afterChange } }) {  // Updated action type
  try {
    const response = yield call(statusChange, vendor);  // Updated action type
    if(response.status){
      afterChange()
      NotificationService.success(response.message)
      yield put(statusChangeSuccess({ vendor, ...response }));  // Updated action type
    }
  } catch (error) {
    yield put(statusChangeFail(error));  // Updated action type
    if(error){
      NotificationService.error(error)
    }
  }
}

export function* watchAddNewVendor() {  // Updated action type
  yield takeEvery(ADD_NEW_VENDOR, onAddNewVendor);  // Updated action type
}

export function* watchGetVendors() {  // Updated action type
  yield takeEvery(GET_VENDORS, getVendors);  // Updated action type
}


export function* watchVendorList() {  // Updated action type
  yield takeEvery(VENDOR_LIST, vendorList);  // Updated action type
}

export function* watchUpdateVendor() {  // Updated action type
  yield takeEvery(UPDATE_VENDOR, onUpdateVendor);  // Updated action type
}

export function* watchDeleteVendor() {  // Updated action type
  yield takeEvery(DELETE_VENDOR, onDeleteVendor);  // Updated action type
}


export function* watchStatusChange() {  // Updated action type
  yield takeEvery(STATUS_CHANGE, onStatusChange);  // Updated action type
}

function* vendorSaga() {  // Updated component name
  yield all([
    fork(watchGetVendors),  // Updated action type
    fork(watchAddNewVendor),  // Updated action type
    fork(watchDeleteVendor),  // Updated action type
    fork(watchUpdateVendor),
    fork(watchVendorList),
    fork(watchStatusChange)
    // Updated action type
  ]);
}

export default vendorSaga;  // Updated component name
