// reducers.js
import {
  // FETCH_PRODUCTS_SUCCESS,
  // FETCH_PRODUCTS_FAILURE,
  API_RESPONSE_ERROR,
  API_RESPONSE_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  ADD_PRODUCT,
  FETCH_PRODUCTS,
  EDIT_PRODUCT_SUCCESS,
  EDIT_PRODUCT_FAIL,
  PRODUCT_LIST,

  PRODUCT_STATUS_CHANGE_SUCCESS,
  PRODUCT_STATUS_CHANGE_FAIL,
  VENDOR_FETCH_PRODUCTS
} from "./actionType";

const initialState = {
  products: [],
  productAllList: [],
  editData : null,
  loading: false,
  error: null,
};

const Product = (state = initialState, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case FETCH_PRODUCTS:
          return {
            ...state,
            products: action.payload.data,
          };
          case VENDOR_FETCH_PRODUCTS:
          return {
            ...state,
            products: action.payload.data,
          };
          case PRODUCT_LIST:
          return {
            ...state,
            productAllList: action.payload.data,
          };

        default:
          return { ...state };
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case FETCH_PRODUCTS:
          return {
            ...state,
            error: action.payload.error,
          };
          case VENDOR_FETCH_PRODUCTS:
          return {
            ...state,
            error: action.payload.error,
          };
          case PRODUCT_LIST:
          return {
            ...state,
            error: action.payload.error,
          };

        default:
          return { ...state };
      }
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        products: [...state.products, action.payload.product],
        loading: false,
        error: null,
      };
    case ADD_PRODUCT_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.map((product) =>
          product.id.toString() === action.payload.product.id.toString()
            ? { ...product, ...action.payload.data }
            : product
        ),
      };

    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    // productReducer.js
    case EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        editData: action.payload.data, // Assuming payload contains the updated product
      };

    case EDIT_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(
          (product) =>
            product.id.toString() !== action.payload.product.toString()
        ),
      };

    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

      case PRODUCT_STATUS_CHANGE_SUCCESS:  // Updated action type
      return {
        ...state,
        products: Array.isArray(state?.products)
          ? state.products.filter(
              (product) => product?.uuid.toString() !== action.payload.uuid.toString()
            )
          : [],
      };

    case PRODUCT_STATUS_CHANGE_FAIL:  // Updated action type
      return {
        ...state,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default Product;
