import React, { useState } from 'react';
import { Col, Input, Label, Row, Form, FormFeedback } from 'reactstrap';
import BlankLayout from '../../../Components/Common/BlankLayout';
import CardSection from '../../../Components/Common/CardSection';
import OFFCanvas from '../../../Components/Common/OffCanvas/OFFCanvas';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useForm, Controller } from "react-hook-form";

const ViewDetailModal = (props) => {
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
    
      const [flag, setflag] = useState(false);
    
      const onSubmit = (data) => {
        alert("done");
        // Handle the form submission, including uploaded files in 'selectedFiles'
        console.log(data);
      };
  return (
   <>
    <OFFCanvas canvasTitle={'Full Details'} direction={'end'} flag={props.flag} handler={()=>props.Handler()}>
  <Row>

        </Row>
  </OFFCanvas>
   </>
  )
}

export default ViewDetailModal