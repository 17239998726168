import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationService from "../../../Service/NotificationService";

// Ecoomerce Redux States
import {
  
  // ADD_NEW_CUSTOMER,

  GET_GETIN_TOUCH,

  // UPDATE_CUSTOMER,
  DELETE_GETIN_TOUCH
} from "./actionType";

import {
  getInTouchApiResponseError,
  getInTouchApiResponseSuccess,
  
    // addCustomerFail,
    // addCustomerSuccess,

  //   updateCustomerSuccess,
  // updateCustomerFail,

  deleteGetInTouchSuccess,
  deleteGetInTouchFail,
   
  } from "./action";

  import {
  
    // addNewCustomer,
    getGetInTouch as getGetInTouchApi,
    // updateCustomer,
    deleteGetInTouch,
   
  } from "../../../helpers/fakebackend_helper";

  toast.configure();


  // function* onAddNewCustomer({ payload: customer }) {
  //   try {
  //     const response = yield call(addNewCustomer, customer);
  //     yield put(addCustomerSuccess(response));
      
  //     toast.success(response.message, { autoClose: 3000 });
  //   } catch (error) {
  //     yield put(addCustomerFail(error));
  //     toast.error(error, { autoClose: 3000 });
  //   }
  // }


//update
  // function* onUpdateCustomer({ payload: customer }) {
  //   try {
  //     const response = yield call(updateCustomer, customer);
  //     yield put(updateCustomerSuccess(response));
  //     toast.success(response.message, { autoClose: 3000 });
  //   } catch (error) {
  //     yield put(updateCustomerFail(error));
  //     toast.error(error, { autoClose: 3000 });
  //   }
  // }
  

  //delete
function* onDeleteGetInTouch({ payload: getInTouch }) {
  try {
    const response = yield call(deleteGetInTouch, getInTouch);
    if(response.status){
      NotificationService.success(response.message)
    }
    yield put(deleteGetInTouchSuccess({ getInTouch, ...response }));
  } catch (error) {
    yield put(deleteGetInTouchFail(error));
    NotificationService.error(error)
  }
}
//getAll
function* getGetInTouch() {
  try {
    const response = yield call(getGetInTouchApi);
    yield put(getInTouchApiResponseSuccess(GET_GETIN_TOUCH, response.data));
  } catch (error) {
    yield put(getInTouchApiResponseError(GET_GETIN_TOUCH, error));
  }
}




  // export function* watchAddNewCustomer() {
  //   yield takeEvery(ADD_NEW_CUSTOMER, onAddNewCustomer);
  // }

  export function* watchGetGetInTouch() {
    yield takeEvery(GET_GETIN_TOUCH, getGetInTouch);
  }
  // export function* watchUpdateCustomer() {
  //   yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer);
  // }
  
  export function* watchDeleteGetInTouch() {
    yield takeEvery(DELETE_GETIN_TOUCH, onDeleteGetInTouch);
  }
  
  


  function* getInTouchSaga() {
    yield all([
      fork(watchGetGetInTouch),
      // fork(watchAddNewCustomer),
      fork(watchDeleteGetInTouch),
      // fork(watchUpdateCustomer),
    ]);
  }
  
  export default getInTouchSaga;