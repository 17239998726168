import React, { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import { Col, Label, Row, Card, CardHeader, CardBody } from "reactstrap";
import BlankLayout from '../../../Components/Common/BlankLayout';
import ViewDetailModal from './ViewDetailModal';
import DeleteLetsTalkModal from './DeleteLet\'sTalkModal';
import { useSelector, useDispatch } from "react-redux";


import {
  getBusinessInquiry as onGetBusinessInquiry,
  // addNewBusinessInquiry as onAddNewBusinessInquiry,
  // updateCustomer as onUpdateCustomer,
  deleteBusinessInquiry as onDeleteBusinessInquiry,
} from "../../../store/admin/businessinquiry/action";

const LetsTalkTable = () => {

    // const [isEdit, setIsEdit] = useState(false);
    const [flag, setflag] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteEnquiryId, setDeleteEnquiryId] = useState()
//     // const [deleteModalMulti, setDeleteModalMulti] = useState(false);

    const [businessInquiryData, setBusinessInquiryData] = useState([]);
//     const [deleteCustomer, setDeleteCustomer] = useState()
//     const [editData, setEditData] = useState()
const { businessInquiry, isBusinessInquiryCreated, isBusinessInquirySuccess, error } = useSelector((state) =>
({
  
  businessInquiry: state?.BusinessInquiry?.businessInquiry ,
  isBusinessInquiryCreated: state.BusinessInquiry ? state.BusinessInquiry.isBusinessInquiryCreated : false,
  isBusinessInquirySuccess: state.BusinessInquiry ? state.BusinessInquiry.isBusinessInquirySuccess : false,
  error: state.BusinessInquiry ? state.BusinessInquiry.error : null,
}    

));

    
  const dispatch = useDispatch();

  useEffect(() => {
    if (businessInquiry && !businessInquiry.length) {
      dispatch(onGetBusinessInquiry());
    }
  }, []);


  // dispatch, customers
  useEffect(() => {
    setBusinessInquiryData(businessInquiry);
  }, [businessInquiry]);


  


    const onClickDelete = (id) => {
      setDeleteEnquiryId(id)
    //   setOrder(order);
      setDeleteModal(true);
    
    };

    
    const handleDeleteBusinessInquiry = async () => {
      if (businessInquiryData?.businessInquiry) {
       await dispatch(onDeleteBusinessInquiry(deleteEnquiryId));
        dispatch(onGetBusinessInquiry());
        setDeleteModal(false);
      

      }
    };
    const columns = [
        // {
        //     name: <Input className="form-check-input fs-15" type="checkbox" name="checkAll" value="option1" />,
        //     cell: () => (
        //         <input className="form-check-input fs-15" type="checkbox" name="checkAll" value="option1" />
        //     ),
        // },
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            cell: (row, index) => <div>{index + 1}</div>,
            // options: {
            //   customBodyRender: (value, tableMeta) => tableMeta.rowIndex + 1,
            // },
            // selector: row => row.srNo,
            // sortable: true
        },
        // {
        //     name: <span className='font-weight-bold fs-13'>ID</span>,

        //     selector: row => row.id,
        //     // sortable: true
        // },
        {
            name: <span className='font-weight-bold fs-13'>First Name</span>,
            selector: row => row.first_name,
            // sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Last Name</span>,
          selector: row => row.last_name,
          // sortable: true
      },
      {
        name: <span className='font-weight-bold fs-13'>Email</span>,
        selector: row => row.email,
        // sortable: true
    },
    {
        name: <span className='font-weight-bold fs-13'>Phone Number</span>,
        selector: row => row.mobile,
        // sortable: true
    },
        {
            name: <span className='font-weight-bold fs-13'>Company Name</span>,
            selector: row => row.business_name,
            // sortable: true
        },
        {
          name: <span className='font-weight-bold fs-13'>Company Location</span>,
          selector: row => row.based,
          // sortable: true
      },
      {
        name: <span className='font-weight-bold fs-13'>website</span>,
        selector: row => row.website,
        // sortable: true
    },
   
      {
        name: <span className='font-weight-bold fs-13'>Interested Service</span>,
        selector: row => row.intrested_area,
        // sortable: true
    },
  //   {
  //     name: <span className='font-weight-bold fs-13'>Description</span>,
  //     selector: row => row.description,
  //     // sortable: true
  // },
  {
    name: <span className='font-weight-bold fs-13'>Your Budget</span>,
    selector: row => row.budget,
    // sortable: true
},
{
  name: <span className='font-weight-bold fs-13'>How do you know about us?</span>,
  selector: row => row.mode_of_information,
  // sortable: true
},
      
        // {
        //     name: <span className='font-weight-bold fs-13'>Password</span>,
        //     selector: row => row.password,
        //     // sortable: true
        // },
       
        // {
        //     name: <span className='font-weight-bold fs-13'>Image</span>,
        //     selector: row => <img src={row.image} className='avatar-xs rounded-circle' alt="" /> ,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Assigned To</span>,
        //     selector: row => row.assigned,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Create By</span>,
        //     selector: row => row.createdBy,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Create Date</span>,
        //     selector: row => row.createDate,
        //     sortable: true
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Status</span>,
        //     sortable: true,
        //     selector: (cell) => {
        //         switch (cell.status) {
        //             case "Re-open":
        //                 return <span className="badge badge-soft-info"> {cell.status} </span>;
        //             case "On-Hold":
        //                 return <span className="badge badge-soft-secondary"> {cell.status} </span>;
        //             case "Closed":
        //                 return <span className="badge badge-soft-danger"> {cell.status} </span>;
        //             case "Inprogress":
        //                 return <span className="badge badge-soft-warning"> {cell.status} </span>;
        //             case "Open":
        //                 return <span className="badge badge-soft-primary"> {cell.status} </span>;
        //             case "New":
        //                 return <span className="badge badge-soft-success"> {cell.status} </span>;
        //             default:
        //                 return <span className="badge badge-soft-success"> {cell.status} </span>;
        //         }
        //     },
        // },
        // {
        //     name: <span className='font-weight-bold fs-13'>Priority</span>,
        //     sortable: true,
        //     selector: (cell) => {
        //         switch (cell.priority) {
        //             case "High":
        //                 return <span className="badge bg-danger"> {cell.priority} </span>;
        //             case "Medium":
        //                 return <span className="badge bg-info"> {cell.priority} </span>;
        //             case "Low":
        //                 return <span className="badge bg-success"> {cell.priority} </span>;
        //             default:
        //                 return <span className="badge bg-danger"> {cell.priority} </span>;
        //         }
        //     },
        // },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            // sortable: true,
            cell: (cellProps) => {
              return (
                <ul className="list-inline hstack gap-2 mb-0">
                  <li className="list-inline-item">
                    <Link
                      to="#"
                      className="text-primary d-inline-block"
                      onClick={() => {
                        setflag(!flag);
                      }}
                    >
                      <i className="ri-eye-fill fs-16"></i>
                    </Link>
                  </li>
                  {/* <li className="list-inline-item edit">
                    <Link
                      to="#"
                      className="text-primary d-inline-block edit-item-btn"
                      onClick={() => {
                        setflag(!flag);
                      }}
                    //   onClick={() => {
                    //     const orderData = cellProps.row.original;
                    //     handleOrderClick(orderData);
                    //   }}
                    >
                      <i className="ri-pencil-fill fs-16"></i>
                    </Link>
                  </li> */}
                  <li className="list-inline-item">
                    <Link
                      to="#"
                      className="text-danger d-inline-block remove-item-btn"
                      onClick={() => {
                        const customerData = cellProps?.id;
                        onClickDelete(customerData);
                      }}
                    >
                      <i className="ri-delete-bin-5-fill fs-16"></i>
                    </Link>
                  </li>
                </ul>
              );
            },
          },
        // {
        //     name: <span className='font-weight-bold fs-13'>Action</span>,
        //     sortable: true,

        //     cell: () => {
        //         return (
        //             <UncontrolledDropdown className="dropdown d-inline-block">
        //                 <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
        //                     <i className="ri-more-fill align-middle"></i>
        //                 </DropdownToggle>
        //                 <DropdownMenu className="dropdown-menu-end">
        //                     <DropdownItem href="#"><i className="ri-eye-fill align-bottom me-2 text-muted"></i>View</DropdownItem>
        //                     <DropdownItem className='edit-item-btn'><i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit</DropdownItem>
        //                     <DropdownItem className='remove-item-btn'> <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete </DropdownItem>
        //                 </DropdownMenu>
        //             </UncontrolledDropdown>
        //         );
        //     },
        // },
    ];

    const data = [
        {
            srNo: "01",
            id: "VLZ-452",
            purchaseId: "VLZ1400087402",
            title: "Post launch reminder/ post list",
            name: "Joseph Parker",
            business_name : "abc",
            message: "lorem lorem lorem",
            phone_number: "87654567435",
            email: "john@gmail.com",
            password: "12345678",
            website: "customer.com",
            image: avatar2,
            assigned: "Alexis Clarke",
            createdBy: "Joseph Parker",
            createDate: "03 Oct, 2021",
            status: "Re-open",
            priority: "High",
        },
        {
            srNo: "02",
            id: "VLZ-453",
            purchaseId: "VLZ1400087425",
            title: "Additional Calendar",
            name: "Diana Kohler",
            business_name : "abc",
            message: "lorem lorem lorem",
            phone_number: "87654567435",
            email: "john@gmail.com",
            password: "12345678",
            website: "customer.com",
            assigned: "Admin",
            image: avatar2,
            createdBy: "Mary Rucker",
            createDate: "05 Oct, 2021",
            status: "On-Hold",
            priority: "Medium",
        },
        {
            srNo: "03",
            id: "VLZ-454",
            purchaseId: "VLZ1400087438",
            title: "Make a creating an account profile",
            name: "Tonya Noble",
            business_name : "abc",
            message: "lorem lorem lorem",
            phone_number: "87654567435",
            email: "john@gmail.com",
            password: "12345678",
            website: "customer.com",
            image: avatar2,
            assigned: "Admin",
            createdBy: "Tonya Noble",
            createDate: "27 April, 2022",
            status: "Closed",
            priority: "Low",
        },
        {
            srNo: "04",
            id: "VLZ-455",
            purchaseId: "VLZ1400087748",
            title: "Apologize for shopping Error!",
            name: "Joseph Parker",
            business_name : "abc",
            message: "lorem lorem lorem",
            phone_number: "87654567435",
            email: "john@gmail.com",
            password: "12345678",
            website: "customer.com",
            image: avatar2,
            assigned: "Alexis Clarke",
            createdBy: "Joseph Parker",
            createDate: "14 June, 2021",
            status: "Inprogress",
            priority: "Medium",
        },
        {
            srNo: "05",
            id: "VLZ-456",
            purchaseId: "VLZ1400087547",
            title: "Support for theme",
            name: "Donald Palmer",
            business_name : "abc",
            message: "lorem lorem lorem",
            phone_number: "87654567435",
            email: "john@gmail.com",
            password: "12345678",
            website: "customer.com",
            image: avatar2,
            assigned: "Admin",

            createdBy: "Donald Palmer",
            createDate: "25 June, 2021",
            status: "Closed",
            priority: "Low",
        },
        {
            srNo: "06",
            id: "VLZ-457",
            purchaseId: "VLZ1400087245",
            title: "Benner design for FB & Twitter",
            name: "Mary Rucker",
            business_name : "abc",
            message: "lorem lorem lorem",
            phone_number: "87654567435",
            email: "john@gmail.com",
            password: "12345678",
            website: "customer.com",
            image: avatar2,
            assigned: "Jennifer Carter",
            createdBy: "Mary Rucker",
            createDate: "14 Aug, 2021",
            status: "Inprogress",
            priority: "Medium",
        },
        {
            srNo: "07",
            id: "VLZ-458",
            purchaseId: "VLZ1400087785",
            title: "Change email option process",
            name: "James Morris",
            business_name : "abc",
            message: "lorem lorem lorem",
            phone_number: "87654567435",
            email: "john@gmail.com",
            password: "12345678",
            website: "customer.com",
            image: avatar2,
            assigned: "Admin",
            createdBy: "James Morris",
            createDate: "12 March, 2022",
            status: "Open",
            priority: "High",
        },
        {
            srNo: "08",
            id: "VLZ-460",
            purchaseId: "VLZ1400087745",
            title: "Support for theme",
            name: "Nathan Cole",
            business_name : "abc",
            message: "lorem lorem lorem",
            phone_number: "87654567435",
            email: "john@gmail.com",
            password: "12345678",
            website: "customer.com",
            image: avatar2,
            assigned: "Nancy Martino",
            createdBy: "Nathan Cole",
            createDate: "28 Feb, 2022",
            status: "On-Hold",
            priority: "Low",
        },
        {
            srNo: "09",
            id: "VLZ-461",
            purchaseId: "VLZ1400087179",
            title: "Form submit issue",
            name: "Grace Coles",
            business_name : "abc",
            message: "lorem lorem lorem",
            phone_number: "87654567435",
            email: "john@gmail.com",
            password: "12345678",
            website: "customer.com",
            image: avatar2,
            assigned: "Admin",
            createdBy: "Grace Coles",
            createDate: "07 Jan, 2022",
            status: "New",
            priority: "High",
        },
        {
            srNo: "10",
            id: "VLZ-462",
            purchaseId: "VLZ140008856",
            title: "Edit customer testimonial",
            name: "Freda",
            business_name : "abc",
            message: "lorem lorem lorem",
            phone_number: "87654567435",
            email: "john@gmail.com",
            password: "12345678",
            website: "customer.com",
            image: avatar2,
            assigned: "Alexis Clarke",
            createdBy: "Freda",
            createDate: "16 Aug, 2021",
            status: "Closed",
            priority: "Medium",
        },
        {
            srNo: "11",
            id: "VLZ-462",
            purchaseId: "VLZ140008856",
            title: "Edit customer testimonial",
            name: "Freda",
            business_name : "abc",
            message: "lorem lorem lorem",
            phone_number: "87654567435",
            email: "john@gmail.com",
            password: "12345678",
            website: "customer.com",
            image: avatar2,
            assigned: "Alexis Clarke",
            createdBy: "Freda",
            createDate: "16 Aug, 2021",
            status: "Closed",
            priority: "Medium",
        },
    ];
  return (
    <>
    <BlankLayout metaTitle={"Let's talk Section"} pageTitle={"Let's talk"} breadcrumTitle={"Let's talk"} breadcrumPagetitle={"Let's talk"}>

      <DataTable
            columns={columns}
            data={businessInquiryData?.businessInquiry}
            pagination
            // highlightOnHover={true}
        />
        </BlankLayout>

 <DeleteLetsTalkModal
        show={deleteModal}
        onDeleteClick={handleDeleteBusinessInquiry}
        onCloseClick={() => setDeleteModal(false)}
      />

<ViewDetailModal flag={flag}  Handler={()=>{setflag(!flag)}} />
    </>
  )
}

export default LetsTalkTable