// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_COUNTRY_CHARTS_DATA = "GET_COUNTRY_CHARTS_DATA";
export const GET_AUDIENCESMETRICS_CHARTS_DATA = "GET_AUDIENCESMETRICS_CHARTS_DATA";
export const GET_USERDEVICE_CHARTS_DATA = "GET_USERDEVICE_CHARTS_DATA";
export const GET_AUDIENCESSESSIONS_CHARTS_DATA = "GET_AUDIENCESSESSIONS_CHARTS_DATA";


export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILURE = 'GET_DASHBOARD_DATA_FAILURE';


export const GET_APPKEY_DATA = "GET_APPKEY_DATA";
export const GET_APPKEY_DATA_SUCCESS = 'GET_APPKEY_DATA_SUCCESS';
export const GET_APPKEY_DATA_FAILURE = 'GET_APPKEY_DATA_FAILURE';


export const CHANGE_APPKEY_DATA = "CHANGE_APPKEY_DATA";
export const CHANGE_APPKEY_DATA_SUCCESS = 'CHANGE_APPKEY_DATA_SUCCESS';
export const CHANGE_APPKEY_DATA_FAILURE = 'CHANGE_APPKEY_DATA_FAILURE';

