import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Label,
  Row,
  Card,
  CardHeader,
  CardBody,
  FormFeedback,
} from "reactstrap";
import CardSection from "../../../Components/Common/CardSection";
// import ProductTable from "./ProductsTable";
// import ProductsForm from "./ProductsForm";
import BlankLayout from "../../../Components/Common/BlankLayout";
import { useDispatch, useSelector } from "react-redux";
import { getAppKeyData, changeAppKeyData } from "../../../store/dashboardAnalytics/action";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import AddkeychangeModal from "./Modals/AppkeychangeModal";

const VendorSettings = () => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const [settingsData, setSettingsData] = useState([]);
  const [isEditingDisabled, setIsEditingDisabled] = useState(true);
  const [passwordShow, setPasswordShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);


  const { appKeyData } = useSelector((state) => ({
    appKeyData: state?.DashboardAnalytics?.appKeyData,
  }));

  const vendorId = sessionStorage.getItem("vendor_id");
  const afterAdd = () => {};
  const afterChange =async () => {
    setDeleteModal(false)
    
    const product = {
      vendor_id: vendorId !== "1" ? vendorId : "",
    };
    if(deleteModal !== true){
      await dispatch(getAppKeyData(product, afterAdd));
    }
  };


  useEffect(() => {
    const product = {
      vendor_id: vendorId !== "1" ? vendorId : "",
    };
    dispatch(getAppKeyData(product, afterAdd));
  }, []);

  useEffect(() => {
    setSettingsData(appKeyData[0]);
  }, [appKeyData]);

  console.log(settingsData, "setting");
  
  const handleDeleteOrder = async () =>{
    const product = {
      vendor_id: vendorId !== "1" ? vendorId : "",
    };
    dispatch(changeAppKeyData(product, afterChange));
  }

  return (
    <React.Fragment>
      <BlankLayout
        metaTitle={"Settings"}
        pageTitle={"settings"}
        breadcrumTitle={"settings"}
        breadcrumPagetitle={"settings"}
      >
        <Row>
          <Col xl={12}>
            <CardSection panelTitle="Regenerate App key">
              {/* <ProductsForm /> */}
              <Col md={6}>
                <div className="mb-3">
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    // rules={{ required: "Product Name is required" }}
                    render={({ field }) => (
                      <>
                        <Label for="firstNameinput" className="form-label">
                          App id
                        </Label>
                        <Input
                          {...field}
                          type="text"
                          // disabled={isEditingDisabled}
                          value={settingsData?.app_id}
                          // invalid={errors.name ? true : false}
                          className="form-control"
                          // placeholder="Enter your name"
                          id="firstNameinput"
                        />
                        {/* <FormFeedback type="invalid">
                          {errors.name && errors.name.message}
                        </FormFeedback> */}
                      </>
                    )}
                  />
                </div>
               
                
              
                
              </Col>
              <div className="d-flex  align-items-center">
              <Col md={5}>
              <div className="mb-3">
                  <Label className="form-label" htmlFor="password-input">
                    App key
                  </Label>
                  <div className="position-relative auth-pass-inputgroup mb-3">
                    <Input
                      name="password"
                      value={settingsData?.app_key || ""}
                      type={passwordShow ? "text" : "password"}
                      className="form-control pe-5"
                      // placeholder="Enter Password"
                      // onChange={validation.handleChange}
                      // onBlur={validation.handleBlur}
                      // invalid={
                      //     validation.touched.password && validation.errors.password ? true : false
                      // }
                    />
                    {/* {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null} */}
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password-addon"
                      onClick={() => setPasswordShow(!passwordShow)}
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={1} className="mt-2">
              {/* <button className="py-2 px-3 fw-bold bg-danger text-white rounded-2xl">Change</button> */}
              <button onClick={() => setDeleteModal(true)} type="button" class="btn btn-danger">Change</button>

              </Col>
              </div>
            </CardSection>
          </Col>
          <Col xl={8}>
            {/* <CardSection panelTitle="Products Table">
              <ProductTable />
            </CardSection> */}
          </Col>
        </Row>
      </BlankLayout>
      <AddkeychangeModal
        show={deleteModal}
        onDeleteClick={()=>handleDeleteOrder()}
        onCloseClick={() => setDeleteModal(false)}
      />
    </React.Fragment>
    
  );
};

export default VendorSettings;
