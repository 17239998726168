import React, { useState, useEffect } from 'react';
import { Col, Input, Label, Row, Form, FormFeedback } from 'reactstrap';
import BlankLayout from '../../../Components/Common/BlankLayout';
import CardSection from '../../../Components/Common/CardSection';
import OFFCanvas from '../../../Components/Common/OffCanvas/OFFCanvas';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  getVendors as onGetVendors,  // Updated action
  updateVendor as onUpdateVendor,  // Updated action
} from "../../../store/admin/Vendor/action";  // Updated import path

import {
  getSubcategories as onGetSubcategories,  // Updated action
  editSubcategory as onEditSubcategory,  // Updated action
  updateSubcategory as onUpdateSubcategory
} from "../../../store/admin/SubCategory/action"; 
import { categoryList } from "../../../store/admin/Category/action";


const FilterModal = (props) => {  // Updated component name
    const [id, setId] = useState(null)
    const [subCategory, setSubcategory] = useState()
    const [category, setCategory] = useState([]);
    



  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { subcategories, error, loading } = useSelector((state) => ({  // Updated selector name
    subcategories: state?.Subcategory?.subcategories,  // Updated state name
    loading: state?.Subcategory?.loading,  // Updated state name
    error: state?.Subcategory?.error,  // Updated state name
  }));

  const dispatch = useDispatch();
  
  useEffect(() => {
    const data = {
      "page": 1,
      "page_size": 20
    }
    dispatch(onGetSubcategories(data)); 
  }, []);

  useEffect(() => {
    setSubcategory(subcategories);  
  }, [subcategories]);



  

const [selectedItemIds, setSelectedItemIds] = useState([]);

const handleCheckboxChange = (itemId) => {
  if (selectedItemIds.includes(itemId)) {
    setSelectedItemIds(selectedItemIds.filter(id => id !== itemId));
  } else {
    setSelectedItemIds([...selectedItemIds, itemId]);
  }
};

// useEffect(()=>{
//  props.setId(selectedItemIds)
// },[selectedItemIds])
console.log(selectedItemIds, 'sub')


const groupedItems = {};

subCategory?.list?.forEach(item => {
  if (!groupedItems[item.category_name]) {
    groupedItems[item.category_name] = [];
  }
  groupedItems[item.category_name].push(item);
});


  return (
    <>
      <OFFCanvas canvasTitle={'Filter products'} direction={'end'} flag={props.filter} handler={() =>{
props.Handler();
// setSelectedItemIds([]);
props.setId([]);
      } }>
        <Row>
       
          <Col xl={12}>
          <div>
      {Object.keys(groupedItems).map((categoryName, index) => (
        <div key={index}>
          <h5 className="font-weight-bold pt-3">{categoryName}</h5>
          {groupedItems[categoryName].map((item, itemIndex) => (
          <div className="row align-items-center pt-1" key={itemIndex}>
          <div className="col-auto">
            <input
              type="checkbox"
              className=""
              checked={selectedItemIds.includes(item.id)}
              onChange={() => handleCheckboxChange(item.id)}
              id={`checkbox-${item.id}`} 
            />
          </div>
          <div className="col">
            <label htmlFor={`checkbox-${item.id}`} className="mb-0">{item.name}</label>
          </div>
        </div>
          ))}
        </div>
      ))}
    </div>
    <button onClick={()=>{
        props.setId(selectedItemIds);
        props.Handler()
        } } type="button" class="btn btn-dark mt-3">Apply</button>

            {/* <CardSection panelTitle="Enter Details"> */}
            
            {/* </CardSection> */}
          </Col>
        
        </Row>
      </OFFCanvas>
    </>
  )
}

export default FilterModal;  // Updated component name
