import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import NotificationService from "../../../Service/NotificationService"
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";
import { toast } from "react-toastify";
const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  console.log(user, history, '...')
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      if (response) {
        yield put(loginSuccess(response));
        history('/dashboard');
      } else {
        yield put(apiError(response));
      }
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        username: user.email,
        password: user.password,
      });
      sessionStorage.setItem("authUser", JSON.stringify(response?.data?.accessToken));
      sessionStorage.setItem("refreshToken", JSON.stringify(response?.data?.refreshToken));
      sessionStorage.setItem("userType", response?.data?.user_info.role)
      sessionStorage.setItem("vendor_id", JSON.stringify(response?.data?.user_info.id))
      sessionStorage.setItem("userName", JSON.stringify(response?.data?.user_info?.name))
      if (response) {
        yield put(loginSuccess(response));
        history('/dashboard-analytics');
        NotificationService.success(response.message)

      } else {
        // console.log(response, 'from saga')
        yield put(apiError(response));
        NotificationService.error(response?.data?.error?.message)

        // toast.error(response?.data?.error?.message, { autoClose: 3000 });

      }
    } else if (process.env.REACT_APP_API_URL) {
      const response = yield call(postFakeLogin, {
        email: user.username,
        password: user.password,
      });
      if (response.status === "success") {
        yield put(loginSuccess(response));
        history('/dashboard');
        sessionStorage.setItem("authUser", JSON.stringify(response));
      } else {
        yield put(apiError(response));
        NotificationService.error(response?.data?.error?.message)

      }
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    sessionStorage.removeItem("authUser");
    sessionStorage.removeItem("vendor_id");
    sessionStorage.removeItem("userType");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("refreshToken");




    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(LOGOUT_USER, response));
    } else {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      );
      sessionStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      sessionStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
