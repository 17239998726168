import {
    FORM_SUBMIT_REQUEST,
    FORM_SUBMIT_SUCCESS,
    FORM_SUBMIT_FAILURE,
  } from './actionType';


const initialState = {
    data: null,
    error: null,
    loading: false
  };
  
  const ProfilePicChange = (state = initialState, action) => {
    switch (action.type) {
      case FORM_SUBMIT_REQUEST:
        return {
          ...state,
          error: null,
          loading: true
        };
      case FORM_SUBMIT_SUCCESS:
        return {
          ...state,
          data: action.payload,
        };
      case FORM_SUBMIT_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default ProfilePicChange;