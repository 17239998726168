import axios from "axios";
import { api } from "../config";


let isRefreshing = false;
let refreshSubscribers = [];

const onRefreshed = (newToken) => {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
  isRefreshing = false;
  
};

const addSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};


// default
axios.defaults.baseURL = api?.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")) : null;
if (token)
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
console.log(token, 'doken')
// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    console.log(response, 'ressssss')
    return response.data ? response.data : response;
  },
  async function  (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const originalRequest = error.config;
    let refreshPromise = null;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          // Fetch the refresh token from where it's stored on the client side
          const refreshToken = JSON.parse(sessionStorage.getItem("refreshToken"));

          // Use the refresh token to get a new access token
          const response = await axios.post("/auth/refresh-token", { refresh_token: refreshToken });

          if (response.status) {
            sessionStorage.removeItem("authUser");
            sessionStorage.removeItem("refreshToken");
            sessionStorage.setItem("authUser", JSON.stringify(response?.data?.accessToken));
            sessionStorage.setItem("refreshToken", JSON.stringify(response?.data?.refreshToken));
          }

          const newToken = JSON.parse(sessionStorage.getItem("authUser"));

          onRefreshed(newToken);

          // Retry the original request with the new access token
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          console.error("Error during token refresh:", refreshError);
          throw refreshError;
        } finally {
          isRefreshing = false;
        }
        
      } else {
        // If refresh is already in progress, add this request to the subscribers
        return new Promise((resolve) => {
          addSubscriber((newToken) => {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            resolve(axios(originalRequest));
          });
        });
      }
    
    }

    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        
        message = error?.response?.data?.error?.message ;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };