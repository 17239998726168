import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_COUNTRY_CHARTS_DATA,
  GET_AUDIENCESMETRICS_CHARTS_DATA,
  GET_USERDEVICE_CHARTS_DATA,
  GET_AUDIENCESSESSIONS_CHARTS_DATA,
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_APPKEY_DATA,
  GET_APPKEY_DATA_SUCCESS,
  GET_APPKEY_DATA_FAILURE,
  CHANGE_APPKEY_DATA,
  CHANGE_APPKEY_DATA_FAILURE,
  CHANGE_APPKEY_DATA_SUCCESS
} from "./actionType";

// common success
export const dashboardAnalyticsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const dashboardAnalyticsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});



// charts data
export const getCountryChartsData = (chartData) => ({
  type: GET_COUNTRY_CHARTS_DATA,
  payload: chartData
});

// Audiences Metrics
export const getAudiencesMetricsChartsData = (audiencesMetricsData) => ({
  type: GET_AUDIENCESMETRICS_CHARTS_DATA,
  payload: audiencesMetricsData
});

// Users by Device
export const getUserDeviceChartsData = (userDeviceData) => ({
  type: GET_USERDEVICE_CHARTS_DATA,
  payload: userDeviceData
});

// Audiences Sessions by Country
export const getAudiencesSessionsChartsData = (audiencesSessionsData) => ({
  type: GET_AUDIENCESSESSIONS_CHARTS_DATA,
  payload: audiencesSessionsData
});

export const getDashboardData = (product, afterAdd) => ({
  type: GET_DASHBOARD_DATA,
  payload: { product, afterAdd },
});
// export const getDashboardDataSuccess = () => ({ type: GET_DASHBOARD_DATA_SUCCESS });
export const getDashboardDataSuccess = (product) => ({
  type: GET_DASHBOARD_DATA_SUCCESS,
  payload: product,
});
export const getDashboardDataFailure = (error) => ({
  type: GET_DASHBOARD_DATA_FAILURE,
  payload: { error },
});



export const getAppKeyData = (product, afterAdd) => ({
  type: GET_APPKEY_DATA,
  payload: { product, afterAdd },
});

export const getAppKeyDataSuccess = (product) => ({
  type: GET_APPKEY_DATA_SUCCESS,
  payload: product,
});

export const getAppKeyDataFailure = (error) => ({
  type: GET_APPKEY_DATA_FAILURE,
  payload: { error },
});

export const changeAppKeyData = (product, afterAdd) => ({
  type: CHANGE_APPKEY_DATA,
  payload: { product, afterAdd },
});

export const changeAppKeyDataSuccess = (product) => ({
  type: CHANGE_APPKEY_DATA_SUCCESS,
  payload: product,
});

export const changeAppKeyDataFailure = (error) => ({
  type: CHANGE_APPKEY_DATA_FAILURE,
  payload: { error },
});