import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotificationService from "../../../Service/NotificationService"

// Ecoomerce Redux States
import {
  
  ADD_NEW_CATEGORY,

  GET_CATEGORIES,

  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  CATEGORY_LIST
} from "./actionType";

import {
   categoryApiResponseSuccess,
   categoryApiResponseError,
  
    addCategoryFail,
    addCategorySuccess,

    updateCategorySuccess,
  updateCategoryFail,

  deleteCategorySuccess,
  deleteCategoryFail,
   
  } from "./action";

  import {
  
    addNewCategory,
    getCategories as getCategoriesApi,
    categoryListApi,

    updateCategory,
    deleteCategory,
   
  } from "../../../helpers/fakebackend_helper";

  toast.configure();


  function* onAddNewCategory({ payload:{category , afterAdd}  }) {
    try {
      const response = yield call(addNewCategory, category);
      if(response.status){
        NotificationService.success(response.message);
        afterAdd()
      }
      yield put(addCategorySuccess(response));
      } catch (error) {
      yield put(addCategoryFail(error));
      NotificationService.error(error);
    }
  }


//update
  function* onUpdateCategory({ payload: category }) {
    try {
      const response = yield call(updateCategory, category);
      if(response.status){
        toast.success(response.message, { autoClose: 3000 });
      }
      yield put(updateCategorySuccess(response));
    } catch (error) {
      yield put(updateCategoryFail(error));
      toast.error(error, { autoClose: 3000 });
    }
  }
  

  //delete
function* onDeleteCategory({ payload: category }) {
  try {
    const response = yield call(deleteCategory, category);
    if(response.status){
      toast.success(response.message, { autoClose: 3000 });
    }
    yield put(deleteCategorySuccess({ category, ...response }));
    yield put(categoryApiResponseSuccess(GET_CATEGORIES, response.data));
  } catch (error) {
    yield put(deleteCategoryFail(error));
    toast.error(error, { autoClose: 3000 });
  }
}
//getAll
function* getCategories({ payload: category }) {
  try {
    const response = yield call(getCategoriesApi, category);
    yield put(categoryApiResponseSuccess(GET_CATEGORIES, response.data));
  } catch (error) {
    yield put(categoryApiResponseError(GET_CATEGORIES, error));
  }
}

function* categoryList() {
  try {
    const response = yield call(categoryListApi);
    yield put(categoryApiResponseSuccess(CATEGORY_LIST, response.data));
  } catch (error) {
    yield put(categoryApiResponseError(CATEGORY_LIST, error));
  }
}




  export function* watchAddNewCategory() {
    yield takeEvery(ADD_NEW_CATEGORY, onAddNewCategory);
  }

  export function* watchGetCategories() {
    yield takeEvery(GET_CATEGORIES, getCategories);
  }
  export function* watchCategoryList() {
    yield takeEvery(CATEGORY_LIST, categoryList);
  }
  export function* watchUpdateCategory() {
    yield takeEvery(UPDATE_CATEGORY, onUpdateCategory);
  }
  
  export function* watchDeleteCategory() {
    yield takeEvery(DELETE_CATEGORY, onDeleteCategory);
  }
  
  


  function* categorySaga() {
    yield all([
      fork(watchGetCategories),
      fork(watchCategoryList),
      fork(watchAddNewCategory),
      fork(watchDeleteCategory),
      fork(watchUpdateCategory),
    ]);
  }
  
  export default categorySaga;