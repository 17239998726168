import React, { useState, useEffect } from "react";
import { Col, Input, Label, Row, Form, FormFeedback } from "reactstrap";
import BlankLayout from "../../../Components/Common/BlankLayout";
import CardSection from "../../../Components/Common/CardSection";
import OFFCanvas from "../../../Components/Common/OffCanvas/OFFCanvas";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useForm, Controller } from "react-hook-form";

import { useSelector, useDispatch } from "react-redux";

import {
  getSubcategories as onGetSubcategories,
  addNewSubcategory as onAddNewSubcategory, // Updated action
  addSubcategorySuccess, // Updated action
  updateSubcategory as onUpdateSubcategory,
  deleteSubcategory as onDeleteSubcategory,
} from "../../../store/admin/SubCategory/action"; // Updated import path
import { categoryList } from "../../../store/admin/Category/action";

const AddSubCategory = () => {
  // Updated component name
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [category, setCategory] = useState([]);
  const { categories } = useSelector((state) => ({
    categories: state?.Category?.categories,
    loading: state?.Category?.loading,
    error: state?.Category?.error,
  }));

  useEffect(() => {
    dispatch(categoryList());
  }, []);

  useEffect(() => {
    setCategory(categories);
  }, [categories]);

  const [flag, setflag] = useState(false);
  const dispatch = useDispatch();

  const { subcategories, error, loading } = useSelector((state) => ({
    subcategories: state?.Subcategory?.subcategories, // Updated state name
    loading: state?.Subcategory?.loading, // Updated state name
    error: state?.Subcategory?.error, // Updated state name
  }));

  const afterAdd = () => {
    reset();
    const data = {
      page: 1,
      page_size: 10,
    };
    dispatch(onGetSubcategories(data));
  };

  const onSubmit = async (data) => {
    dispatch(onAddNewSubcategory(data, afterAdd));
  };

  return (
    <>
      <React.Fragment>
       
        <Row>
          <Col xl={12}>
            {/* <CardSection panelTitle="Enter Details"> */}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col
                  className={`mb-3 ${
                    errors.category_id ? "d-block" : ""
                  }" md={12}`}
                >
                  <Controller
                    name="category_id"
                    control={control}
                    defaultValue="" // Set the default value if needed
                    rules={{ required: "Category is required" }}
                    render={({ field }) => (
                      <>
                        <Label htmlFor="dropdownValue" className="form-label">
                          Select Category
                        </Label>

                        <select
                          {...field}
                          className={`form-control ${
                            errors.category_id ? "is-invalid" : ""
                          }`}
                          id="dropdownValue"
                        >
                          <option value="" disabled>
                            Select an option
                          </option>
                          {Array.isArray(category) &&
                            category.length !== 0 &&
                            category.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                        </select>
                        {errors?.category_id && (
                          <FormFeedback className="d-block">
                            {errors?.category_id?.message}
                          </FormFeedback>
                        )}
                      </>
                    )}
                  />
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Name is required" }}
                      render={({ field }) => (
                        <>
                          <Label for="firstNameinput" className="form-label">
                            Subcategory Name
                          </Label>
                          <Input
                            {...field}
                            type="text"
                            invalid={errors.name ? true : false}
                            className="form-control"
                            placeholder="Enter Subcategory name"
                            id="firstNameinput"
                          />
                          <FormFeedback type="invalid">
                            {errors.name && errors.name.message}
                          </FormFeedback>
                        </>
                      )}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Controller
                      name="key"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Key is required" }}
                      render={({ field }) => (
                        <>
                          <Label for="lastNameinput" className="form-label">
                            Key
                          </Label>
                          <Input
                            {...field}
                            type="text"
                            invalid={errors.key ? true : false}
                            className="form-control"
                            placeholder="Enter your Last name"
                            id="lastNameinput"
                          />
                          <FormFeedback type="invalid">
                            {errors.key && errors.key.message}
                          </FormFeedback>
                        </>
                      )}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <div className="text-start">
                    {loading ? (
                      <button type="button" class="btn btn-primary " disabled>
                        Loading...
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    )}
                    {/* <button onClick={() => { setflag(!flag) }} className='btn btn-warning'> offcanvas</button> */}
                  </div>
                </Col>
              </Row>
            </Form>
            {/* </CardSection> */}
          </Col>
        </Row>
        {/* </BlankLayout> */}
      </React.Fragment>
    </>
  );
};

export default AddSubCategory; // Updated component name
