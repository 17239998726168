import React, { useState } from 'react';
import { Col, Input, Label, Row, Form, FormFeedback } from 'reactstrap';
import BlankLayout from '../../../Components/Common/BlankLayout';
import CardSection from '../../../Components/Common/CardSection';
import OFFCanvas from '../../../Components/Common/OffCanvas/OFFCanvas';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useForm, Controller } from "react-hook-form";

const EditCategory = (props) => {
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
    
      const [flag, setflag] = useState(false);
    
      const onSubmit = (data) => {
        alert("done");
        // Handle the form submission, including uploaded files in 'selectedFiles'
        console.log(data);
      };
  return (
   <>
    <OFFCanvas canvasTitle={'Edit Value'} direction={'end'} flag={props.flag} handler={()=>props.Handler()}>
  <Row>
  <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md={6}>
                      <div className="mb-3">
                        <Controller
                          name="firstName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "First name is required" }}
                          render={({ field }) => (
                            <>
                              <Label
                                for="firstNameinput"
                                className="form-label"
                              >
                                First Name
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.firstName ? true : false}
                                className="form-control"
                                placeholder="Enter your firstname"
                                id="firstNameinput"
                              />
                              <FormFeedback type="invalid">
                                {errors.firstName && errors.firstName.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Controller
                          name="lastName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Last name is required" }}
                          render={({ field }) => (
                            <>
                              <Label for="lastNameinput" className="form-label">
                                Last Name
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.lastName ? true : false}
                                className="form-control"
                                placeholder="Enter your Last name"
                                id="lastNameinput"
                              />
                              <FormFeedback type="invalid">
                                {errors.lastName && errors.lastName.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="companyName"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Company name is required" }}
                          render={({ field }) => (
                            <>
                              <Label
                                for="companyNameinput"
                                className="form-label"
                              >
                                Company Name
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.companyName ? true : false}
                                className="form-control"
                                placeholder="Enter your Company name"
                                id="companyNameinput"
                              />
                              <FormFeedback type="invalid">
                                {errors.companyName &&
                                  errors.companyName.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>

                  </Row>
                </Form>
        </Row>
  </OFFCanvas>
   </>
  )
}

export default EditCategory