
import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,

  
    ADD_NEW_CATEGORY,
    ADD_CATEGORY_SUCCESS,
    ADD_CATEGORY_FAIL,

    GET_CATEGORIES,
    CATEGORY_LIST,

    UPDATE_CATEGORY_SUCCESS,
    UPDATE_CATEGORY_FAIL,


    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAIL,

  } from "./actionType"

  const INIT_STATE = {
    categories: [],
    loading: false,
    errorMsg: "",
    error: null,
  };

  const Category = (state = INIT_STATE, action) => {
    switch (action.type) {
      case API_RESPONSE_SUCCESS:
        switch (action.payload.actionType) {
           
          case GET_CATEGORIES:
            return {
              ...state,
              categories: action.payload.data,
            
            };
            case CATEGORY_LIST:
            return {
              ...state,
              categories: action.payload.data,
              loading: false
             
            };
  
  
          default:
            return { ...state };
        }
      case API_RESPONSE_ERROR:
        switch (action.payload.actionType) {
      
          case GET_CATEGORIES:
            return {
              ...state,
              error: action.payload.error,
            };
            case CATEGORY_LIST:
            return {
              ...state,
              error: action.payload.error,
              
            };

          default:
            return { ...state };
        }
        case CATEGORY_LIST:
          return{
                ...state,
          }

      case GET_CATEGORIES:
        return {
          ...state,
          categories: Array.isArray(state.categories) ? [...state.categories, action?.payload?.data] : [action?.payload?.data],
        loading: false,
        };
        case ADD_NEW_CATEGORY :
          return {
          ...state,
          loading: true,
        };
      ///////////////////
      case ADD_CATEGORY_SUCCESS:
        return {
          ...state,
          categories: [...state.categories, action.payload.data],
        };
     
      case ADD_CATEGORY_FAIL:
        return {
          ...state,
          error: action.payload,
        };

        case UPDATE_CATEGORY_SUCCESS:
          return {
            ...state,
            categories: state.categories.map(category =>
              category._id.toString() === action.payload.data._id.toString()
                ? { ...category, ...action.payload.data }
                : category
            ),
          };
    
        case UPDATE_CATEGORY_FAIL:
          return {
            ...state,
            error: action.payload,
          };
     
        case DELETE_CATEGORY_SUCCESS:
          return {
            ...state,
            categories: state.categories.filter(
              category => category._id.toString() !== action.payload.category.toString()
            ),
          };
    
        case DELETE_CATEGORY_FAIL:
          return {
            ...state,
            error: action.payload,
          };
  
      default:
        return { ...state };
    }
  };
  
  export default Category;