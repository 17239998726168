import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_NEW_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  GET_CATEGORIES,
  CATEGORY_LIST,
} from "./actionType";

export const categoryApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const categoryApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// Add
export const addNewCategory = (category, afterAdd) => ({
  type: ADD_NEW_CATEGORY,
  payload: { category, afterAdd },
});

//1
export const addCategorySuccess = (category) => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: category,
});
// 1
export const addCategoryFail = (category) => ({
  type: ADD_CATEGORY_FAIL,
  payload: category,
});

//getAll
export const getCategories = (category) => ({
  type: GET_CATEGORIES,
  payload: category,
});

export const categoryList = () => ({
  type: CATEGORY_LIST,
  // payload: category,
});

//update
export const updateCategory = (category) => ({
  type: UPDATE_CATEGORY,
  payload: category,
});

export const updateCategorySuccess = (category) => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: category,
});

export const updateCategoryFail = (error) => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: error,
});

//delete
export const deleteCategory = (category) => ({
  type: DELETE_CATEGORY,
  payload: category,
});

export const deleteCategorySuccess = (category) => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: category,
});

export const deleteCategoryFail = (error) => ({
  type: DELETE_CATEGORY_FAIL,
  payload: error,
});
