import React, { useState } from "react";
import { Col, Input, Label, Row, Card, CardHeader, CardBody, Form ,FormFeedback } from "reactstrap";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import {
  addNewCategory as onAddNewCategory,
  // addCategorySuccess,
  addCategorySuccess,
  getCategories as onGetCategories,
  updateCategory as onUpdateCategory,
  deleteCategory as onDeleteCategory,
} from "../../../store/admin/Category/action";
import default_image from "../../../assets/images/default-image.jpg"

const CategoryForm = () => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage]= useState(null)
  
  const dispatch = useDispatch();


  const handleImageChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      // Set the image preview
      setImage(file)
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  const afterAdd = ()=>{
    setImagePreview(null)
    reset({
      image: "",
    })
    const data = {
      "page": 1,
      "page_size": 10
  }
    dispatch(onGetCategories(data));
  }

  const onSubmit = async (data) => {
    // Handle the form submission, including uploaded files in 'selectedFiles'
    console.log(image, 'image')
    const formData = new FormData();
    formData.append("category_name", data.name);

    // Assuming 'image' is a File or Blob object
    formData.append("category_image", image );

    // const filterData = {
    //   category_name: data.name,
    //   category_image: data.image
    // }
    // await dispatch(onAddNewCategory(filterData));
       dispatch(onAddNewCategory(formData, afterAdd));
      
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
        <Col md={12}>
                      <div className="mb-3">
                        <Controller
                          name="name"
                          control={control}
                          defaultValue=""
                          rules={{ required: "Category name is required" }}
                          render={({ field }) => (
                            <>
                              <Label
                                for="firstNameinput"
                                className="form-label"
                              >
                                 Name
                              </Label>
                              <Input
                                {...field}
                                type="text"
                                invalid={errors.name ? true : false}
                                className="form-control"
                                placeholder="Enter your name"
                                id="firstNameinput"
                              />
                              <FormFeedback type="invalid">
                                {errors.name && errors.name.message}
                              </FormFeedback>
                            </>
                          )}
                        />
                      </div>
                    </Col>
         
          <Col md={12} className="mb-3">
            
          <Controller
          name="image"
          rules={{ required: "Image is required" }}
          control={control}
          render={({ field }) => (
            <>
            <Label for="image" className="form-label">
                    Select Image
                  </Label>
              <Input
              {...field}
                type="file"
                accept="image/*"
                value={field.value}
                invalid={errors.image ? true : false}
                className="form-control"
                onChange={(e) => {
                  field.onChange(e);
                  handleImageChange(e);
                }}
              />
                 <FormFeedback className="" type="invalid">
                                {errors.image && errors.image.message}
                    </FormFeedback>
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Preview"
                  className="mt-3"
                  style={{ maxWidth: '100%' }}
                />
              ) :
              <img
              src={default_image}
              alt="Preview"
              className="mt-3"
              style={{ maxWidth: '100%' }}
            />
             }
            </>
          )}
        />
      
          
          </Col>
     
          <Col md={12}>
            <div className="text-start">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CategoryForm;
